.nyk-feedback-step-one {
  min-width: 41.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  border: 0;

  @media screen and (max-width: $breakpoint-small) {
    min-width: unset;
  }

  &__title {
    @include heading("medium");
  }

  &__content {
    min-height: calc(24.6rem - 6.4rem);
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__actions {
    display: flex;
    gap: 3.2rem;
    flex-wrap: wrap;
    margin-top: auto;

    .nyk-form-choice__content__item__label {
      @include heading("large");
    }
  }
}
