.nyk-site-searching {
  padding: 8rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 3.2rem;
    max-width: $max-width;
    margin: 0 auto;

    @media screen and (max-width: $breakpoint-medium) {
      flex-direction: column;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      gap: 2.4rem;
    }

    &__header {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      &__title {
        @include heading("large");

        @media screen and (max-width: $breakpoint-medium) {
          font-size: $fs-mobile-heading-x-large;
        }
      }

      &__count {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        @include heading("small");
      }
    }

    &__list {
      flex: 3;
      max-width: 84.5rem;
      margin-left: auto;
      width: 100%;

      @media screen and (max-width: $breakpoint-medium) {
        max-width: unset;
        margin-top: 2.4rem;
        margin-left: unset;
      }

      @media screen and (max-width: $breakpoint-x-small) {
        margin-top: 0;
      }

      .nyk-search-input-field {
        @media screen and (max-width: $breakpoint-x-small) {
          margin-bottom: 3.5rem;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &__search {
        position: relative;
        display: flex;
        align-items: center;
        gap: 1.8rem;
        width: 100%;
        height: 5.6rem;
        margin-bottom: 6rem;

        &__icon {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          left: 2.4rem;
          padding: 0;
          background-color: transparent;
          border: none;
          cursor: pointer;
          z-index: 10;

          svg {
            height: 2rem;
          }
        }

        &__input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 5.6rem;
          border: 1px solid var(--colortheme-primary-dark);
          border-radius: 5rem;
          background-color: transparent;
          padding: 0 6rem;
          @include bodyText("large");
        }

        &__close {
          position: absolute;
          top: 50%;
          right: 2.4rem;
          transform: translate(0, -50%);
          display: none;
          padding: 0;
          background-color: transparent;
          border: none;
          cursor: pointer;
          z-index: 10;

          &--show {
            display: block;
          }

          .nyk-icon {
            width: 2rem;
          }
        }
      }

      &__tabs {
        overflow-x: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        cursor: all-scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        &__list {
          position: relative;
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &__line {
            position: absolute;
            bottom: 0;
            height: 2px;
            background-color: var(--colortheme-delta);
            transition: 0.3s;
            transition-timing-function: ease-in-out;
            z-index: 3;
          }

          &__item {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            border-bottom: 1px solid var(--colortheme-primary-dark-20);
            transition: 0.3s;

            &:hover {
              background-color: var(--colortheme-neutral-2);
            }

            &:focus-within {
              background-color: var(--colortheme-neutral-3);
            }

            &:active {
              background-color: var(--colortheme-neutral-2);
            }

            &__button {
              position: relative;
              @include bodyText("medium");
              white-space: nowrap;
              border: none;
              background-color: transparent;
              padding: 1.6rem 3.2rem;
              cursor: pointer;
              z-index: 2;
              overflow: hidden;

              &:focus {
                outline: none;
              }

              &:active {
                &:after {
                  animation: tabPressed 0.3s;
                  animation-delay: 0.1s;
                  animation-fill-mode: forwards;
                  animation-iteration-count: 1;
                  -webkit-animation-iteration-count: 1;
                  -webkit-animation-fill-mode: forwards;
                }
              }

              &::after {
                content: "";
                position: absolute;
                right: -10rem;
                top: -3.2rem;
                opacity: 0;
                height: 12rem;
                width: 12rem;
                border-top-left-radius: 12rem;
                border-bottom-left-radius: 12rem;
                background-color: var(--colortheme-neutral-4);
                transition: 0.3s;
                z-index: 1;
              }

              &__text {
                position: relative;
                z-index: 3;
              }
            }
          }
        }
      }

      &__action {
        display: flex;
        justify-content: center;
        margin-top: 7.6rem;

        @media screen and (max-width: $breakpoint-x-small) {
          margin-top: 4.4rem;
        }
      }
    }
  }
}

@keyframes tabPressed {
  0% {
    opacity: 1;
    border-radius: 12rem;
  }
  90% {
    border-top-left-radius: 12rem;
    border-bottom-left-radius: 12rem;
  }
  95% {
    border-radius: 0;
  }
  100% {
    right: 0;
    width: 100%;
    border-radius: 0;
    opacity: 1;
  }
}
