.nyk-modal {
    background-color: var(--colortheme-bg-color);
    border-radius: 1.6rem;
    border: none;
    overflow: hidden;
    padding: 0;
    max-width: $max-width;
    width: calc(100% - 4.8rem);
    min-height: 25rem;
    max-height: calc(100vh - 12rem);

    @media screen and (max-width: $breakpoint-large) {
        max-width: calc($max-width - (2 * $margin-large));
    }

    @media screen and (max-width: $breakpoint-medium) {
        max-width: calc($max-width - $margin-large);
    }

    @media screen and (max-width: $breakpoint-small) {
        width: calc(100% - 3.2rem);
        margin: auto $margin-x-small $margin-x-small $margin-x-small;
        max-height: unset;
        height: calc(100% - 3.2rem);
    }

    @media screen and (max-width: $breakpoint-xx-small) {
        width: calc(100% - $margin-x-small);
        margin: auto 0.8rem 0.8rem 0.8rem;
        height: calc(100% - $margin-x-small);
    }


    &--white {
        background-color: var(--colortheme-neutral-7);

        .nyk-modal {
            &__body {
                &__header {
                    background-color: var(--colortheme-neutral-7);
                }

                &__close {
                    background-color: var(--colortheme-neutral-2);
                }
            }
        }
    }

    &--big {
        .nyk-modal {
            &__wrapper {
                width: 100%;
            }
        }
    }

    &--small {
        max-width: 67rem;

        @media screen and (max-width: $breakpoint-small) {
            max-width: unset;
        }

        .nyk-modal {
            &__wrapper {
                max-width: 67rem;
                width: 100%;
                max-height: 50.7rem;

                @media screen and (max-width: $breakpoint-small) {
                    max-width: unset;
                    max-height: unset;
                }
            }

            &__body {
                &__header {
                    max-width: calc(67rem - 6.4rem);

                    @media screen and (max-width: $breakpoint-small) {
                        max-width: unset;
                    }
                }

                &__content {
                    @media screen and (max-width: $breakpoint-small) {
                        margin-top: 11.8rem;
                    }
                }
            }
        }

        &-long {
            max-width: 67rem;

            @media screen and (max-width: $breakpoint-small) {
                max-width: unset;
            }

            .nyk-modal {
                &__wrapper {
                    max-width: 67rem;
                    width: 100%;
                    max-height: calc(100vh - 12rem);

                    @media screen and (max-width: $breakpoint-small) {
                        max-width: unset;
                        max-height: unset;
                    }
                }

                &__body {
                    &__header {
                        max-width: calc(67rem - 6.4rem);

                        @media screen and (max-width: $breakpoint-small) {
                            max-width: unset;
                        }
                    }
                }
            }
        }
    }

    &--subtitle {
        .nyk-modal {
            &__body {
                &__content {
                    margin-top: 15rem;

                    @media screen and (max-width: 350px) {
                        margin-top: 17rem;
                    }
                }
            }
        }
    }

    &::backdrop {
        background-color: rgba(0, 0, 0, 0.4);
    }

    &__wrapper {
        position: relative;
        max-width: $max-width;
        overflow-x: hidden;
        overflow-y: auto;
        border-radius: 1.6rem;
        max-height: calc(100vh - 12rem);

        @media screen and (max-width: $breakpoint-large) {
            max-width: calc($max-width - (2 * $margin-large));
        }

        @media screen and (max-width: $breakpoint-medium) {
            max-width: calc($max-width - $margin-large);
        }

        @media screen and (max-width: $breakpoint-small) {
            max-height: unset;
            height: 100%;
        }
    }

    &__body {
        padding: 0 3.2rem 3.2rem 3.2rem;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: $breakpoint-small) {
            padding: 0 2.4rem 3.2rem 2.4rem;
        }

        &__header {
            position: fixed;
            width: calc(100% - 9.5rem);
            padding: 3.2rem 0 1.6rem 0;
            max-width: calc($max-width - 6.4rem);
            background-color: var(--colortheme-bg-color);
            z-index: 1;

            @media screen and (max-width: $breakpoint-large) {
                width: calc($max-width - (2 * $margin-large + 6.4rem));
            }

            @media screen and (max-width: $breakpoint-medium) {
                width: calc(100% - 11rem);
            }

            @media screen and (max-width: $breakpoint-small) {
                padding: 2.4rem 0 1.6rem 0;
                width: calc(100% - 8rem);
            }

            @media screen and (max-width: $breakpoint-xx-small) {
                padding: 2.4rem 0 1.6rem 0;
                width: calc(100% - 5.5rem);
            }
        }

        &__title {
            overflow: hidden;
            word-break: break-word;
            width: calc(100% - 5.6rem);
            @include heading('medium');

            @media screen and (max-width: $breakpoint-small) {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                -webkit-line-clamp: 3;
            }
        }

        &__subtitle {
            word-break: break-word;
            width: calc(100% - 5.6rem);
            @include heading('medium');

            @media screen and (max-width: $breakpoint-small) {
                @include heading('small');
            }


            @media screen and (max-width: $breakpoint-xx-small) {
                @include heading('x-small');
            }
        }

        &__content {
            flex: 1;
            margin-top: 10rem;

            @media screen and (max-width: $breakpoint-small) {
                margin-top: 14rem;
            }

            .nyk-switch-loan-type-content,
            .nyk-switch-loan-type-content__list {
                margin-bottom: 0;
            }
        }

        &__close {
            position: absolute;
            top: 2.4rem;
            right: -0.8rem;
            width: 4rem;
            height: 4rem;
            border-radius: 4rem;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--colortheme-neutral-7);
            cursor: pointer;


            @media screen and (max-width: $breakpoint-xx-small) {
                right: 0;
            }
        }
    }
}

body {
    // complements the overlay.helpers functions
    &.no-scroll {
        //position: fixed;
        overflow: hidden;
        inline-size: 100%;
    }
}
