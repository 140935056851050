.nyk-section-block {
    padding: 6rem 0;
    display: flex;
    grid-template-rows: auto;
    gap: 4rem;

    @media screen and (min-width: $breakpoint-large) {
        margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
        max-width: $max-width;
        width: 100%;
        margin: 0 auto;
    }

    @media screen and (max-width: $breakpoint-large) {
        padding: 6rem $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
        padding: 6rem $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
        padding: 3.2rem 2.4rem;
        flex-direction: column;
        gap: 2.4rem;
    }

    @media screen and (max-width: $breakpoint-x-small) {
        padding: 3.2rem 1.6rem;
    }

    &--no-title {
        .nyk-section-block__content {
            margin-left: $column-aside-small;

            @media screen and (max-width: $breakpoint-small) {
                margin-left: 0;
            }
        }
    }

    &__title {
        max-width: calc($column-aside-small - 4rem);
        width: 100%;
        @include heading("medium");
        word-break: break-word;

        @media screen and (max-width: $breakpoint-small) {
            font-size: 2.4rem;
            line-height: 3.2rem;
            width: 100%;
            max-width: unset;
        }
    }

    &__content {
        max-width: $column-aside-big;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        @media screen and (max-width: $breakpoint-small) {
            width: 100%;
            max-width: unset;
        }

        .nyk-form-container {
            margin: 0;
            width: 100%;
        }
    }
}

@import "./SectionBlock.contentarea-overrides.scss";
