@import "./ChatWindow/ChatWindow.scss";
@import "./ChatMenu/ChatMenu.scss";
@import "./ChatMessage/ChatMessage.scss";
@import "./ChatFeedback/ChatFeedback.scss";
@import "./ChatConnectionError/ChatConnectionError.scss";


.nyk-chat-panel {
  position: absolute;
  z-index: 3;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  z-index: 1010;
  &__content {
    width: 100%;
    height: 100dvh;
    margin: auto;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    overflow: hidden;
    &--vertical-top {
        .nyk-chat-panel__chat-buttons {
          // Chat button distance from top with no top-menu
            top: calc(4rem + 8.7rem);
          @media screen and (min-width: $breakpoint-medium) {
            top: 17.6rem;
          }
        }
        .nyk-chat-window {
          // Window distance from top with top-menu__top and top-menu__bottom
          @media screen and (min-height: $chatPanelHeightBreakpoint) {
            top: calc(4rem + 8.7rem);
          }
          // Window distance from top with top-menu__top
          @media screen and (min-width: $breakpoint-medium) and (min-height: $chatPanelHeightBreakpoint) {
            top: 17.6rem;
          }
        }
        // Window distance from top with no top-menu
        .nyk-menu-is-scrolled & {
          .nyk-chat-panel__chat-buttons {
            top: 4rem;
            @media screen and (max-width: $breakpoint-x-small) {
              top: 2.2rem;
            }
          }
          .nyk-chat-window {
            @media screen and (min-height: $chatPanelHeightBreakpoint) {
              top: 4rem;
            }
            @media screen and (max-width: $breakpoint-small) and (max-height: $chatPanelHeightBreakpoint) {
              top: 0;
            }
          }
        }

        .nyk-menu-is-sticky & {
          .nyk-chat-panel__chat-buttons {
            @media screen and (min-width: $breakpoint-medium) {
              top: 12.7rem;
            }
            @media screen and (max-width: $breakpoint-small) {
              top: calc(1.6rem + 8.7rem);
            }
          }
          .nyk-chat-window {
            @media screen and (min-width: $breakpoint-medium) and (min-height: $chatPanelHeightBreakpoint) {
              top: 12.7rem;
            }
            @media screen and (max-width: $breakpoint-small) and (max-height: $chatPanelHeightBreakpoint) {
              top: calc(1.6rem + 8.7rem);
            }
          }
      }
    }
    &--vertical-bottom {
      .nyk-chat-panel__chat-buttons {
        bottom: 4rem;
        top: auto;
        @media screen and (max-width: $breakpoint-x-small) {
          bottom: 1.6rem;
          top: auto;
        }
      }
      .nyk-chat-window {
        bottom: 4rem;
        top: auto;
        @media screen and (max-height: $chatPanelHeightBreakpoint) {
          bottom: 0rem;
        }
        @media screen and (max-width: $breakpoint-small) {
          bottom: 1.6rem;
          top: auto;
        }
      }
    }
    @media screen and (max-width: $breakpoint-small) {
      &--chat-window-open {
        .nyk-menu-is-scrolled & {
          .nyk-chat-window {
            top: 0;
          }
        }
        &:not(.nyk-menu-is-scrolled &) {
          .nyk-chat-window {
            top: 0;
          }
        }
      }
    }
    &--collapsed {
      .nyk-chat-panel__intro-chat-button-start,
      .nyk-chat-panel__intro-chat-button-end {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  &__chat-buttons {
    position: absolute;
    left: auto;
    right: 0;
    top: 0;
    display: flex;
    gap: 1.6rem;
    margin: 0 1.6rem;
    transition: top 0.3s, bottom 0.3s;
    @media screen and (min-width: $breakpoint-x-small) {
    margin: 0 2.4rem;
    }
  }
  &__intro-chat-button {
    height: 7.6rem;
    position: relative;
    overflow: hidden;
    pointer-events: all;
    transform-origin: center center;
    @media screen and (max-width: $breakpoint-x-small) {
      height: 6.8rem;
    }
  }
  &__intro-chat-button-start {
    padding: 0;
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 1.6rem;
    transition: opacity 100ms;
  }
  &__intro-chat-button-end {
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 1.8rem;
    margin: auto;
    padding: 0;
    width: 4rem;
    height: 4rem;
    pointer-events: auto;
    border: none;
    background-color: transparent;
    transition: opacity 100ms;
  }
  &__start-chat-button {
    pointer-events: all;
    width: 7.6rem;
    height: 7.6rem;
    position: relative;
    transition: top 0.3s;
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $breakpoint-x-small) {
      width: 6.8rem;
      height: 6.8rem;
    }
  }

  &__close-icon {
    border-radius: 50%;
    background-color: var(--colortheme-primary-dark);
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 0;

  }
  &__start-chat-icon {
    width: 3.2rem;
    height: 2.92rem;
    flex-shrink: 0;
  }
  &__welcome-message-heading {
    font-weight: 400;
    font-size: 3.2rem;
    margin: 0.4 0 1.4rem;
    line-height: 1.2;
  }
  &__start-chat-button-text {
    margin-left: 6.8rem;
    margin-right: 2.4rem;
    line-height: 2.2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    white-space: nowrap;
    transition: transform 1s linear;
  }
  &__chat-input {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid var(--colortheme-neutral-3);
    padding: 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.2rem;
    background-color: white;
    @media screen and (min-width: $breakpoint-small) and (min-height: $chatPanelHeightBreakpoint) {
      border-bottom-right-radius: 1.6rem;
      border-bottom-left-radius: 1.6rem;
    }
    @media screen and (max-width: $breakpoint-medium) {
      background-color: white;
    }
  }
  &__chat-input-label {
    width: 100%;
  }
  &__chat-input-textarea {
    width: 100%;
    color: var(--colortheme-primary-dark);
    padding: 8px 12px;
    font-size: 16px;
    border: none;
    min-height: 38px;
    resize: none;
    font-family: var(--font-family);
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: var(--colortheme-primary-dark);
    }
    &:disabled {
      cursor: not-allowed;
      color: var(--colortheme-neutral-5);
      background-color: transparent;

      &::placeholder {
        color: var(--colortheme-neutral-5);
      }
    }
  }
  &__message-section {
    margin: 2.4rem 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__message-section-author {
    color: #07094A99;
    font-size: 1.2rem;
    display: inline-block;
  }
  &__message-section-feedback-buttons {
    display: flex;
    margin-top: 0.8rem;
  }
  &__message-section-feedback-button {
    width: 2.7rem;
    height: 2.7rem;
    padding: 0.6rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  &__message-section-feedback-button-icon {
      > path {
        transition: fill 350ms ease-out;
        fill: transparent;
      }
    &--active {
      > path {
        fill: var(--colortheme-primary);
      }
    }
  }
  &__response-loader {
    display: inline-block;
    border-radius: 1.6rem;
    padding: 0.85rem 1.6rem;
    background-color: #ede8e6;
    margin-top: 0.8rem;
  }
  &__response-loader-dots {
    width: 2.2rem;
    height: 1.5rem;
    --chat-response-loader-dots: no-repeat
      radial-gradient(circle closest-side, var(--colortheme-primary-dark) 90%, transparent);
    background: var(--chat-response-loader-dots) 0% 50%,
      var(--chat-response-loader-dots) 50% 50%,
      var(--chat-response-loader-dots) 100% 50%;
    background-size: calc(100% / 4) 50%;
    animation: responseLoader 800ms infinite linear;

    @keyframes responseLoader {
      20% {
        background-position: 0% 50%, 50% 50%, 100% 50%;
      }
      40% {
        background-position: 0% 100%, 50% 50%, 100% 50%;
      }
      60% {
        background-position: 0% 50%, 50% 100%, 100% 50%;
      }
      80% {
        background-position: 0% 50%, 50% 50%, 100% 100%;
      }
    }
  }
  &__chat-input-action-send {
    display: flex;
    flex-shrink: 0;
    width: 4rem;
    height: 4rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      width: 1.2rem;
      height: 1.2rem;
    }
    background-color: var(--colortheme-delta);
    border-color: var(--colortheme-delta);
    color: white;

    .nyk-button__icon {
        path {
            fill: white;
        }
    }

    &:hover {
        background-color: var(--colortheme-primary-dark);
    }

    &:focus {
        background-color: var(--colortheme-primary-dark);
    }

    &:active {
        background-color: var(--colortheme-primary-darker);
    }
  }
  &:has(.nyk-chat-panel__content--vertical-bottom) {
    .chatWindowAppear-enter-from,
    .chatWindowAppear-leave-to {
      opacity: 0;
      scale: 0.3;
      transform: translateY(2rem);
      transform: translateX(5rem);
    }
    .chatWindowAppear-enter-active,
    .chatWindowAppear-leave-active {
      transform-origin: bottom center;
    }
  }
  &:has(.nyk-chat-panel__content--vertical-top) {
    .chatWindowAppear-enter-from,
    .chatWindowAppear-leave-to {
      opacity: 0;
      scale: 0.3;
      transform: translateY(-2rem);
      transform: translateX(5rem);
    }
    .chatWindowAppear-enter-active,
    .chatWindowAppear-leave-active {
      transform-origin: top center;
    }
  }
  .chatWindowAppear-enter-active {
    transition: all 300ms ease-out;
  }
  .chatWindowAppear-leave-active {
    transition: all 300ms ease-in;
  }
  
  .chatMessageAppear-enter-active,
  .chatMessageAppear-leave-active {
    transition: all 300ms ease-out;
    transform-origin: bottom center;
  }
  .chatMessageAppear-enter-from,
  .chatMessageAppear-leave-to {
    opacity: 0;
    scale: 0.7;
    transform: translateY(10px);
  }

}
