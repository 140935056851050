.nyk-center-map {
  padding-bottom: 6rem;

  @media screen and (max-width: $breakpoint-small) {
      padding-bottom: 3.2rem;
  }

  &--hidden-mobile {
      @media screen and (max-width: $breakpoint-small) {
          .nyk-google-maps-with-markers {
              display: none;
          }

          .nyk-center-map__map-section__suggestions {
              display: block;
              max-width: unset;
              background-color: transparent;
          }
      }
  }

  &__map-search-section {
      .nyk-google-maps-with-markers {
          height: 66rem;
          width: 100%;

          @media screen and (max-width: $breakpoint-small) {
              height: 100%;
              aspect-ratio: calc(1 / 1);
          }
      }
  }

  &__controls {
      max-width: $column-aside-big;
      margin-left: auto;
      padding: 2rem 0 4rem 0;
      display: flex;
      align-items: flex-end;
      gap: 1.2rem;

      @media screen and (min-width: $breakpoint-large) {
          margin: 0 $margin-x-large 0 auto;
          max-width: calc($column-aside-big - 12.2rem); // 12.2rem is magic sweet spot
      }

      @media screen and (min-width: $breakpoint-computed-x-large) {
          max-width: calc($max-width * 0.666666666667);
          margin: 0 calc((100% - $max-width) / 2) 0 auto;
      }

      @media screen and (max-width: $breakpoint-large) {
          margin: 0 $margin-large 0 auto;
          max-width: calc($column-aside-big - 10.7rem); // 10.7rem is magic sweet spot
      }

      @media screen and (max-width: $breakpoint-medium) {
          margin: 0 $margin-medium 0 auto;
          max-width: calc($column-aside-big - 3.2rem); // 3.2rem is magic sweet spot
      }

      @media screen and (max-width: $breakpoint-small) {
          margin: 0 $margin-medium;
          max-width: unset;
          padding: 3.2rem 0 0 0;
          flex-direction: column;
          align-items: stretch;
          gap: 0.8rem;
      }

      @media screen and (max-width: $breakpoint-x-small) {
          margin: 0 $margin-x-small;
      }

      &__search {
          &-results {
              text-align: right;
              font-size: 1.2rem;
          }

          .nyk-form {
              margin: 0;
          }
      }

      > * {
          flex-basis: 100%;
      }

      &__dropdown {
          max-width: 35rem;

          @media screen and (max-width: $breakpoint-small) {
              max-width: unset;
          }
      }
  }

  &__switch-view-mobile {
      display: none;

      @media screen and (max-width: $breakpoint-small) {
          display: block;
          margin: 0 $margin-medium;
          padding: 2.4rem 0 1.6rem 0;
      }

      @media screen and (max-width: $breakpoint-x-small) {
          margin: 0 $margin-x-small;
      }

      &__body {
          display: flex;
          align-items: center;

          &__item {
              display: flex;
              align-items: center;
              gap: 0.8rem;
              @include bodyText('small');
              padding: 1rem 3.6rem;
              border: 1px solid var(--colortheme-primary-dark);
              background-color: transparent;
              border-radius: 0;
              cursor: pointer;

              &:first-child {
                  padding-left: 2.4rem;
                  border-bottom-left-radius: 3.2rem;
                  border-top-left-radius: 3.2rem;
              }

              &:last-child {
                  border-left: none;
                  padding-right: 2.4rem;
                  border-bottom-right-radius: 3.2rem;
                  border-top-right-radius: 3.2rem;
              }

              svg {
                  width: 1.8rem;

                  path {
                      fill: transparent;
                  }
              }

              &--active {
                  background-color: var(--colortheme-primary-dark);
                  color: var(--colortheme-neutral-7);

                  svg {
                      path {
                          fill: var(--colortheme-neutral-7);
                      }
                  }
              }
          }
      }
  }

  &__zoom-level {
      font-size: 1.4rem;
      text-align: right;

      @media screen and (min-width: $breakpoint-large) {
          margin: 0 $margin-x-large 0 2.4rem;
      }

      @media screen and (min-width: $breakpoint-computed-x-large) {
          margin: 0 calc((100% - $max-width) / 2) 0 2.4rem;
      }

      @media screen and (max-width: $breakpoint-large) {
          margin: 0 $margin-large 0 2.4rem;
      }

      @media screen and (max-width: $breakpoint-medium) {
          margin: 0 $margin-medium 0 2.4rem;
      }

      @media screen and (max-width: $breakpoint-small) {
          flex-direction: column;
      }

      @media screen and (max-width: $breakpoint-x-small) {
          margin: 0 $margin-x-small;
      }
  }

  &__map-section {
      display: flex;
      border-radius: var(--border-radius);
      overflow: hidden;

      @media screen and (min-width: $breakpoint-large) {
          margin: 0 $margin-x-large 0 2.4rem;
      }

      @media screen and (min-width: $breakpoint-computed-x-large) {
          margin: 0 calc((100% - $max-width) / 2) 0 2.4rem;
      }

      @media screen and (max-width: $breakpoint-large) {
          margin: 0 $margin-large 0 2.4rem;
      }

      @media screen and (max-width: $breakpoint-medium) {
          margin: 0 $margin-medium 0 2.4rem;
      }

      @media screen and (max-width: $breakpoint-small) {
          flex-direction: column;
      }

      @media screen and (max-width: $breakpoint-x-small) {
          margin: 0 $margin-x-small;
      }

      &__hidden-mobile {
          @media screen and (max-width: $breakpoint-small) {
              display: none;
          }
      }

      &__suggestions {
          background-color: var(--colortheme-neutral-7);
          max-width: 40rem;
          width: 100%;
          display: none;

          @media screen and (min-width: $breakpoint-computed-x-large) {
              max-width: 50rem;
          }

          @media screen and (max-width: $breakpoint-medium) {
              max-width: 30rem;
          }

          @media screen and (max-width: $breakpoint-small) {
              display: none;
          }

          &--shown {
              display: block;

              @media screen and (max-width: $breakpoint-small) {
                  display: none;
              }
          }

          &__list {
              list-style: none;
              padding: 0;
              display: flex;
              flex-direction: column;
              margin: 0;
              margin-left: auto;

              &__item {
                  @media screen and (max-width: $breakpoint-small) {
                      &:first-child {
                          border-top: 0.1rem solid var(--colortheme-neutral-3);
                      }
                  }

                  &__link {
                      position: relative;
                      display: block;
                      padding: 3.2rem 0;
                      text-decoration: none;
                      transition: 0.2s;

                      @media screen and (max-width: $breakpoint-small) {
                          padding: 1.2rem 0;
                      }

                      &::after {
                          content: '';
                          position: absolute;
                          bottom: 0;
                          display: block;
                          height: 0.1rem;
                          width: 100%;
                          margin-left: 9rem;
                          background-color: var(--colortheme-neutral-3);

                          @media screen and (max-width: $breakpoint-medium) {
                              margin-left: 2.4rem;
                          }

                          @media screen and (max-width: $breakpoint-small) {
                              margin-left: 0;
                          }
                      }

                      &:hover {
                          background-color: var(--colortheme-neutral-2);

                          .nyk-center-map__map-section__suggestions__list__item__arrow {
                              opacity: 1;
                              margin-right: 3.2rem;

                              @media screen and (max-width: $breakpoint-small) {
                                  margin-right: 0.8rem;
                              }
                          }
                      }

                      &__card {
                          display: flex;
                          align-items: flex-start;
                          justify-content: space-between;
                          gap: 1.6rem;
                          padding-right: 3.2rem;
                          margin-left: 9rem;

                          @media screen and (max-width: $breakpoint-medium) {
                              margin-left: 2.4rem;
                          }

                          @media screen and (max-width: $breakpoint-small) {
                              padding: 0 0.8rem;
                              margin: 0;
                          }

                          &__header {
                              &__title {
                                  @include heading('small');
                                  margin: 0 0 0.4rem 0;
                              }

                              &__address {
                                  @include bodyText('medium');
                                  margin: 0;
                              }

                              &__postal-code {
                                  @include bodyText('medium');
                                  margin: 0;
                              }
                          }

                          &__distance {
                              margin-top: 0.8rem;
                              min-width: 6rem;
                              text-align: right;
                              @include bodyText('small');
                              color: var(--colortheme-primary-dark-60);
                          }
                      }
                  }

                  &__arrow {
                      text-align: right;
                      margin: 1.8rem 4rem 0 0;
                      opacity: 0;
                      transition: 0.2s;

                      @media screen and (max-width: $breakpoint-small) {
                          margin-right: 1.6rem;
                      }

                      svg {
                          width: 1.4rem;
                          height: 1.1rem;
                      }
                  }
              }
          }
      }
  }
}

.nyk-centerinformation-page {
  .nyk-anker-menu {
      @media screen and (max-width: $breakpoint-small) {
          padding-top: 0;
      }
  }
}
