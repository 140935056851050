.nyk-image {
  display: flex;
  flex-direction: column;
  padding: 4rem 0 4.4rem 0;
  max-width: $max-width;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0 2.4rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &--right {
    align-items: flex-end;

    .nyk-image__content__description {
      margin-left: unset;
    }
  }

  &--hero {
    @media screen and (min-width: $breakpoint-large) {
      margin: 0 $margin-x-large 0 2.4rem;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
      margin: 0 calc((100% - $max-width) / 2) 0 calc((100% - calc($max-width + 9.2rem)) / 2);
      max-width: calc($max-width + 9.2rem);
    }

    @media screen and (max-width: $breakpoint-large) {
      margin: 0 $margin-large 0 2.4rem;
    }

    @media screen and (max-width: $breakpoint-medium) {
      margin: 0 $margin-medium 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
      margin: 0 $margin-medium 0 1.2rem;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      margin: 0 $margin-x-small;
    }

    &.nyk-image--right {
      @media screen and (min-width: $breakpoint-large) {
        margin: 0 2.4rem 0 $margin-x-large;
      }

      @media screen and (min-width: $breakpoint-computed-x-large) {
        margin: 0 calc((100% - calc($max-width + 9.2rem)) / 2) 0 calc((100% - $max-width) / 2);
        max-width: calc($max-width + 9.2rem);
      }

      @media screen and (max-width: $breakpoint-large) {
        margin: 0 2.4rem 0 $margin-large;
      }

      @media screen and (max-width: $breakpoint-medium) {
        margin: 0 2.4rem 0 $margin-medium;
      }

      @media screen and (max-width: $breakpoint-small) {
        margin: 0 1.2rem 0 $margin-medium;
      }

      @media screen and (max-width: $breakpoint-x-small) {
        margin: 0 $margin-x-small;
      }

      .nyk-image__content {
        &__description {
          margin-left: 0 !important;
        }
      }
    }

    .nyk-image__content {
      width: 100%;
      max-width: unset;

      &__description {
        max-width: calc($column-aside-big - 4.4rem);
        margin-left: auto !important;

        @media screen and (min-width: $breakpoint-computed-x-large) {
          max-width: calc($column-aside-big - 3.2rem);
        }

        @media screen and (max-width: $breakpoint-medium) {
          max-width: $column-aside-big;
        }

        @media screen and (max-width: $breakpoint-x-small) {
          max-width: unset;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4.4rem;
    max-width: $column-aside-big;

    @media screen and (max-width: $breakpoint-small) {
      max-width: unset;
      width: 100%;
      gap: 2.4rem;
    }

    .nyk-richtext {
      margin: 0;

      & > * {
        width: 100%;
      }

      p {
        @include heading("medium");
        margin: 0 0 1.6rem 0;

        &:last-child {
          margin-bottom: 0;
        }

        @media screen and (max-width: $breakpoint-small) {
          @include heading("small");
        }
      }
    }

    &__img:is(img) {
      aspect-ratio: 16 / 9;
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);

      @media screen and (max-width: $breakpoint-small) {
        max-width: unset;
      }

      @media screen and (max-width: $breakpoint-x-small) {
        aspect-ratio: calc(1 / 1);
        max-width: unset;
      }
    }

    &__description {
      @include heading("medium");
      margin-left: auto;

      @media screen and (max-width: $breakpoint-medium) {
        font-size: $fs-mobile-heading-small;
      }

      @media screen and (max-width: $breakpoint-small) {
        max-width: unset;
      }
    }
  }
  &__image-content {
    position: relative;
  }
}
