.nyk-document-archive {
  max-width: $max-width;
  padding: 6rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 4.4rem;

    + .nyk-document-archive__filters {
      margin-top: 6rem;
    }

    &__title {
      @include heading("x-large");
    }

    &__description {
      @include bodyText("large");
      margin: 0;
    }
  }

  &__filters {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 1.6rem;
    margin-top: 6rem;
    flex-wrap: wrap;

    @media screen and (max-width: $breakpoint-small) {
      flex-direction: column;
    }

    &__search {
      position: relative;
      display: flex;
      flex: 1;
      width: 100%;

      @media screen and (max-width: $breakpoint-small) {
        order: 1;
      }

      &__icon {
        position: absolute;
        top: 50%;
        left: 1.6rem;
        transform: translateY(-50%);

        svg {
          height: 1.6rem;
        }
      }

      &__input {
        flex: 1;
        padding: 1.4rem 4rem;
        height: 6rem;
        border: none;
        border-radius: 0.9rem;
        border: 1px solid var(--colortheme-primary-dark);
        @include bodyText("large");
        color: var(--colortheme-primary-dark);
        background-color: transparent;

        @media screen and (max-width: $breakpoint-x-small) {
          width: 100%;
        }

        &:focus {
          outline-offset: 0;
        }
      }

      &__close {
        border: none;
        background-color: transparent;
        padding: 0;
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1.6rem;
        margin-left: auto;

        &--show {
          display: block;
        }

        .nyk-icon {
          height: 1.6rem;
          width: 1.6rem;
        }
      }
    }

    .nyk-multi-select-dropdown {
      flex: 1;

      @media screen and (max-width: $breakpoint-small) {
        order: 2;
        width: 100%;
      }
    }
  }

  &__results {
    margin: 6rem 0 0 0.8rem;
    @include bodyText("small");
  }

  .nyk-dynamic-document-list {
    margin: 0;
    padding: 1.2rem 0;
  }
}
