.nyk-breadcrumb {
    padding: 2rem 0;

    @media screen and (min-width: $breakpoint-large) {
        margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
        margin: 0 auto;
        max-width: $max-width;
    }

    @media screen and (max-width: $breakpoint-large) {
        margin: 0 $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
        margin: 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
        padding: 1.6rem 0;
    }

    @media screen and (max-width: $breakpoint-x-small) {
        margin: 0 $margin-x-small;
    }

    &__list {
        display: flex;
        align-items: center;
        gap: 1rem;
        list-style: none;
        padding: 0;
        flex-wrap: wrap;

        &--shown {
            .nyk-breadcrumb__list__item {
                display: flex;
            }

            .nyk-breadcrumb__list__item--dots {
                display: none;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            font-size: $fs-body-small;
            color: var(--colortheme-primary-dark-60);
            line-height: 2rem;

            @media screen and (max-width: $breakpoint-small) {
                display: none;
            }

            // Show the 2 last plus the arrow on mobile as default
            &:nth-last-child(3) {
                display: flex;
            }

            &:nth-last-child(2) {
                display: flex;
            }

            &:nth-last-child(1) {
                display: flex;
            }

            &--arrow {
                width: 0.8rem;
                height: 0.8rem;
                border: 1px solid var(--colortheme-primary-dark-60);
                border-top: 0;
                border-left: 0;
                transform: rotate(-45deg);

                & + .nyk-breadcrumb__list__item {
                    margin-left: 0.4rem;
                }
            }

            &--dots {
                display: none;
                @media screen and (max-width: $breakpoint-small) {
                    display: block;
                }
            }

            &__dots {
                font-size: $fs-body-large;
                line-height: 1.6rem;
                padding: 0;
                padding-right: 0.4rem;
                margin-bottom: 0.5rem;
                background-color: transparent;
                color: var(--colortheme-primary-dark-60);
                border: none;
                cursor: pointer;
                outline: none;

                &:focus-within {
                    outline: solid 2px var(--colortheme-delta);
                    outline-offset: 1rme;
                }
            }

            &__link {
                text-decoration: none;
                color: var(--colortheme-netrual-3);
                cursor: pointer;
                border-bottom: 1px solid transparent;
                transition: 0.2s;

                &:hover {
                    border-bottom: 1px solid;
                    border-color: var(--colortheme-netrual-3);
                }
            }

            > svg {
                width: 1rem;
                height: 1rem;

                path {
                    fill: var(--colortheme-primary-dark-60);
                }
            }
        }
    }
}
