.nyk-job-page {
  &__application {
    margin-top: 8rem;

    @media screen and (min-width: $breakpoint-large) {
      margin: 0 $margin-x-large;
      margin-top: 8rem;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
      margin: 0 auto;
      max-width: $max-width;
      margin-top: 8rem;
    }

    @media screen and (max-width: $breakpoint-large) {
      margin: 0 $margin-large;
      margin-top: 8rem;
    }

    @media screen and (max-width: $breakpoint-medium) {
      margin: 0 $margin-medium;
      margin-top: 8rem;
    }

    @media screen and (max-width: $breakpoint-small) {
      margin-top: 3.2rem;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      margin: 0 $margin-x-small;
      margin-top: 8rem;
      width: unset;
    }

    &__content {
      width: $column-aside-big;
      margin-left: auto;

      @media screen and (max-width: $breakpoint-small) {
        width: 100%;
      }

      &__title {
        @include heading("x-large");
        word-break: break-word;

        @media screen and (max-width: $breakpoint-small) {
          margin-bottom: 2.4rem;
        }
      }

      &__info {
        display: grid;
        grid-template-columns: auto auto;
        gap: 4rem;

        @media screen and (max-width: $breakpoint-small) {
          display: flex;
          flex-direction: column-reverse;
        }

        .nyk-richtext {
          margin: 0;

          & > * {
            margin-left: unset;
            width: 100%;
          }
        }
      }

      &__form {
        &__group {
          margin-bottom: 4rem;
        }

        &__button {
          margin-top: 2rem;
          margin-bottom: 12rem;
        }
      }

      .nyk-image {
        margin: 0;

        &__content {
          max-width: 100%;
        }
      }
    }
  }
}
