.nyk-fs {
    &-heading {
        &--xxx-large {
            @include heading('xxx-large');
        }
        &--xx-large {
            @include heading('xx-large');
        }
        &--x-large {
            @include heading('x-large');
        }
        &--large {
            @include heading('large');
        }
        &--medium {
            @include heading('medium');
        }
        &--small {
            @include heading('small');
        }
        &--x-small {
            @include heading('x-small');
        }
    }

    &-text {
        &--large {
            @include bodyText('large');
        }
        &--medium {
            @include bodyText('medium');
            
        }
        &--small {
            @include bodyText('small');
            
        }
        &--x-small {
            @include bodyText('x-small');
            
        }
    }
}

.nyk-fw {

    &--normal {
        font-weight: 400;
    }

    &--bold {
        font-weight: 700;
    }
}