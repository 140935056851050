.nyk-contact-step-intro {
  &__content {
    display: grid;
    grid-template-columns: calc($column-aside-big - 1.6rem) auto; // 1.6rem is minus the grid gutter from figma
    height: 50rem;
    overflow: hidden;
    border-radius: 1.6rem;
    background-color: var(--colortheme-alfa);

    @media screen and (max-width: $breakpoint-small) {
      display: flex;
      flex-direction: column-reverse;
      height: unset;
    }

    &__info {
      display: flex;
      flex-direction: column;
      padding: 3.2rem 2.4rem;

      &__header {
        @media screen and (max-width: $breakpoint-small) {
          margin-bottom: 10rem;
        }

        &__title {
          @include heading("medium");

          @media screen and (max-width: $breakpoint-small) {
            @include heading("small");
          }
        }
      }

      &__content {
        margin-top: auto;

        &__description {
          @include bodyText("large");
          margin: 0;

          @media screen and (max-width: $breakpoint-small) {
            @include bodyText("medium");
          }
        }
      }

      &__action {
        margin-top: 5.8rem;

        &__link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;

          &__name {
            @include bodyText("medium");
          }

          &__button {
            background-color: var(--colortheme-neutral-7) !important;
            border-color: var(--colortheme-neutral-7) !important;
          }
        }
      }
    }

    &__image {
      width: 100%;
      height: 50rem;
      overflow: hidden;

      @media screen and (max-width: $breakpoint-small) {
        height: 32rem;
      }

      &__img:is(img) {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0;
      }
    }
  }
}
