.nyk-context-banner {
    position: relative;
    margin-top: 4rem;
    padding: 2.4rem;
    background-color: var(--colortheme-primary-dark);
    border-radius: var(--border-radius);
    max-width: $max-width;
    display: grid;
    grid-template-columns: auto 6rem;
    align-items: center;

    @media screen and (min-width: $breakpoint-large) {
        margin: 1.6rem $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
        margin: 1.6rem auto;
    }

    @media screen and (max-width: $breakpoint-large) {
        margin: 1.6rem $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
        margin: 2.4rem $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
        align-items: flex-start;
        flex-direction: column;
        display: flex;
    }

    @media screen and (max-width: $breakpoint-x-small) {
        margin: 2.4rem $margin-x-small;
    }

    * :focus-visible {
        outline-color: var(--colortheme-neutral-7);
    }

    &--warning {
        background-color: var(--color-warning);
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 4rem;
        gap: 4rem;

        @media screen and (max-width: $breakpoint-small) {
            flex-wrap: wrap;
        }

        &__info {
            display: flex;
            align-items: center;

            &__description {
                @include bodyText('medium');
                color: var(--colortheme-neutral-7);
                margin: 0;

                @media screen and (max-width: $breakpoint-small) {
                    padding-right: 6.4rem;
                }

                &__label {
                    @include bodyText('medium');
                    color: var(--colortheme-neutral-7);
                    font-weight: 700;
                }
            }

            &__date {
                @include bodyText('medium');
                color: var(--colortheme-neutral-7);
                margin: 0;
            }
        }

        &__link.nyk-button {
            color: var(--colortheme-neutral-7);
            transition: 0.3s;

            &:hover {
                color: var(--colortheme-neutral-2);

                svg {
                    path {
                        fill: var(--colortheme-neutral-2);
                    }
                }
            }
        }
    }

    &__link {
        margin-left: auto;
        background-color: var(--colortheme-neutral-7);
        border: none;
        width: 4rem;
        height: 4rem;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s;
        position: relative;

        @media screen and (max-width: $breakpoint-small) {
            position: absolute;
            top: 2.4rem;
            right: 2.4rem;
        }

        &:focus-visible {
            outline-color: var(--colortheme-neutral-7);
        }

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            content: '';
            display: block;
            width: 1.4rem;
            height: 0.2rem;
            background-color: var(--colortheme-primary-dark);
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            content: '';
            display: block;
            width: 1.4rem;
            height: 0.2rem;
            background-color: var(--colortheme-primary-dark);
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &.nyk-button {
            color: var(--colortheme-neutral-7);

            &__icon {
                svg {
                    path {
                        fill: var(--colortheme-neutral-7);
                    }
                }
            }
        }

        &:hover,
        &:focus {
            background-color: var(--colortheme-neutral-2);
        }
    }
}
