.nyk-calculator-table {
    &.nyk-table {
        padding: 0;
        margin: $margin-x-small 0;
    }
    &--hidden {
        .nyk-table__container__table {
            &__body__row {
                display: none;

                &:first-child {
                    display: table-row;
                }
            }
        }

        .nyk-calculator-table__item-collapse-icon {
            transform: rotate(180deg);
        }
    }

    &--show-hidden-row {
        .nyk-calculator-table {
            &__load-more.nyk-button {
                svg {
                    transform: rotate(0);
                }
            }
        }
    }

    &__item-collapse-icon {
        display: flex;
        align-items: center;
        justify-self: center;

        @media screen and (max-width: $breakpoint-small) {
            width: 2rem;

            &.nyk-calculator-table__item-collapse-icon svg {
                margin: 0;
            }
        }
    }

    &__item-tooltip {
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 2.2rem;
        background-color: transparent;
        border: 2px solid var(--colortheme-primary-dark);
        font-style: normal;
        padding: 0;
        cursor: pointer;

        &-symbol {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 0.2rem;
        }
    }

    .nyk-calculator-table__load-more {
        margin: $margin-x-small 0 0 1rem;
        font-size: $fs-body-large;
        font-weight: 700;

        @media screen and (max-width: $breakpoint-xs-custom-small) {
            padding: 0;
        }

        svg {
            transform: rotate(180deg);
        }
    }

    .nyk-table__container {
        border-top: 1px solid #9796b0;
        overflow-y: hidden;

        .nyk-table__container__table {
            &__header {
                border-bottom: 1px solid #9796b0;

                &__row__item {
                    h3 {
                        @include bodyText('large');
                        font-weight: 700;
                        margin: 0;

                        @media screen and (max-width: $breakpoint-small) {
                            @include bodyText('medium');
                        }
                    }
                }
            }

            &__body__row {
                @media screen and (max-width: $breakpoint-small) {
                    &[aria-expanded] {
                        border-bottom: 1px solid #9796b0;

                        .nyk-table__container__table__body__row__item {
                            &:first-child {
                                padding-right: 0;
                            }
                        }
                    }

                    &--hide.nyk-table__container__table__body__row {
                        display: none;
                    }
                }

                @media screen and (max-width: $breakpoint-xx-small) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.8rem;
                    padding: 1.6rem 0;
                }

                &__item {
                    font-weight: 400;

                    &:first-child {
                        padding-right: 6rem;

                        @media screen and (max-width: $breakpoint-small) {
                            padding-right: 1.2rem;
                        }
                    }

                    &:last-child {
                        font-weight: 500;
                        white-space: nowrap;
                    }

                    @media screen and (max-width: $breakpoint-small) {
                        padding: 1.6rem 0 1.6rem 0.8rem;
                    }

                    @media screen and (max-width: $breakpoint-xx-small) {
                        padding: 0 0.8rem;

                        &:last-child {
                            text-align: left;
                        }
                    }

                    a {
                        color: var(--colortheme-primary-dark);
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &__button {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1.2rem;
        width: 100%;
        padding: 1.6rem 0.8rem 1.6rem 1.2rem;
        @include bodyText('large');
        font-weight: 700;
        cursor: pointer;
        background-color: transparent;
        border: none;
        border-top: 1px solid #9796b0;
        border-bottom: 1px solid #9796b0;
        text-align: left;

        &-content {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            svg {
                width: 2rem;
                height: 2rem;
            }
        }

        &-value {
            vertical-align: top;
            font-weight: 500;
            white-space: nowrap;
            min-width: 12rem;
            text-align: right;
        }

        @media screen and (max-width: $breakpoint-xx-small) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.8rem;
            padding: 1.6rem 0;
            @include bodyText('medium');

            &-value {
                text-align: left;
            }
        }
    }


    &--hidden {
        @media screen and (max-width: $breakpoint-small) {
            .nyk-table__container {
                .nyk-table__container__table {
                    &__body__row {
                        display: none;

                        &:first-child {
                            display: flex;
                        }
                    }
                }
            }
        }
    }

    &--collapse {
        .nyk-table__container {
            border-top: none;
        }
    }
}
