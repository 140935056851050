.nyk-text-article {
    display: flex;
    flex-direction: column;

    &__hero {
        &.nyk-hero-topic-pages {
            row-gap: 2.8rem;
        }

        &__meta {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            &__tag {
                @include bodyText("small");
                padding: 0.4rem 1.2rem;
                border-radius: 0.6rem;
                background-color: var(--colortheme-beta);
            }

            &__name {
                @include bodyText("small");
                color: var(--colortheme-primary-dark-60);
            }

            &__divider {
                @include bodyText("small");
                color: var(--colortheme-primary-dark-60);
            }

            &__time-to-read {
                @include bodyText("small");
                color: var(--colortheme-primary-dark-60);
            }
        }

        .nyk-hero-topic-pages__subject {
            @media screen and (max-width: $breakpoint-small) {
                margin-top: 0.4rem;
            }
        }

        .nyk-hero-topic-pages__title {
            grid-column: 2;
            margin-bottom: 2rem;

            @media screen and (max-width: $breakpoint-small) {
                grid-column: 1;
            }
        }

        .nyk-hero-topic-pages__description {
            @media screen and (max-width: $breakpoint-small) {
                padding-top: 0;
            }
        }
    }

    &__visual {
        padding: 6rem 0 3rem 0;
    }

    .nyk-section-block {
        padding-top: 3rem;
        padding-bottom: 3rem;

        .nyk-richtext {
            width: 100%;
            margin: 0;

            > * {
                width: 100%;
            }
        }

        &--no-title {
            .nyk-richtext {
                > * {
                    width: $column-aside-big;

                    @media screen and (max-width: $breakpoint-small) {
                        width: 100%;
                    }
                }
            }

            .nyk-section-block__content {
                .nyk-richtext {
                    > * {
                        width: 100%;
                    }
                }
            }
        }
    }

    .nyk-table {
        width: 100%;

        @media screen and (max-width: $breakpoint-large) {
            width: unset;
        }

        & > .nyk-table__header {
            width: $column-aside-big;
            margin-left: auto;

            @media screen and (max-width: $breakpoint-small) {
                width: 100%;
            }
        }

        & > .nyk-table__container {
            width: $column-aside-big;
            margin: 0;
            margin-left: auto;

            @media screen and (max-width: $breakpoint-small) {
                width: 100%;
            }
        }
    }

    .nyk-person-card--horizontal {
        margin-top: 4rem;
        margin-bottom: 4rem;

        @media screen and (max-width: $breakpoint-computed-x-large) {
            width: calc($column-aside-big - 12rem);
        }

        @media screen and (max-width: $breakpoint-large) {
            width: calc($column-aside-big - 11rem);
        }

        @media screen and (max-width: $breakpoint-medium) {
            width: calc($column-aside-big - 3rem);
        }

        @media screen and (max-width: $breakpoint-small) {
            width: auto;
        }
    }

    .nyk-image {
        @media screen and (min-width: $breakpoint-computed-x-large) {
            width: 100%;
        }
    }

    &--podcast {
        .nyk-text-article__visual {
            margin-left: auto;

            @media screen and (min-width: $breakpoint-large) {
                margin: 0 $margin-x-large;
            }

            @media screen and (min-width: $breakpoint-computed-x-large) {
                margin: 0 auto;
                width: 100%;
            }

            @media screen and (max-width: $breakpoint-large) {
                margin: 0 $margin-large;
            }

            @media screen and (max-width: $breakpoint-medium) {
                margin: 0 $margin-medium;
            }

            @media screen and (max-width: $breakpoint-x-small) {
                margin: 0 $margin-x-small;
            }

            .video.video {
                width: $column-aside-big;

                @media screen and (max-width: $breakpoint-small) {
                    width: 100%;
                }

                iframe {
                    width: 100%;
                }
            }
        }
    }

    &--webinar {
        .nyk-text-article__visual {
            padding: 0;
        }

        .webinar {
            width: 100%;
            height: 100%;
            aspect-ratio: calc(16 / 9);
            border-radius: var(--border-radius);
            overflow: hidden;

            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }

    & .nyk-newsletters-signup {
        margin: 0 auto;
        width: 100%;

        .nyk-signup-step {
            margin: 0 auto;
        }
    }
}
