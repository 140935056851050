.nyk-send-calculation {
    margin-bottom: 1.8rem;

    &--alfa {
        .nyk-send-calculation {
            background-color: var(--colortheme-alfa);
        }

        .nyk-signup-step__content {
            background-color: var(--colortheme-alfa);
        }

        .nyk-signup-step__content {
            &--animation {
                &::before {
                    background-color: var(--colortheme-alfa);
                }
            }
        }
    }

    &--beta {
        .nyk-send-calculation {
            background-color: var(--colortheme-beta);
        }

        .nyk-signup-step__content {
            background-color: var(--colortheme-beta);
        }

        .nyk-signup-step__content {
            &--animation {
                &::before {
                    background-color: var(--colortheme-beta);
                }
            }
        }
    }

    &--gamma {
        .nyk-send-calculation {
            background-color: var(--colortheme-gamma);
        }

        .nyk-signup-step__content {
            background-color: var(--colortheme-gamma);
        }

        .nyk-signup-step__content {
            &--animation {
                &::before {
                    background-color: var(--colortheme-gamma);
                }
            }
        }
    }

    &--delta {
        .nyk-send-calculation {
            background-color: var(--colortheme-delta);
        }

        .nyk-signup-step__content {
            background-color: var(--colortheme-delta);
        }

        .nyk-signup-step__content {
            &--animation {
                &::before {
                    background-color: var(--colortheme-delta);
                }
            }
        }
    }

    &--epsilon {

        * :focus-visible {
            outline-color: var(--colortheme-neutral-7);
        }

        .nyk-send-calculation {
            background-color: var(--colortheme-epsilon);
        }

        .nyk-signup-step__content {
            background-color: var(--colortheme-epsilon);
        }

        .nyk-signup-step__content {
            &--animation {
                &::before {
                    background-color: var(--colortheme-epsilon);
                }
            }
        }
    }
}
