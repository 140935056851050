.nyk-calculator-info-box {
    grid-area: calcInfo;
    padding: 3.2rem $margin-medium;
    border-radius: $margin-x-small;
    background-color: var(--colortheme-accent-secondary);
    display: flex;
    flex-direction: column;

    &__title {
        @include heading('medium');
    }

    &__description {
        margin-top: 6.4rem;
        @include bodyText('medium');
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        p {
            margin: 0;
        }

        & a {
            color: var(--colortheme-primary-dark);
            text-decoration: underline;
        }
    }

    &__repayment {
        @include heading('small');
        font-weight: 700;
    }

    &__action {
        margin-top: auto;
        padding-top: 4.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-text {
            @include bodyText('medium');
            font-weight: 500;
        }

        &-icon {
            position: relative;
            width: 4.4rem;
            height: 4.4rem;
            border-radius: 4.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--colortheme-neutral-7);
        }
    }
}
