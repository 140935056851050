.nyk-chat-feedback-review {
  margin: auto;
  padding: 0 2.4rem 2.4rem 2.4rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  &__final-message {
    font-weight: 400;
    @include fluid-size('font-size', '32', '27', '375', '1420');
    font-size: 3.2rem;
    margin: 0.4rem 0 1.4rem;
    line-height: 1.2;
    >* {
        margin: 0 0 1.2rem;
    }
  }
  &__review-message {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;

  }
  &__heading {
    margin-bottom: 1.2rem;
    display: inline-block;
    line-height: 1;
    @include fluid-size('font-size', '140', '90', '375', '1420');
  }
  &__sub-heading {
    font-size: 1.8rem;
    > * {
      margin: 1.2rem 0;
    }
  }
  &__close-btn {
    position: relative;
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  &__footer {
    margin-top: auto;
  }
}
