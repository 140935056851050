.nyk-not-found-page {
  &__body {
    display: grid;
    grid-template-columns: calc((100% - $max-width) / 2) calc($max-width / 3) auto calc(
        (100% - $max-width) / 2
      );
    grid-template-areas:
      ". page-not-found-title page-not-found-graphic page-not-found-graphic"
      ". . page-not-found-description ."
      ". . page-not-found-search ."
      ". . page-not-found-actions .";
    row-gap: 4rem;
    margin: 8rem 0 6rem 0;

    @media screen and (min-width: $breakpoint-large) {
      grid-template-columns: $margin-x-large calc($max-width / 3) auto $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
      grid-template-columns: calc((100% - $max-width) / 2) calc($max-width / 3) auto calc(
          (100% - $max-width) / 2
        );
    }

    @media screen and (max-width: $breakpoint-large) {
      grid-template-columns: $margin-large calc($max-width / 3) auto $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
      grid-template-columns:
        $margin-medium calc((100% - ($margin-medium * 2)) / 3)
        auto $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
      grid-template-areas:
        ". page-not-found-title page-not-found-title ."
        "page-not-found-graphic page-not-found-graphic page-not-found-graphic page-not-found-graphic"
        ". page-not-found-description page-not-found-description ."
        ". page-not-found-search page-not-found-search ."
        ". page-not-found-actions page-not-found-actions .";
      margin: 4rem 0 6rem 0;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      grid-template-columns: $margin-x-small auto auto $margin-x-small;
      grid-template-areas:
        ". page-not-found-title page-not-found-title ."
        ". page-not-found-description page-not-found-description ."
        ". page-not-found-search page-not-found-search ."
        ". page-not-found-actions page-not-found-actions .";
    }

    &__title {
      grid-area: page-not-found-title;
      @include heading("x-large");
    }

    &__404-graphic {
      grid-area: page-not-found-graphic;
      margin-bottom: 4rem;

      @media screen and (max-width: $breakpoint-small) {
        margin: 4rem 0;
      }

      @media screen and (max-width: $breakpoint-x-small) {
        display: none;
      }
    }

    &__description {
      grid-area: page-not-found-description;

      &.nyk-richtext * {
        @include heading("small");
      }
    }

    &__search {
      grid-area: page-not-found-search;
      margin-bottom: 1.6rem;

      .nyk-search-input-field {
        margin: 0;
      }
    }

    &__actions {
      grid-area: page-not-found-actions;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }
}
