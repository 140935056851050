.nyk-accordion__content {
  & > * {
    margin-top: 3rem;
    margin-bottom: 3rem;

    &:first-child {
      margin-top: 0;
    }
  }

  .nyk-richtext {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;

    &:first-child {
      margin-top: 0 !important;
    }
  }

  .nyk-button {
    margin-top: 1.2rem;
  }

  .nyk-table {
    padding: 0;
    margin: 3rem 0;

    &__header__title {
      @include heading("small");
    }
  }

  .nyk-image {
    padding: 0;
    margin: 3rem 0;

    &__content {
      width: 100%;
      max-width: unset;
    }
  }

  .nyk-video {
    padding: 0;
    margin: 3rem 0;

    &__content {
      width: 100%;
      max-width: unset;
    }
  }
}
