.nyk-new-loan-calculator-input {
    border-radius: var(--border-radius);
    background-color: var(--colortheme-neutral-7);
    margin: 2rem $margin-x-small 6rem 3.2rem;

    @media screen and (max-width: $breakpoint-small) {
        margin: 3.2rem 0;
        border-radius: 0;
    }

    &__content-wrapper {
        padding: 6rem 0;
        display: flex;
        grid-template-rows: auto;
        gap: 4rem;

        @media screen and (min-width: $breakpoint-large) {
            margin: 0 $margin-x-large;
        }

        @media screen and (min-width: $breakpoint-computed-x-large) {
            max-width: $max-width;
            width: 100%;
            margin: 0 auto;
        }

        @media screen and (max-width: $breakpoint-large) {
            padding: 6rem $margin-large;
        }

        @media screen and (max-width: $breakpoint-medium) {
            padding: 6rem $margin-medium;
        }

        @media screen and (max-width: $breakpoint-small) {
            padding: 3.2rem 2.4rem;
            flex-direction: column;
            gap: 2.4rem;
        }

        @media screen and (max-width: $breakpoint-x-small) {
            padding: 3.2rem $margin-x-small;
        }
    }

    &__content {
        margin-left: auto;
        max-width: $column-aside-big;
        width: 100%;

        @media screen and (max-width: $breakpoint-small) {
            width: 100%;
            max-width: unset;
        }

        &-title {
            @include heading('medium');
            margin-bottom: 3.6rem;
        }

        &-actions {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            max-width: 50rem;
            padding-top: 2rem;
            gap: 2rem;

            @media screen and (max-width: $breakpoint-small) {
                width: 100%;
                max-width: unset;
            }

            .nyk-button.nyk-button--link {
                padding-right: 3.8rem;

                &:hover,
                &:focus {
                    .nyk-button__icon {
                        right: -0.4rem;
                    }
                }

                .nyk-button__icon {
                    right: 0;
                }
            }
        }
    }

    &__banner {
        padding-top: 6rem;

        .nyk-context-banner {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
