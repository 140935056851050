.nyk-social-links {
  max-width: $max-width;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    width: 100%;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &--right {
    .nyk-social-links__content {
      width: $column-aside-big;

      @media screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;
    flex-wrap: wrap;
    border-top: 1px solid var(--colortheme-primary-dark-20);
    border-bottom: 1px solid var(--colortheme-primary-dark-20);
    padding: 1.6rem 0;
    margin-left: auto;

    @media screen and (max-width: $breakpoint-small) {
      width: 100%;
    }

    &__media {
      display: flex;
      align-items: center;
      gap: 1.2rem;

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.4rem;
        height: 4.4rem;
        border: 1px solid var(--colortheme-primary-dark);
        border-radius: 4.4rem;

        svg {
          height: 1.6rem;
        }
      }
    }

    &__meta {
      @include bodyText("medium");
      color: var(--colortheme-primary-dark-60);
    }
  }
}
