.nyk-card-large {
  position: relative;
  display: grid;
  grid-template-columns: calc(50% - 2.2rem) calc(50% - 2.2rem);
  gap: 4.4rem;
  height: 50rem;
  padding: 2.4rem;
  border-radius: var(--border-radius);
  background-color: var(--colortheme-neutral-7);
  overflow: hidden;

  @media screen and (max-width: $breakpoint-small) {
    height: unset;
    display: flex;
    flex-direction: column;
  }

  &--transparent {
    height: 50rem;
    padding: 0;
    background-color: transparent;

    @media screen and (max-width: $breakpoint-small) {
      height: unset;

      .nyk-card-large__header {
        margin-bottom: 3.2rem !important;
      }
    }
  }

  &--image {
    height: 100%;
    min-height: 50rem;
    padding: 0;

    & img {
      border-radius: 1.6rem;
    }

    @media screen and (max-width: $breakpoint-small) {
      min-height: 45rem;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      min-height: 36rem;
    }

    .nyk-card-large__footer {
      position: absolute;
      right: 2.4rem;
      bottom: 2.4rem;
      margin-left: 2.4rem;
    }
  }

  &--color-primary {
    background-color: var(--colortheme-primary);

    * :focus-visible {
      outline-color: var(--colortheme-neutral-7);
    }

    .nyk-card-large__footer__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    .nyk-card-large__left__title,
    .nyk-card-large__left__text-big,
    .nyk-card-large__right__description,
    .nyk-card-large__right__link {
      color: var(--colortheme-neutral-7);
    }
  }

  &--color-primary-dark {
    background-color: var(--colortheme-primary-dark);

    * :focus-visible {
      outline-color: var(--colortheme-neutral-7);
    }

    .nyk-card-large__footer__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    .nyk-card-large__left__title,
    .nyk-card-large__left__text-big,
    .nyk-card-large__right__description,
    .nyk-card-large__right__link {
      color: var(--colortheme-neutral-7);
    }
  }

  &--color-accent-primary {
    background-color: var(--colortheme-accent-primary);

    * :focus-visible {
      outline-color: var(--colortheme-neutral-7);
    }

    .nyk-card-large__footer__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    .nyk-card-large__left__title,
    .nyk-card-large__left__text-big,
    .nyk-card-large__right__description,
    .nyk-card-large__right__link {
      color: var(--colortheme-neutral-7);
    }
  }

  &--color-accent-secondary {
    background-color: var(--colortheme-accent-secondary);

    .nyk-card-large__footer__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }
  }

  &--color-alfa {
    background-color: var(--colortheme-alfa);

    .nyk-card-large__footer__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }
  }

  &--color-beta {
    background-color: var(--colortheme-beta);

    .nyk-card-large__footer__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }
  }

  &--color-gamma {
    background-color: var(--colortheme-gamma);

    .nyk-card-large__footer__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }
  }

  &--color-delta {
    background-color: var(--colortheme-delta);

    .nyk-card-large__footer__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }
  }

  &--color-epsilon {
    background-color: var(--colortheme-epsilon);

    .nyk-card-large__footer__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }
  }

  &--color-neutral-2 {
    background-color: var(--colortheme-neutral-2);

    .nyk-card-large__footer__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }
  }

  &__header {
    grid-column: span 2;
    word-break: break-word;

    @media screen and (max-width: $breakpoint-small) {
      margin-bottom: 8rem;
    }

    &__title {
      @include heading("x-large");
      display: -webkit-box;
      line-clamp: 6;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    &__title {
      @include heading("medium");
      margin: 0;

      @media screen and (max-width: $breakpoint-small) {
        margin-bottom: 8rem;
      }
    }

    &__text-big {
      font-size: 12rem;
      line-height: 12.5rem;
      color: var(--colortheme-primary-dark);
      margin: 0;

      @media screen and (max-width: $custom-card-gallery-breakpoint) {
        font-size: 8.8rem;
        line-height: 8.85rem;
      }

      @media screen and (max-width: $breakpoint-small) {
        display: none;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__description {
      @include bodyText("large");
      margin: 0;

      @media screen and (max-width: $custom-card-gallery-breakpoint) {
        @include bodyText("medium");
      }

      @media screen and (max-width: $breakpoint-small) {
        @include bodyText("large");
        margin-bottom: 6rem;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      @include bodyText("medium");
    }
  }

  &__bg-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
