.nyk-hero-topic-pages {
    display: grid;
    grid-template-columns: calc(33.33333333% - 4rem) auto;
    grid-template-rows: auto;
    row-gap: 4.4rem;
    column-gap: 4rem;
    padding: 8rem 0 4rem 0;

    @media screen and (min-width: $breakpoint-large) {
        margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
        margin: 0 auto;
        max-width: $max-width;
        width: 100%;
    }

    @media screen and (max-width: $breakpoint-large) {
        margin: 0 $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
        margin: 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
        padding: 3.2rem 0;
        gap: 0;
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: $breakpoint-x-small) {
        margin: 0 $margin-x-small;
    }

    &__title {
        @include heading("x-large");
        word-break: break-word;

        @media screen and (max-width: $breakpoint-small) {
            grid-column: span 2;

            & + .nyk-hero-topic-pages__description {
                margin-top: 3.2rem;
            }

            &--big {
                font-size: 18rem;
                line-height: 18rem;
            }
        }
    }

    &__subject {
        @include heading("medium");
        hyphens: auto;
        line-height: 4rem;

        @media screen and (max-width: $breakpoint-small) {
            grid-column: span 2;

            & + .nyk-hero-topic-pages__title {
                margin-top: 2.4rem;
            }
        }
    }

    &__description {
        margin-top: 0.8rem !important;
        font-size: $fs-heading-medium !important;
        line-height: $fs-heading-medium * 1.2 !important;
        font-weight: 400 !important;
        color: var(--colortheme-primary-dark) !important;
        grid-column: 2;
        margin-top: 0.9rem;

        @media screen and (max-width: $breakpoint-small) {
            font-size: $fs-mobile-heading-medium !important;
            line-height: $fs-mobile-heading-medium * 1.3 !important;
            margin-top: 0.8rem;
            grid-column: span 2;
        }

        &.nyk-richtext {
            margin: 0;
            padding-bottom: 0;

            & > * {
                font-size: $fs-heading-medium !important;
                line-height: $fs-heading-medium * 1.2 !important;
                font-weight: 400 !important;
                color: var(--colortheme-primary-dark) !important;
                white-space: unset;
                margin-left: unset;
                width: 100%;

                @media screen and (max-width: $breakpoint-small) {
                    font-size: $fs-mobile-heading-medium !important;
                    line-height: $fs-mobile-heading-medium * 1.3 !important;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__action {
        grid-column: 2;

        @media screen and (max-width: $breakpoint-small) {
            margin-top: 1.6rem;
            grid-column: span 2;
        }
    }
}