.nyk-video {
  display: flex;
  flex-direction: column;
  padding: 4rem 0 4.4rem 0;
  max-width: $max-width;
  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0 2.4rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &--right {
    align-items: flex-end;

    .nyk-video__content__description {
      margin-left: unset;
    }
  }

  &--hero {
    @media screen and (min-width: $breakpoint-large) {
      margin: 0 $margin-x-large 0 2.4rem;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
      margin: 0 calc((100% - $max-width) / 2) 0 calc((100% - calc($max-width + 9.2rem)) / 2);
      max-width: calc($max-width + 9.2rem);
    }

    @media screen and (max-width: $breakpoint-large) {
      margin: 0 $margin-large 0 2.4rem;
    }

    @media screen and (max-width: $breakpoint-medium) {
      margin: 0 $margin-medium 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
      margin: 0 $margin-medium 0 1.2rem;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      margin: 0 $margin-x-small;
    }

    &.nyk-video--right {
      @media screen and (min-width: $breakpoint-large) {
        margin: 0 2.4rem 0 $margin-x-large;
      }

      @media screen and (min-width: $breakpoint-computed-x-large) {
        margin: 0 calc((100% - calc($max-width + 9.2rem)) / 2) 0 calc((100% - $max-width) / 2);
        max-width: calc($max-width + 9.2rem);
      }

      @media screen and (max-width: $breakpoint-large) {
        margin: 0 2.4rem 0 $margin-large;
      }

      @media screen and (max-width: $breakpoint-medium) {
        margin: 0 2.4rem 0 $margin-medium;
      }

      @media screen and (max-width: $breakpoint-small) {
        margin: 0 1.2rem 0 $margin-medium;
      }

      @media screen and (max-width: $breakpoint-x-small) {
        margin: 0 $margin-x-small;
      }
    }

    .nyk-video__content {
      width: 100%;
      max-width: unset;

      &__description {
        max-width: $column-aside-big;

        @media screen and (max-width: $breakpoint-x-small) {
          max-width: unset;
        }
      }
    }

    .nyk-video__content__placeholder-image__play {
      bottom: 6rem;
      left: 6rem;
      @media screen and (max-width: $breakpoint-small) {
        bottom: 4rem;
        right: 4rem;
      }

      @media screen and (max-width: $breakpoint-x-small) {
        bottom: 3.2rem;
        right: 3.2rem;
      }
    }

    .nyk-video__content__cookie {
      &__title {
        @include heading("large");

        @media screen and (max-width: $breakpoint-small) {
          font-size: $fs-mobile-heading-large;
          line-height: $fs-mobile-heading-large * 1.15;
        }

        @media screen and (max-width: $breakpoint-x-small) {
          font-size: $fs-heading-small;
          line-height: $fs-heading-small * 1.5;
        }
      }
    }
  }


  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4.4rem;
    width: 100%;
    max-width: $column-aside-big;
    aspect-ratio: calc(16 / 9);

    @media screen and (max-width: $breakpoint-small) {
      max-width: unset;
      gap: 2.4rem;
    }

    &__placeholder-image {
      position: relative;
      aspect-ratio: calc(16 / 9);
      background-color: var(--colortheme-neutral-4);
      width: auto;
      border-radius: var(--border-radius);
      cursor: pointer;
      border: none;
      padding: 0;

      &:focus-visible {
        outline: 2px solid;
        outline-color: var(--colortheme-delta);
      }

      @media screen and (max-width: $breakpoint-small) {
        max-width: unset;
      }

      @media screen and (max-width: $breakpoint-x-small) {
        aspect-ratio: calc(1 / 1);
        max-width: unset;
      }

      &__img {
        aspect-ratio: calc(16 / 9);
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: var(--border-radius);
      }

      &__play {
        position: absolute;
        bottom: 4.4rem;
        right: 4.4rem;
        width: 5.6rem;
        height: 5.6rem;
        padding: 0.8rem;
        background-color: var(--colortheme-neutral-7);
        border-radius: 1.2rem;
        border: none;
        cursor: pointer;
        transition: 0.3s;

        @media screen and (max-width: $breakpoint-small) {
          bottom: 4rem;
          right: 4rem;
        }

        @media screen and (max-width: $breakpoint-x-small) {
          bottom: 3.2rem;
          right: 3.2rem;
        }

        &:hover {
          border-radius: 5.6rem;
        }

        &:active {
          border-radius: 5.6rem;

          svg {
            transform: scale(0.8);
          }
        }

        svg {
          transition: 0.2s;
          path {
            fill: var(--colortheme-primary-dark);
          }
        }

        &--dark {
          background-color: var(--colortheme-primary-dark);

          svg {
            path {
              fill: var(--colortheme-neutral-7);
            }
          }
        }
      }
    }

    &__video {
      position: absolute;
      aspect-ratio: calc(16 / 9);
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      overflow: hidden;

      @media screen and (max-width: $breakpoint-small) {
        max-width: unset;
      }

      @media screen and (max-width: $breakpoint-x-small) {
        height: unset;
        max-width: unset;
      }
  
      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .nyk-richtext {
      margin: 0;
    }

    &__description {
      @include heading("medium");
      margin-left: auto;

      @media screen and (max-width: $breakpoint-medium) {
        font-size: $fs-mobile-heading-small;
      }

      @media screen and (max-width: $breakpoint-small) {
        max-width: unset;
      }
    }

    &__cookie {
      position: relative;
      aspect-ratio: calc(16 / 9);
      object-fit: cover;
      height: 100%;
      border-radius: var(--border-radius);
      background-color: var(--colortheme-neutral-4);
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 4.4rem;
      padding: 4rem 8rem;

      @media screen and (max-width: $breakpoint-small) {
        max-width: unset;
      }

      @media screen and (max-width: $breakpoint-x-small) {
        aspect-ratio: calc(1 / 1);
        max-width: unset;
        padding: 3.2rem 2.4rem;
      }

      &__title {
        @include heading("large");

        @media screen and (max-width: $breakpoint-x-small) {
          font-size: $fs-heading-small;
          line-height: $fs-heading-small * 1.5;
        }
      }

      &__play {
        position: absolute;
        bottom: 4.4rem;
        right: 4.4rem;
        width: 5.6rem;
        height: 5.6rem;
        padding: 0.8rem;
        background-color: var(--colortheme-neutral-5-40);
        border-radius: 1.2rem;
        border: none;

        @media screen and (max-width: $breakpoint-small) {
          bottom: 4rem;
          right: 4rem;
        }

        @media screen and (max-width: $breakpoint-x-small) {
          display: none;
        }

        svg {
          transition: 0.2s;
          path {
            fill: var(--colortheme-neutral-7-40);
          }
        }
      }
    }
  }
}
