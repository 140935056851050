.nyk-breaking-news {
  z-index: $submenudropdown;
  padding: 2.4rem 0;
  background-color: var(--colortheme-accent-secondary);

  @media screen and (min-width: $breakpoint-large) {
    padding: 2.4rem $margin-x-large;
  }

  @media screen and (max-width: $breakpoint-large) {
    padding: 2.4rem $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    padding: 2.4rem $margin-medium;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    padding: 2.4rem $margin-x-small;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    row-gap: 2rem;
    column-gap: 4rem;
    flex-wrap: wrap;
    max-width: $max-width;
    margin: 0 auto;

    &__info {
      display: flex;
      align-items: center;

      &__description {
        @include bodyText("large");
        margin: 0;

        &__label {
          @include bodyText("large");
          font-weight: 700;
        }
      }

      &__date {
        @include bodyText("large");
        margin: 0;
      }
    }

    &__link {
      margin-left: auto;
      font-weight: 500;
    }
  }
}
