.nyk-anker-menu {
  display: flex;
  max-width: $max-width;
  padding: 4rem 0 6rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &__content {
    width: $column-aside-big;
    margin-left: auto;

    @media screen and (max-width: $breakpoint-small) {
      margin-left: 0;
      width: 100%;
    }

    .nyk-anker-menu__nav {
      &__list {
        padding-left: 0;
        list-style: none;

        &__item {
          border-bottom: 1px solid var(--colortheme-primary-dark-20);

          &__link {
            text-decoration: none;
            display: flex;
            justify-content: space-between;
            gap: 4rem;
            padding: 1.6rem 0;
            color: var(--colortheme-primary-dark);

            @media screen and (max-width: $breakpoint-small) {
              gap: 2.4rem;
            }

            &__title {
              @include bodyText("medium");
              font-weight: 500;
            }

            &__icon {
              display: flex;
              align-items: center;

              svg {
                width: 1.2rem;
              }
            }
          }
        }
      }
    }
  }
}
