.nyk-news-insights-overview {
  &__articles {
    max-width: $max-width;
    padding: 4rem 0 6rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1.6rem;

    .nyk-standard-card {
      &:nth-child(1) {
        min-height: 50rem;
        grid-row: span 2;
      }

      &:nth-child(2),
      &:nth-child(3) {
        grid-row: span 1;
      }
    }

    @media screen and (min-width: $breakpoint-large) {
      margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
      margin: 0 auto;
      max-width: $max-width;
    }

    @media screen and (max-width: $breakpoint-large) {
      margin: 0 $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
      margin: 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
      padding: 3.2rem 0;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      margin: 0 $margin-x-small;
    }
  }
}
