.nyk-chat-feedback-input {
    display: flex;
    flex-direction: column;
    padding: 2.4rem;
  &__heading {
    font-weight: 400;
    font-size: 3.2rem;
    margin: 0.4rem 0 1.4rem;
    line-height: 1.2;
    max-width: 27.6rem;
  }
  &__feedback-buttons {
    display: flex;
    margin-top: 3.6rem;
    gap: 3rem;
  }
  &__button {
    width: 4.6rem;
    height: 5.1rem;
    position: relative;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    > svg {
      width: 4.6rem;
      height: 4.4rem;
    }
  }
  &__close-btn {
    position: relative;
    margin-top: auto;
    margin-bottom: 2.4rem;
  }
}
