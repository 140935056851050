.nyk-editors-recommendation-news {
  max-width: $max-width;
  padding: 6rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &__header {
    margin-bottom: 4rem;

    &__title {
      @include heading("x-large");
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: $margin-x-small;
    row-gap: 6rem;

    @media screen and (max-width: $breakpoint-medium) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: $breakpoint-small) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $breakpoint-x-small) {
      row-gap: 0;
      grid-template-columns: repeat(1, 1fr);
    }

    &__item {
      display: flex;
      flex-direction: column;
      padding: 2.4rem 0.8rem 1rem 0.8rem;
      border-top: 1px solid var(--colortheme-primary-dark-20);

      @media screen and (max-width: $breakpoint-small) {
        padding: 3rem 0.8rem;
      }

      & > a {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      &__meta {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        margin-bottom: 2.4rem;

        &__tag {
          @include bodyText("small");
          padding: 0.4rem 1.2rem;
          border-radius: 0.6rem;
          background-color: var(--colortheme-beta);
        }

        &__name {
          @include bodyText("small");
          color: var(--colortheme-primary-dark-60);
        }
      }

      &__header {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;

        @media screen and (max-width: $breakpoint-x-small) {
          flex-direction: row;
          align-items: center;
          gap: $margin-x-small;
        }

        &__icon {
          svg {
            height: 4rem;
          }
        }

        &__title {
          @include heading("x-small");
          font-weight: 700;

          @media screen and (max-width: $breakpoint-small) {
            font-size: $fs-body-medium;
          }
        }

        &__arrow {
          margin-left: auto;
          display: none;

          @media screen and (max-width: $breakpoint-x-small) {
            display: block;
          }

          svg {
            width: 1.4rem;
          }
        }
      }

      &__actions {
        margin-top: auto;
        padding-top: 5.4rem;

        @media screen and (max-width: $breakpoint-x-small) {
          display: none;
        }
      }
    }
  }
}
