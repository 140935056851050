.nyk-feedback-block {
  @include cardBody();
  min-height: 24.6rem;
  height: 100%;
  background-color: var(--colortheme-alfa);
  display: inline-block;

  @media screen and (max-width: $breakpoint-small) {
    display: block;
  }

  &--full-width {
    display: block;
  }
}
