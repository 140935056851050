.nyk-document-list {
  padding: 6rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &--right {
    .nyk-document-list__header {
      display: grid;
      grid-template-columns: auto $column-aside-big;

      @media screen and (max-width: $breakpoint-small) {
        display: flex;
        flex-direction: column;
        gap: 4.4rem;
      }

      &__title {
        @include heading("medium");

        @media screen and (max-width: $breakpoint-small) {
          @include heading("x-large");
        }
      }
    }

    .nyk-document-list__content {
      width: $column-aside-big;
      flex-direction: column;
      margin-left: auto;

      @media screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 4.4rem;
    margin-bottom: 6rem;

    &__title {
      @include heading("x-large");
    }

    &__description {
      @include bodyText("large");
      margin: 0;
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 1.6rem;

    @media screen and (max-width: $breakpoint-small) {
      flex-direction: column;
      gap: 7.6rem;
    }

    &__group {
      flex: 1;
      width: 100%;

      &__header {
        padding: 0 0 1.6rem 0.8rem;
        border-bottom: 1px solid var(--colortheme-primary-dark-40);

        &__title {
          @include bodyText("large");
          margin: 0;
        }
      }

      &__list {
        padding: 0;
        list-style: none;

        &__item {
          &__link {
            display: grid;
            grid-template-columns: 4.8rem auto 5.6rem;
            gap: 2.4rem;
            align-items: center;
            padding: 1.2rem 0;
            border-bottom: 1px dashed var(--colortheme-primary-dark-20);
            cursor: pointer;
            text-decoration: none;

            &__filetype {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 2rem;

              &__screen-reader {
                visibility: hidden;
                height: 0;
              }

              svg {
                height: 2.8rem;
                width: 2.8rem;

                path {
                  stroke: var(--colortheme-primary-dark);
                }

                path:nth-child(3) {
                  stroke: none;
                  fill: var(--colortheme-primary-dark);
                }

              }
            }

            &__info {
              &__date {
                @include bodyText("small");
                color: var(--colortheme-primary-dark-60);
              }

              &__name {
                @include bodyText("large");
                word-break: break-word;
                margin: 0;
              }
            }

            &__download {
              display: flex;
              align-items: center;
              margin-left: 0.8rem;
              margin-right: 2rem;

              &__screen-reader {
                visibility: hidden;
                width: 0;
              }

              svg {
                width: 2.8rem;
                height: 2.8rem;

                path {
                  stroke: var(--colortheme-primary-dark);
                }

              }
            }
          }
        }
      }
    }
  }
}
