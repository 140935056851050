.nyk-chat-menu {
    display: flex;
    flex-direction: column;
    background: var(--colortheme-bg-color);
    margin: 0 2.4rem 2.4rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @media screen and (min-width: $breakpoint-small), screen and (max-height: $chatPanelHeightBreakpoint) {
        background-color: white;
    }
    &__container {
        padding-bottom: 2.4rem;
    }
    &__heading {
        font-weight: 400;
        font-size: 3.2rem;
        margin: 0 0 2rem;
        display: inline-block;
    }
    &__menu {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-top: auto;
        margin-bottom: auto;
        gap: 1.2rem;
    }
    &__menu-item {
        display: flex;
        padding: 2rem;
        background-color: white;
        border-radius: 1.6rem;
        gap: 0.8rem;
        border: none;
        color: var(--colortheme-primary-dark);
        text-decoration: none;
        cursor: pointer;
        align-items: center;
        min-height: 7rem;
        &:hover {
            background-color: var(--colortheme-neutral-2);
        }
        @media screen and (min-width: $breakpoint-small) {
            background-color: var(--colortheme-neutral-1);
        }
        &:disabled {
            cursor: not-allowed;
            background-color: var(--colortheme-neutral-3);
            border-color: var(--colortheme-neutral-3);
            color: var(--colortheme-neutral-5);
            > svg {
                >path {
                    stroke: var(--colortheme-neutral-5);
                }
            }
        }
        > svg {
            width: 1.4rem;
            height: 1.4rem;
        }
    }
}
