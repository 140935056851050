@mixin fluid-size($attribute, $minsize, $maxsize, $minviewport, $maxviewport) {
  #{$attribute}: calc(#{$minsize}px + (#{$maxsize} - #{$minsize}) * ((100vw - #{$minviewport}px) / (#{$maxviewport} - #{$minviewport})));

  @media screen and (min-width:#{$maxviewport}px) {
    #{$attribute}: #{$maxsize}px;
  }

  @media screen and (max-width:#{$minviewport}px) {
    #{$attribute}: #{$minsize}px;
  }
}

@mixin heading($sizeName: "xxx-large", $mobileBreakPoint: $breakpoint-small) {
  margin: 0;

  @if ($sizeName == "xxx-large") {
    font-size: $fs-heading-xxx-large;
    line-height: 110%;
    font-weight: 400;
    color: var(--colortheme-primary-dark);

    @media screen and (max-width: $mobileBreakPoint) {
      font-size: $fs-mobile-heading-xxx-large;
      line-height: 110%;
    }
  } @else if ($sizeName == "xx-large") {
    font-size: $fs-heading-xx-large;
    line-height: 110%;
    font-weight: 400;
    color: var(--colortheme-primary-dark);

    @media screen and (max-width: $mobileBreakPoint) {
      font-size: $fs-mobile-heading-xx-large;
      line-height: 120%;
    }
  } @else if ($sizeName == "x-large") {
    font-size: $fs-heading-x-large;
    line-height: 120%;
    font-weight: 400;
    color: var(--colortheme-primary-dark);

    @media screen and (max-width: $mobileBreakPoint) {
      font-size: $fs-mobile-heading-x-large;
      line-height: 120%;
    }
  } @else if ($sizeName == "large") {
    font-size: $fs-heading-large;
    line-height: 120%;
    font-weight: 400;
    color: var(--colortheme-primary-dark);

    @media screen and (max-width: $mobileBreakPoint) {
      font-size: $fs-mobile-heading-large;
      line-height: 120%;
    }
  } @else if ($sizeName == "medium") {
    font-size: $fs-heading-medium;
    line-height: 120%;
    font-weight: 400;
    color: var(--colortheme-primary-dark);

    @media screen and (max-width: $mobileBreakPoint) {
      font-size: $fs-mobile-heading-medium;
      line-height: 130%;
    }
  } @else if ($sizeName == "small") {
    font-size: $fs-heading-small;
    line-height: 130%;
    font-weight: 400;
    color: var(--colortheme-primary-dark);

    @media screen and (max-width: $mobileBreakPoint) {
      font-size: $fs-mobile-heading-small;
      line-height: 130%;
    }
  } @else if ($sizeName == "x-small") {
    font-size: $fs-heading-x-small;
    line-height: 150%;
    font-weight: 400;
    color: var(--colortheme-primary-dark);

    @media screen and (max-width: $mobileBreakPoint) {
      font-size: $fs-mobile-heading-x-small;
      line-height: 150%;
    }
  }
}

@mixin bodyText($sizeName: "large", $mobileBreakPoint: $breakpoint-medium) {
  @if ($sizeName == "large") {
    font-family: var(--font-family);
    font-size: $fs-body-large;
    line-height: 150%;
    color: var(--colortheme-primary-dark);
  } @else if ($sizeName == "medium") {
    font-family: var(--font-family);
    font-size: $fs-body-medium;
    line-height: 150%;
    color: var(--colortheme-primary-dark);
  } @else if ($sizeName == "small") {
    font-family: var(--font-family);
    font-size: $fs-body-small;
    line-height: 150%;
    color: var(--colortheme-primary-dark);
  } @else if ($sizeName == "x-small") {
    font-family: var(--font-family);
    font-size: $fs-body-x-small;
    line-height: 150%;
    color: var(--colortheme-primary-dark);

    @media screen and (max-width: $mobileBreakPoint) {
      font-size: $fs-body-x-small;
      line-height: 125%;
    }
  }
}

@mixin button() {
  width: -moz-fit-content;
  width: fit-content;
  display: inline-flex;
  align-items: center;
  gap: 1rem; // 10px
  padding: 1rem 2.4rem; // 10px 24px
  border: 1px solid var(--colortheme-neutral-7);
  border-radius: var(--border-radius-button);
  font-size: $fs-button;
  text-align: left;
  background-color: var(--colortheme-neutral-7);
  color: var(--colortheme-primary-dark);
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;

//   &:focus {
//     outline-offset: 1rem; // 10px
//     outline-width: 2px;
//     outline-color: var(--colortheme-primary-dark);
//   }
}

@mixin cardBody() {
  padding: 3.2rem 2.4rem;
  border-radius: var(--border-radius);
  background-color: var(--colortheme-neutral-2);
  overflow: hidden;
  box-sizing: border-box;
}