.nyk-find-center-map-and-employees {
  &__filters {
    padding: 4rem 0;
    max-width: $max-width;

    @media screen and (min-width: $breakpoint-large) {
      margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
      margin: 0 auto;
      max-width: $max-width;
    }

    @media screen and (max-width: $breakpoint-large) {
      margin: 0 $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
      margin: 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
      padding: 0;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      margin: 0 $margin-x-small;
    }

    .nyk-filter-tabs {
      margin-left: auto;
      max-width: $column-aside-big;
      border-bottom: 1px solid var(--colortheme-primary-dark-20);

      @media screen and (max-width: $breakpoint-small) {
        max-width: unset;
      }

      &__tab {
        border: none;

        @media screen and (max-width: $breakpoint-small) {
          flex: 1;
          max-width: unset;
        }
      }
    }
  }

  &__sections {
    &__content {
      &--hidden {
        display: none;
      }
    }
  }
}
