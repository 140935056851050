.nyk-quote {
  max-width: $max-width;
  padding: 4rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }
  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &__content {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 2.8rem;
    margin: 0;
    width: $column-aside-big;
    margin-left: auto;

    @media screen and (max-width: $breakpoint-small) {
      flex-direction: column;
      width: 100%;
      gap: 1.6rem;
    }

    &__icon {
      position: absolute;
      left: -7.6rem;

      @media screen and (max-width: $breakpoint-small) {
        position: relative;
        left: unset;
      }

      svg {
        width: 4.8rem;
      }
    }

    &__quote {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      &__text {
        font-size: $fs-heading-large !important;
        line-height: $fs-heading-large * 1.2 !important;
        font-weight: 400 !important;
        color: var(--colortheme-primary-dark) !important;

        @media screen and (max-width: $breakpoint-small) {
          font-size: $fs-mobile-heading-large !important;
          line-height: $fs-mobile-heading-large * 1.2 !important;
        }

        &.nyk-richtext {
          margin: 0;

          & > * {
            margin-left: unset;
            width: 100%;
          }
        }

        & > * {
          font-size: $fs-heading-large !important;
          line-height: $fs-heading-large * 1.2 !important;
          font-weight: 400 !important;
          color: var(--colortheme-primary-dark) !important;

          @media screen and (max-width: $breakpoint-small) {
            font-size: $fs-mobile-heading-large !important;
            line-height: $fs-mobile-heading-large * 1.2 !important;
          }
        }
      }

      &__author {
        @include bodyText("large");
        color: var(--colortheme-primary-dark-60);
      }
    }
  }
}
