.nyk-breaker {
  border: none;
  border-top: 1px solid #cdcedb;

  @media screen and (min-width: $breakpoint-large) {
    margin: 6rem $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 6rem auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 6rem $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 6rem $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    margin: 3.2rem $margin-medium;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 3.2rem $margin-x-small;
  }
}
