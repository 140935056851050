.nyk-feedback-step-three {
  height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__close {
    margin-left: auto;

    &__icon {
      height: 4.4rem;
      width: 4.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4.4rem;
      border: none;
      background-color: var(--colortheme-neutral-7);
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        background-color: var(--colortheme-neutral-2);
      }

      .nyk-icon {
        height: 1.6rem;
      }
    }
  }

  &__message {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: var(--colortheme-primary-dark);
    font-size: 18rem;
    margin: 0;
  }
}
