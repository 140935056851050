.nyk-loan-calculator-inputs {
    .nyk-form__content {
        max-width: 50rem;

        @media screen and (max-width: $breakpoint-small) {
            width: 100%;
            max-width: unset;
        }

        &__input,
        &__input:focus {
            -webkit-box-shadow: 0 0 0 30px transparent inset !important;
        }
    }

    .nyk-form__help-message,
    .nyk-form__error-message {
        max-width: 50rem;

        @media screen and (max-width: $breakpoint-small) {
            width: 100%;
            max-width: unset;
        }
    }

    .nyk-form-choice {
        margin-top: 0;
    }

    .nyk-button {
        margin-top: 2rem;
    }
}
