.nyk-form-container {
  max-width: $max-width;
  width: $column-aside-big;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large 0 auto;
    width: calc($column-aside-big - $margin-x-large - 3.2rem);
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 calc((100% - $max-width) / 2) 0 auto;
    max-width: $max-width * 0.66666667;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large 0 auto;
    width: calc($column-aside-big - $margin-large - 2.4rem);
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium 0 auto;
    width: calc($column-aside-big - $margin-medium - 0.8rem);
  }

  @media screen and (max-width: $breakpoint-small) {
    margin: 0 $margin-medium;
    padding: 3.2rem 0;
    max-width: unset;
    width: calc(100% - (2 * $margin-medium));
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
    width: calc(100% - (2 * $margin-x-small));
  }

  .FormStep__inner.FormStep__inner {
    padding: 0 !important;
  }

  .nyk-button-navigation {
    margin: 0;
  }

  .richtext {
    margin: 0;

    & > * {
      margin: 0 !important;
      width: 100% !important;
    }
  }

  .nyk-button {
    .tk-loader {
      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  .nyk-form-container {
    margin: 0;
    width: 100%;
  }
}
