.nyk-footer-contact-sparinvest {
  max-width: $max-width;
  padding: 6rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.6rem;
    row-gap: 3.2rem;

    @media screen and (max-width: $breakpoint-small) {
      grid-template-columns: repeat(1, 1fr);
    }

    &__title {
      @include heading("x-large");
    }

    &__card {
      padding: 2.4rem;
      background-color: var(--colortheme-neutral-7);
      display: flex;
      flex-direction: column;
      height: 50rem;

      &--call-us {
        height: 25rem;
        background-color: var(--colortheme-accent-secondary);
      }

      &__title {
        @include heading("medium");
      }

      &__content {
        margin-top: auto;

        &__description {
          @include heading("medium");
        }
      }

      &__footer {
        margin-top: 3.6rem;

        &__link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;
          @include bodyText("medium");
        }
      }

      &__bottom {
        margin-top: auto;

        &__description {
          @include bodyText("medium");
        }

        &__link-call {
          margin-top: 2rem;
          @include heading("medium");
          text-decoration: none;
        }
      }
    }
  }
}
