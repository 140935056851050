@import "../../../Blocks/Section/SectionBlock.scss";

.nyk-find-center-contact-persons {
  &__section {
    &__controls {
      max-width: $column-aside-big;
      margin-left: auto;
      padding: 2rem 0 4rem 0;
      display: flex;
      gap: 1.2rem;

      @media screen and (min-width: $breakpoint-large) {
        margin: 0 $margin-x-large 0 auto;
        max-width: calc($column-aside-big - 12.2rem); // 12.2rem is magic sweet spot
      }

      @media screen and (min-width: $breakpoint-computed-x-large) {
        margin: 0 calc((100% - $max-width) / 2) 0 auto;
        max-width: calc($max-width * 0.666666666667);
      }

      @media screen and (max-width: $breakpoint-large) {
        margin: 0 $margin-large 0 auto;
        max-width: calc($column-aside-big - 10.7rem); // 10.7rem is magic sweet spot
      }

      @media screen and (max-width: $breakpoint-medium) {
        margin: 0 $margin-medium 0 auto;
        max-width: calc($column-aside-big - 3.2rem); // 3.2rem is magic sweet spot
      }

      @media screen and (max-width: $breakpoint-small) {
        margin: 0 $margin-medium;
        max-width: unset;
        padding: 3.2rem 0 0 0;
        flex-direction: column;
        gap: 0.8rem;
      }

      @media screen and (max-width: $breakpoint-x-small) {
        margin: 0 $margin-x-small;
      }

      &__search {
        width: 100%;

        @media screen and (max-width: $breakpoint-small) {
          max-width: unset;
        }
      }

      &__select {
        max-width: 35rem;
        width: 100%;

        @media screen and (max-width: $breakpoint-small) {
          max-width: unset;
        }
      }

      .nyk-form {
        margin: 0;
      }
    }

    &__employees {
      .nyk-person-card-layout {
        padding-top: 0;

        @media screen and (max-width: $breakpoint-small) {
          padding-top: 2.4rem;
        }
      }
    }
  }
}

.nyk-person-card__header__section {
    padding: 0;

    .nyk-section-block__content {
        gap: unset;

        h4, p {
            margin: 0;
        }

        p {
            margin-bottom: $margin-medium;

            @media screen and (max-width: $breakpoint-medium) {
                margin-bottom: 0;
            }
        }
    }
}