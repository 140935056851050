.nyk-faq-page {
  padding-bottom: 6rem;

  @media screen and (max-width: $breakpoint-small) {
    padding-bottom: 3.2rem;
  }

  &__content {
    max-width: $max-width;
    padding-top: 2rem;

    @media screen and (min-width: $breakpoint-large) {
      margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
      margin: 0 auto;
      max-width: $max-width;
    }

    @media screen and (max-width: $breakpoint-large) {
      margin: 0 $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
      margin: 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
      padding-top: 0;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      margin: 0 $margin-x-small;
    }

    &__question {
      margin-left: auto;
      width: $column-aside-big;

      @media screen and (max-width: $breakpoint-small) {
        width: 100%;
      }

      &__label {
        @include heading("small");
        margin-bottom: 3.2rem;
      }

      &__description {
        @include heading("small");
        margin-bottom: 6rem;

        @media screen and (max-width: $breakpoint-small) {
          margin-bottom: 3.2rem;
        }
      }
    }

    .nyk-video {
      margin: 0;

      &__content {
        width: 100%;
        max-width: unset;
      }
    }
  }
}
