.nyk-search-overlay {
    padding-top: 3rem;

    @media screen and (max-width: $breakpoint-medium) {
        padding-top: 5rem;
    }

    @media screen and (max-width: $breakpoint-small) {
        // same as height on container nav with burger-menu-icon - to avoid overlap
        padding-top: 8.6rem;
    }

    @media screen and (max-width: $breakpoint-x-small) {
        padding-left: 0;
    }

    &__search {
        position: relative;
        display: flex;
        align-items: center;
        gap: 1.8rem;
        width: 100%;
        height: 5.6rem;
        max-width: 84.5rem;
        margin: 0 auto;

        @media screen and (max-width: $breakpoint-medium) {
            max-width: 75rem;
        }

        &__icon {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 2.4rem;
            padding: 0;
            background-color: transparent;
            border: none;
            cursor: pointer;
            z-index: 10;
            display: flex;

            @media screen and (max-width: $breakpoint-small) {
                display: none;
            }

            &--circle {
                left: 0.8rem;
                transform: translate(0, -48%);
                height: 4rem;
                width: 4rem;
                background-color: var(--colortheme-neutral-2);
                display: none;
                align-items: center;
                justify-content: center;
                padding: 0;
                border: none;
                border-radius: 2rem;
                cursor: pointer;

                @media screen and (max-width: $breakpoint-small) {
                    display: flex;
                }

                svg {
                    width: 1.6rem;
                    height: unset;
                }
            }

            svg {
                height: 2rem;
            }
        }

        &__input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 5.6rem;
            border: 1px solid var(--colortheme-primary-dark);
            border-radius: 5rem;
            background-color: transparent;
            padding: 0 6rem;
            @include bodyText("large");

            &:hover {
                box-shadow: inset 0 0 0 1px var(--colortheme-primary-dark);
            }

            &:focus {
                border-color: var(--colortheme-delta);
                box-shadow: inset 0 0 0 1px var(--colortheme-delta);
                outline: none;
            }
        }

        &__close {
            position: absolute;
            top: 50%;
            right: 2.4rem;
            transform: translate(0, -50%);
            display: none;
            padding: 0;
            background-color: transparent;
            border: none;
            cursor: pointer;
            z-index: 10;

            &--show {
                display: block;
            }

            .nyk-icon {
                width: 2rem;
            }
        }
    }

    &__content {
        margin: 0 auto;
        max-width: 84.5rem;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 5.6rem;

        @media screen and (max-width: $breakpoint-medium) {
            max-width: 75rem;
        }

        @media screen and (max-width: $breakpoint-small) {
            max-width: unset;
            margin-top: 2.4rem;
        }

        &--results {
            margin-top: 0;

            .nyk-search-overlay__content__results {
                padding-top: 2.4rem;
            }
        }

        &__results {
            padding: 3.2rem 2.4rem 0 10.8rem;

            @media screen and (max-width: $breakpoint-medium) {
                padding: 3.2rem 2.4rem 0 9.8rem;
            }

            @media screen and (max-width: $breakpoint-small) {
                padding: 0 2.4rem 0 2.4rem;
            }

            &__title {
                @include heading("medium");
                margin-bottom: 3.2rem;

                @media screen and (max-width: $breakpoint-small) {
                    margin-bottom: 2.4rem;
                }
            }

            &__list {
                list-style: none;
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 1.6rem;

                &__item {
                    &__link {
                        font-size: 1.8rem !important;
                        display: flex;
                        align-items: baseline;
                        justify-content: flex-start;

                        .nyk-button__icon {
                            flex-shrink: 0;
                            width: fit-content;

                            svg {
                                height: auto;
                                width: 2rem;

                                path {
                                    fill: var(--colortheme-primary-dark);
                                }
                            }
                        }
                    }
                }
            }

            &--suggestions {
                padding-left: 6rem;
                width: 100%;
                max-width: 84.5rem;

                @media screen and (max-width: $breakpoint-small) {
                    margin: 0 auto;
                    max-width: 75rem;
                    display: flex;
                    align-items: center;
                }

                @media screen and (max-width: $breakpoint-x-small) {
                    padding-left: 2.4rem;
                }

                .nyk-search-overlay__content__results__list {
                    gap: 2.4rem;
                }
            }
        }

        &__cards {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
            padding-right: 10.8rem;

            @media screen and (max-width: $breakpoint-medium) {
                padding-right: 9.8rem;
            }

            @media screen and (max-width: $breakpoint-small) {
                display: none;
            }

            &__card {
                @include cardBody();
                min-height: 35rem;
                max-width: 30rem;
                min-width: 27.4rem;
                display: flex;
                flex-direction: column;

                &--yellow {
                    background-color: var(--colortheme-alfa);
                }

                &--small {
                    min-height: unset;
                    padding: 2.6rem 2.4rem;
                }

                &__title {
                    @include heading("medium");
                }

                &__description {
                    margin-top: auto;
                    @include bodyText("medium");

                    & > * {
                        margin: 0 0 1.6rem 0;
                    }
                }

                &__title-link {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-decoration: none;

                    &__title {
                        @include heading("medium");
                    }

                    &__button {
                        flex-shrink: 0;
                        background-color: var(--colortheme-neutral-7) !important;
                        border-color: var(--colortheme-neutral-7) !important;
                    }
                }

                &__action {
                    margin-top: 5rem;

                    &__link {
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &__text {
                            @include bodyText("medium");
                        }

                        &__button {
                            background-color: var(--colortheme-neutral-7) !important;
                            border-color: var(--colortheme-neutral-7) !important;
                        }
                    }
                }
            }
        }
    }
}
