.nyk-signup-multiple-newsletters {
  .nyk-signup-step {
    &__content {
      background-color: var(--colortheme-beta);
      width: $column-aside-big;

      @media screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
    }

    &__checkbox__input:checked+.nyk-signup-step__checkbox__checkmark {
      background-color: var(--colortheme-delta);
      border-color: var(--colortheme-delta);
    }
  }
}