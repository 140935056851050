.nyk-table {
    padding: 6rem 0;

    @media screen and (min-width: $breakpoint-large) {
        margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
        margin: 0 auto;
        max-width: $max-width;
    }

    @media screen and (max-width: $breakpoint-large) {
        margin: 0 $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
        margin: 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
        padding: 3.2rem 0;
    }

    @media screen and (max-width: $breakpoint-x-small) {
        margin: 0 $margin-x-small;
    }

    &__filter {
        margin-bottom: 7.6rem;
    }

    &__header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 6rem;
        gap: 6rem;

        @media screen and (max-width: $breakpoint-small) {
            gap: 3.2rem;
            margin-bottom: 4.4rem;
            flex-direction: column;
            align-items: flex-start;
        }

        .nyk-richtext {
            margin: 0;

            & > * {
                margin-left: unset;
                width: 100%;
            }
        }

        &:empty {
            margin-bottom: 0;
        }

        &__title {
            @include heading('x-large');
            word-break: break-word;
        }

        &__button {
            white-space: nowrap;

            @media screen and (max-width: $breakpoint-small) {
                white-space: unset;
                margin-left: auto;
            }
        }
    }

    &__container {
        @media screen and (max-width: $breakpoint-x-small) {
            overflow-x: auto;

            &::-webkit-scrollbar {
                height: 1rem;
            }

            &::-webkit-scrollbar-track {
                background: var(--colortheme-neutral-2);
            }

            &::-webkit-scrollbar-thumb {
                background: var(--colortheme-primary-dark-20);
                border-radius: 1rem;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: var(--colortheme-primary-dark-40);
            }

            .nyk-table__container__table__body__row__item {
                min-width: 12rem;
                word-break: break-word;
            }
        }

        &.nyk-richtext {
            > * {
                width: 100%;
            }
        }

        &__table {
            border-collapse: collapse;
            width: 100%;

            &--spaced-equal {
                table-layout: fixed;

                @media screen and (max-width: $breakpoint-x-small) {
                    table-layout: unset;
                }
            }

            &--small {
                margin-left: auto;
                width: $column-aside-big;

                @media screen and (max-width: $breakpoint-small) {
                    width: calc(100% - 1.6rem);
                }
            }

            &__header {
                border-bottom: 1px solid var(--colortheme-primary-dark-40);

                &__row {
                    &__item {
                        text-align: left;
                        @include bodyText('large');
                        font-weight: 700;
                        padding: 1.6rem 0;
                        vertical-align: top;
                        padding-right: 3.2rem;

                        &:first-child {
                            padding-left: 0.8rem;
                        }

                        &:last-child {
                            padding-right: 0.8rem;
                        }

                        &--text-right {
                            text-align: right;
                        }

                        &--text-center {
                            text-align: center;
                        }

                        &--tooltip {
                            display: flex;
                            align-items: center;
                            gap: 1.2rem;
                        }
                    }
                }
            }

            &__body {
                &__row {
                    border-bottom: 1px dashed var(--colortheme-primary-dark-20);
                    transition: 0.2s;

                    &:hover {
                        background-color: var(--colortheme-neutral-2);
                    }

                    &--hide {
                        display: none;
                    }

                    &__item {
                        text-align: left;
                        @include bodyText('large');
                        font-weight: 500;
                        padding: 1.6rem 0;
                        vertical-align: top;
                        padding-right: 3.2rem;

                        &:first-child {
                            padding-left: 0.8rem;
                        }

                        &:last-child {
                            padding-right: 0.8rem;
                        }

                        &--text-right {
                            text-align: right;
                        }

                        &--text-center {
                            text-align: center;
                        }

                        &__screen-reader {
                            visibility: hidden;
                        }

                        &__flag {
                            width: 2.4rem;
                            height: 2.4rem;
                            border-radius: 2.4rem;
                        }

                        & > a {
                            text-decoration: none;
                            color: var(--colortheme-primary-dark);
                        }

                        & svg {
                            width: 2.8rem;
                            height: 2.8rem;
                            margin: 0 2.4rem;
                        }
                    }
                }
            }
        }
    }

    caption {
        @include bodyText('small');
        margin-bottom: 3.2rem;

        &.nyk-table__caption--place-below-table {
            text-align: left;
            caption-side: bottom;
            margin-top: 3.2rem;
        }
    }
}
