.nyk-social-media-post-collection {
    display: flex;
    justify-content: space-between;
    max-width: $max-width;
    margin: 0 auto;
    gap: 1.6rem;
    padding: 6rem 0;

    @media screen and (min-width: $breakpoint-large) {
        margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
        margin: 0 auto;
        max-width: $max-width;
    }

    @media screen and (max-width: $breakpoint-large) {
        margin: 0 $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
        margin: 0 $margin-medium;
        max-width: unset;
    }

    @media screen and (max-width: $breakpoint-small) {
        flex-direction: column;
        padding: 3.2rem 0;
    }

    @media screen and (max-width: $breakpoint-x-small) {
        margin: 0 $margin-x-small;
    }


    &__title {
        flex: 1;
        @include heading('medium');

        @media screen and (max-width: $breakpoint-large) {
            font-size: $fs-mobile-heading-medium;
        }

        @media screen and (max-width: $breakpoint-medium) {
            margin-bottom: 3.6rem;
            width: 100%;
        }
    }

    &__content {
        flex: 3;
        max-width: 85rem;
        display: flex;
        gap: 4.4rem;

        @media screen and (max-width: $breakpoint-large) {
            max-width: 70rem;
            width: 100%;
        }

        @media screen and (max-width: $breakpoint-medium) {
            max-width: unset;
        }

        @media screen and (max-width: $breakpoint-medium) {
            max-width: 70rem;
            width: 100%;
        }

        @media screen and (max-width: $breakpoint-small) {
            max-width: unset;
            flex-direction: column;
        }

        &__iframe-container {
            display: flex;
            align-items: center;
            width: 100%;

            iframe {
                width: 100%;
                border-radius: 2rem;
            }
        }
    }

    &__wide {
        flex-direction: column;

        .nyk-social-media-post-collection {
            &__title {
                width: 100%;
            }

            &__content {
                max-width: unset;
            }
        }
    }
}
