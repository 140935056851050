 .nyk-form--calculator {
    padding: 0;
    border: none;

    .nyk-form-choice__label {
        font-size: $fs-heading-x-small;
        font-weight: 700;
        margin-bottom: $margin-x-small;
    }

    .nyk-form-choice__content {
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: $breakpoint-small) {
            flex-direction: column;
        }
    }
}
