.nyk-data-box {
  margin: 6rem 1.6rem 6rem 3.2rem;
  padding: 6rem 0 20rem 0;
  background-color: var(--colortheme-alfa);
  border-radius: 1.6rem;

  @media screen and (max-width: $breakpoint-small) {
    margin: 3.2rem 1.6rem 10rem $margin-x-small;
    gap: 3.2rem;
  }

  &--alfa {
    background-color: var(--colortheme-alfa);
  }

  &--beta {
    background-color: var(--colortheme-beta);
  }

  &--gamma {
    background-color: var(--colortheme-gamma);
  }

  &--delta {
    background-color: var(--colortheme-delta);
  }

  &--epsilon {
    background-color: var(--colortheme-epsilon);

    * :focus-visible {
      outline-color: var(--colortheme-neutral-7);
    }

    .nyk-data-box__body__header__title {
      color: var(--colortheme-neutral-7);
    }

    .nyk-richtext {
      & > * {
        margin-left: unset;
        width: 100%;
      }

      * {
        color: var(--colortheme-neutral-7);
      }

      ul {
        li::marker {
          color: var(--colortheme-neutral-7);
        }
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: calc(33.33333333% - 5rem) auto;
    grid-template-rows: auto;
    row-gap: 4.4rem;
    column-gap: 4rem;
    max-width: $max-width;

    @media screen and (min-width: $breakpoint-large) {
      margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
      margin: 0 auto;
      max-width: $max-width;
    }

    @media screen and (max-width: $breakpoint-large) {
      margin: 0 $margin-large;
      grid-template-columns: calc(33.33333333% - 6rem) auto;
    }

    @media screen and (max-width: $breakpoint-medium) {
      margin: 0 $margin-medium;
      row-gap: 3.2rem;
    }

    @media screen and (max-width: $breakpoint-small) {
      grid-template-columns: auto;
      margin: 0 2.4rem;
    }

    &__header {
      //width: calc($column-aside-small - 4rem);

      @media screen and (max-width: $breakpoint-small) {
        width: 100%;
      }

      &__title {
        @include heading("medium");
        line-height: 4rem;
        word-break: break-word;
      }
    }

    &__content {
      width: 100%;

      @media screen and (max-width: $breakpoint-small) {
        width: 100%;
      }

      .nyk-richtext {
        padding: 0;
        margin: 0;
        margin-bottom: 4.4rem;

        & > * {
          margin-left: unset;
          width: 100%;
        }

        &:only-child {
          margin-bottom: 0;
        }

        li::marker {
          color: var(--colortheme-primary-dark);
        }
      }
    }
  }
}
