.nyk-feedback-step-two {
  min-height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__top {
    display: flex;
    align-items: flex-start;
    margin-bottom: 6rem;

    @media screen and (max-width: $breakpoint-small) {
      flex-direction: column;
      gap: 2.4rem;
    }

    &__title {
      flex: 1;
      @include heading("medium");

      @media screen and (max-width: $breakpoint-small) {
        flex: unset;
      }
    }

    &__description {
      flex: 1;
      @include bodyText("large");
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 5.6rem;

    &__text {
      padding-bottom: 1.6rem;
      border-bottom: 1px solid var(--colortheme-primary-dark-20);

      &__output {
        white-space: pre-line;
        word-break: break-word;
        width: 100%;
        font-size: 4rem;
        line-height: 150%;
        color: var(--colortheme-primary-dark);
        margin-top: 4rem;

        @media screen and (max-width: $breakpoint-small) {
          @include heading("small");
        }

        &:empty::before {
          content: attr(data-placeholder);
          @include heading("xx-large");

          @media screen and (max-width: $breakpoint-small) {
            @include heading("small");
          }
        }

        &:empty:focus::before {
          content: "";
          font-size: 4rem;
          line-height: 150%;

          @media screen and (max-width: $breakpoint-small) {
            @include heading("small");
          }
        }

        &:focus-visible {
          outline: none;
        }
      }
    }

    &__actions {
      margin-top: auto;
      margin-left: auto;

      &__action {
        display: flex;
        align-items: center;
        gap: 2.4rem;
        background-color: transparent;
        border: none;

        &__text {
          @include bodyText("medium");
          font-weight: 700;
        }

        &__button.nyk-button {
          background-color: var(--colortheme-neutral-7);
          border-color: var(--colortheme-neutral-7);
        }
      }
    }
  }
}
