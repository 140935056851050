$signupStepGeneralGap: 3.2rem;
$signupStepMinHeight: 50rem;

.nyk-signup-step {
  min-height: $signupStepMinHeight;
  box-sizing: border-box;
  max-width: $max-width;
  padding: 6rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
    min-height: 40.3rem;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &__content {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem $signupStepGeneralGap;
    min-height: $signupStepMinHeight;
    background-color: var(--colortheme-gamma);
    padding: 3.2rem 2.4rem;
    border-radius: 1.6rem;
    @include bodyText("small");

    @media screen and (max-width: $breakpoint-small) {
      flex-direction: column;
      min-height: 40.3rem;
      gap: 0.4rem;
    }

    &__section {
      display: flex;
      flex-wrap: wrap;

      &--half-width {
        width: calc(50% - ($signupStepGeneralGap / 2));
      }

      &--66-width {
        width: calc($column-aside-big - ($signupStepGeneralGap / 2));
      }

      &--33-width {
        width: calc($column-aside-small - ($signupStepGeneralGap / 2));
      }

      &--full-width {
        width: 100%;
      }

      &--center {
        flex-direction: column;
        align-items: center;
      }

      &--right {
        flex-direction: column;
        align-items: flex-end;
      }

      &--space-between {
        justify-content: space-between;
      }

      @media screen and (max-width: $breakpoint-small) {
        &--full-width-mobile {
          width: 100%;
        }

        &--hide-mobile {
          display: none;
        }

        &--width-unset-mobile {
          width: unset;
        }
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 1rem;

    @media screen and (max-width: $breakpoint-small) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap: 1.6rem;

      .nyk-signup-step__stepcounter {
        display: block;
        margin: 1rem 0;
      }
    }
  }

  &__stepcounter {
    @include bodyText("small");
    margin: 0;

    &--mobile {
      display: none;

      @media screen and (max-width: $breakpoint-small) {
        display: block;
        white-space: nowrap;
        margin: 1rem 0 0 0;
        margin-left: auto;
      }
    }

    @media screen and (max-width: $breakpoint-small) {
      display: none;
    }
  }

  &__title {
    @include heading("medium");

    @media screen and (max-width: $breakpoint-small) {
      @include heading("small");
    }
  }

  &__summary {
    @include bodyText("small");

    @media screen and (max-width: $breakpoint-small) {
      display: none;

      &--show-allways {
        display: block;
        @include bodyText("x-small");
      }
    }

    p {
      margin: 0;
    }

    &--mobile {
      display: none;

      @media screen and (max-width: $breakpoint-small) {
        display: block;
        @include bodyText("x-small");
        margin-top: 4rem;
      }
    }
  }

  &__mt-auto {
    margin-top: auto;
  }

  &__newsletters-options {
    margin-bottom: 3.2rem;

    @media screen and (max-width: $breakpoint-small) {
      margin-bottom: 2rem;
    }
  }

  &__receipt {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &__text {
      font-size: 18rem;
      color: var(--colortheme-primary-dark);

      @media screen and (max-width: $breakpoint-small) {
        font-size: 12rem;
      }
    }
  }

  &__inputs {
    display: flex;
    justify-content: space-between;
    gap: $signupStepGeneralGap;

    @media screen and (max-width: $breakpoint-small) {
      flex-direction: column;
      gap: 2.4rem;
      width: 100%;
    }

    &--stacked {
      flex-direction: column;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  &__general-input {
    position: relative;
  }

  &__input {
    @include heading("xx-large");
    line-height: 150%;
    border: none;
    border-bottom: 1px solid var(--colortheme-primary-dark-20);
    background-color: transparent;
    padding-bottom: 1.2rem;
    padding-left: 1.2rem;
    width: 100%;
    transition: font-size 0.3s ease-out;

    &::placeholder {
      @include heading("x-large");
      line-height: 150%;
    }

    @media screen and (max-width: $breakpoint-large) {
      @include heading("x-large");
      line-height: 150%;

      &::placeholder {
        @include heading("x-large");
        line-height: 150%;
      }
    }

    @media screen and (max-width: $breakpoint-small) {
      @include heading("large");
      line-height: 150%;
      padding-bottom: 1.2rem;

      &::placeholder {
        @include heading("large");
        line-height: 150%;
      }
    }

    @media screen and (max-width: $breakpoint-xx-small) {
      font-size: $fs-mobile-heading-small;
      padding-bottom: 0.4rem;
      padding-top: 1rem;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--colortheme-primary-dark);
    }

    /* Chrome, Safari, and Edge */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: var(--colortheme-primary-dark) !important;
    }
  }

  &__checkbox {
    position: relative;
    display: flex;
    align-items: flex-start;

    &__input {
      position: absolute;
      left: 0;
      top: 0;
      width: 4.4rem;
      height: 4.4rem;
      opacity: 0;
      cursor: pointer;

      &:focus-visible {
        ~.nyk-signup-step__checkbox__checkmark {
          outline: 2px solid;
          outline-color: var(--colortheme-primary-dark);
          outline-offset: 0.6rem;
        }
      }

      &:checked+.nyk-signup-step__checkbox__checkmark {
        background-color: var(--colortheme-primary-dark);
        border-color: var(--colortheme-primary-dark);

        &::after {
          display: block;
        }
      }
    }

    &__checkmark {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      border-radius: 0.4rem;
      margin: 0.4rem 1.4rem 1.4rem 1.4rem;
      background-color: transparent;
      border: 1px solid var(--colortheme-primary-dark);
      cursor: pointer;
      transition: 0.2s;

      &::after {
        content: "";
        display: none;
        border: 1.6px solid var(--colortheme-primary-dark);
        border-top: none;
        border-left: none;
        transform: rotate(45deg);
        width: 0.5rem;
        height: 0.9rem;
        margin-bottom: 0.25rem;
        border-color: var(--colortheme-neutral-7);
        transition: 0.2s;
      }
    }

    &__label {
      @include bodyText("large");
      cursor: pointer;
      hyphens: auto;
      padding-right: 1rem
    }

    .nyk-tooltip__container {
      margin-left: auto;
      margin-top: 0.4rem;
    }
  }

  &__radio {
    position: relative;
    display: flex;
    align-items: flex-start;

    &__input {
      position: absolute;
      left: 0;
      top: 0;
      width: 4.4rem;
      height: 4.4rem;
      opacity: 0;
      cursor: pointer;

      &:checked+.nyk-signup-step__radio__checkmark {
        border-color: var(--colortheme-delta);

        &::after {
          display: block;
        }
      }
    }

    &__checkmark {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      border-radius: 2rem;
      margin: 0.4rem 1rem 1rem 1rem;
      background-color: transparent;
      border: 1px solid var(--colortheme-primary-dark);
      cursor: pointer;
      transition: 0.2s;

      &::after {
        content: "";
        display: none;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 1.2rem;
        background-color: var(--colortheme-delta);
        transition: 0.2s;
      }
    }

    &__label {
      @include bodyText("large");
      cursor: pointer;
      hyphens: auto;
    }

    .nyk-tooltip__container {
      margin-left: auto;
      margin-top: 0.4rem;
    }
  }

  &__input-error-msg {
    position: absolute;
    top: 100%;
    margin-top: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.2rem;
    @include bodyText("x-small");
    text-align: right;
    width: 100%;

    &:empty {
      display: none;
    }

    .nyk-icon {
      height: 1.6rem;
      width: 1.6rem;
    }
  }
}