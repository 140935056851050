// heading font size - desktop
$fs-heading-xxx-large: 8.8rem; // 88px
$fs-heading-xx-large: 7.6rem; // 76px
$fs-heading-x-large: 5.6rem; // 56px
$fs-heading-large: 4.4rem; // 44px
$fs-heading-medium: 3.2rem; // 32px
$fs-heading-small: 2.4rem; // 24px
$fs-heading-x-small: 1.8rem; // 18px

// heading font size - mobile
$fs-mobile-heading-xxx-large: 6.8rem; // 68px
$fs-mobile-heading-xx-large: 5.6rem; // 56px
$fs-mobile-heading-x-large: 4.4rem; // 44px
$fs-mobile-heading-large: 3.6rem; // 36px
$fs-mobile-heading-medium: 2.8rem; // 28px
$fs-mobile-heading-small: 2.4rem; // 24px
$fs-mobile-heading-x-small: 1.8rem; // 18px

// body text font size - desktop
$fs-body-large: 1.8rem; // 18px
$fs-body-medium: 1.6rem; // 16px
$fs-body-small: 1.4rem; // 14px
$fs-body-x-small: 1.2rem; // 12px

// button font size - desktop
$fs-button: $fs-body-medium; // 16px

// Breakpoints
$breakpoint-large: 1440px; // Large max width
$breakpoint-medium: 1240px; // Medium max width
$breakpoint-small: 905px; // Small max width
$breakpoint-x-small: 600px; // X-Small max width
$breakpoint-xs-custom-small: 400px; // Custom small max width
$breakpoint-xx-small: 375px; // For super small phone

$custom-card-gallery-breakpoint: 1100px;

$max-width: 1280px;

$margin-x-large: 9.2rem; // Margin for: $breakpoint-large & above
$margin-large: 8rem; // Margin for: $breakpoint-large & below
$margin-medium: 2.4rem; // Margin for: $breakpoint-medium & below
$margin-x-small: 1.6rem; // Margin for: $breakpoint-x-small & below

// max content 1280px + (2 * margin-x-large),
// when content needs to be centered because it no longer is effected by margins
$breakpoint-computed-x-large: 1464px;

$column-aside-big: 66.6666666667%;
$column-aside-small: 33.333333333%;

$menuZIndex: 1000;
$menuTopLevelDropdwon: 100;
$submenudropdownOverlay: 1001;
$submenudropdown: 1002;

$chatPanelHeightBreakpoint: 670px;
// Screen size names
$computed-x-large: "computed-x-large";
$x-large: "x-large";
$large: "large";
$medium: "medium";
$small: "small";
$x-small: "x-small";
