.nyk-theme-switch {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    
    &__input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;

        &:checked + .nyk-theme-switch__slider {
            background-color: var(--colortheme-primary);

            &::before {
                left: 2.8rem;
            }
        }
    }

    &__slider {
        transition: 0.3s;
        position: relative;
        display: flex;
        height: 3rem;
        width: 5rem;
        background-color: var(--colortheme-neutral-2);
        border-radius: var(--border-radius);
        align-items: center;
        justify-content: flex-start;
        padding: 0.4rem;
        cursor: pointer;

        &::before {
            left: 0.4rem;
            position: absolute;
            content: "";
            height: 2.5rem;
            width: 2.5rem;
            background-color: var(--colortheme-neutral-7);
            border-radius: var(--border-radius);
            transition: 0.3s;
        }
    }

    &__title {
        margin-top: 0.4rem;
    }
}