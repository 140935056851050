.nyk-footer-information {
  margin-top: 6rem;
  padding: 8rem 0;
  background-color: var(--colortheme-primary);

  @media screen and (max-width: $breakpoint-small) {
    padding: 5.6rem 0;
    margin-top: 3.2rem;
  }

  * :focus-visible {
    outline-color: var(--colortheme-neutral-7);
  }

  &__main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 3.2rem;
    max-width: 126.4rem;
    margin: 0 auto;

    @media screen and (min-width: $breakpoint-large) {
      margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
      margin: 0 auto;
      max-width: $max-width;
    }

    @media screen and (max-width: $breakpoint-large) {
      margin: 0 $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
      margin: 0 $margin-medium;
      flex-direction: column;
      gap: 0.8rem;
      width: unset;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      margin: 0 $margin-x-small;
    }

    &__company-info {
      flex: 1 0 0;

      &__name {
        @include bodyText("large");
        font-weight: 700;
        color: var(--colortheme-neutral-7);
        margin-bottom: 2.4rem;
        margin-top: 0;
        @media screen and (max-width: $breakpoint-medium) {
          margin-bottom: 1.2rem;
        }
      }

      &__address {
        display: flex;
        flex-direction: column;
        font-style: normal;
        &__item {
          @include bodyText("medium");
          color: var(--colortheme-neutral-7);
        }
      }

      &__phone {
        @include heading("medium");
        font-weight: 400;
        color: var(--colortheme-neutral-7);
        text-decoration: none;
        margin-top: 1.6rem;
      }
    }

    &__group {
      flex: 1 0 0;

      @media screen and (max-width: $breakpoint-medium) {
        display: none;
      }

      &__title {
        @include bodyText("large");
        font-weight: 700;
        color: var(--colortheme-neutral-7);
        margin-bottom: 2.4rem;
        margin-top: 0;
      }

      &__list {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        @media screen and (max-width: $breakpoint-medium) {
          gap: 1.2rem;
        }
      }
    }

    &__mobile-groups {
      display: none;
      width: 100%;
      margin-top: 4rem;

      @media screen and (max-width: $breakpoint-medium) {
        display: block;
      }

      .nyk-accordion-group {
        &__content {
          max-width: unset;

          .nyk-accordion:last-child {
            border-color: var(--colortheme-neutral-7-28);
          }
        }
      }

      .nyk-accordion {
        padding-left: 0;
        width: 100%;

        &[open] {
          .nyk-accordion__title {
            font-weight: 700;
          }

          .nyk-accordion__content {
            border-color: var(--colortheme-neutral-7-28);
            padding-top: 0.4rem;

            .nyk-footer-information__main__group__list {
              margin: 0;
              padding-top: 0;
            }
          }
        }

        &__title {
          color: var(--colortheme-neutral-7);
          border-color: var(--colortheme-neutral-7-28);
          font-size: $fs-body-large;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

          &__icon {
            &__line {
              background-color: var(--colortheme-neutral-7);
              height: 0.15rem;
              width: 0.8rem;
            }
          }
        }

        &__content {
          padding: 2rem 0 4.4rem 0;

          .nyk-footer-information__main__group__list {
            gap: 1.2rem;
          }
        }
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    max-width: 126.4rem;
    margin: 0 auto;
    margin-top: 4.4rem;

    @media screen and (min-width: $breakpoint-large) {
      margin: 0 $margin-x-large;
      margin-top: 4.4rem;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
      margin: 0 auto;
      margin-top: 4.4rem;
      max-width: $max-width;
    }

    @media screen and (max-width: $breakpoint-large) {
      margin: 0 $margin-large;
      margin-top: 4.4rem;
    }

    @media screen and (max-width: $breakpoint-medium) {
      margin: 0 $margin-medium;
      margin-top: 4.8rem;
      max-width: unset;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      margin: 0 $margin-x-small;
      margin-top: 4.8rem;
    }

    &__link {
      width: 4rem;
      height: 4rem;
      border: 1px solid var(--colortheme-neutral-7);
      border-radius: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 1.6rem;
        height: 1.6rem;

        path {
          fill: var(--colortheme-neutral-7);
        }
      }
    }
  }
}
