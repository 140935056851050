.nyk-signup-step-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.6rem;
  width: 100%;
  padding-top: 4rem;
  margin-top: auto;

  &--no-space-top {
    padding-top: 0;
    gap: 0;

    @media screen and (max-width: $breakpoint-small) {
      padding-top: 4rem;
    }

    .nyk-signup-step-actions__stepcounter {
      margin-top: 1.2rem;
    }
  }

  &__stepcounter {
    width: 100%;
    margin-bottom: 1.6rem;
  }

  &__action {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &--end {
      margin-left: auto;
    }

    &__text {
      font-weight: 500;
    }

    &__button.nyk-button {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }
  }
}
