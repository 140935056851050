// This file is used to override the default styles of legacy stylesheets.

.mdc-typography--subtitle.mdc-typography--subtitle {
  font-size: 1.8rem;
  line-height: 150%;
}

.mdc-tab.mdc-tab {
  font-size: 1.4rem;
  line-height: 150%;
}

.mdc-list-item__secondary-text.mdc-list-item__secondary-text {
  font-size: 1.4rem;
  line-height: 150%;
}

.mdc-typography--body-lg-alt.mdc-typography--body-lg-alt {
  font-size: 1.8rem;
  line-height: 150%;
}

.mdc-menu .mdc-list-item.mdc-list-item {
  font-size: 1.6rem;
  line-height: 150%;
}

.npa-association__download-button-container {
  margin-bottom: 1.6rem;
}

.mdc-form-field.mdc-form-field {
  font-size: 1.4rem;
  line-height: 150%;
}

.mdc-select__native-control.mdc-select__native-control {
  font-size: 1.6rem;
  line-height: 150%;
}

.varme-beregner-select__control.varme-beregner-select__control {
  font-size: 1.6rem;
  line-height: 4.4rem;
}

.mdc-floating-label.mdc-floating-label {
  font-size: 1.6rem;
  line-height: 150%;
}

.mdc-form-field.mdc-form-field .mdc-text-field--outlined.mdc-text-field--outlined input {
  font-size: 1.6rem;
  line-height: 150%;
}

.nk-modal .tk-richtext.tk-richtext {
  margin: 0;
}

.mdc-select__native-control.mdc-select__native-control {
  font-size: 1.6rem;
  line-height: 150%;
}

.mdc-typography--body-xs.mdc-typography--body-xs {
  font-size: 1.4rem;
  line-height: 150%;
}

.mdc-typography--body.mdc-typography--body {
  font-size: 1.6rem;
  line-height: 150%;
}

.mdc-typography--body-alt.mdc-typography--body-alt {
  font-size: 1.6rem;
  line-height: 150%;
}

.table.table th {
  font-size: 1.2rem;
  line-height: 150%;
}

.table.table tbody td {
  font-size: 1.4rem;
  line-height: 150%;
}

.search-autocomplete-input {
  font-size: 1.6rem;
  line-height: 150%;
}

.mdc-typography--title-xs {
  font-weight: 500;
}

.custom-form .EPiServerForms .FormChoice,
.custom-form .custom-form-container .FormChoice,
.mdc-epi-form .EPiServerForms .FormChoice,
.mdc-epi-form .custom-form-container .FormChoice {
  margin-top: 0;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  background: #0f1e82 !important;
}

.embeddedServiceIcon {
  color: #fff;
}

.embeddedServiceHelpButton .uiButton .helpButtonLabel .message {
  color: #fff;
}
