.ValidationFail > .nyk-form,
.nyk-form.ValidationFail {
    .nyk-form__content {
        border-color: var(--colortheme-accent-primary);

        &:focus-within {
            border-color: var(--colortheme-accent-primary);
            box-shadow: 0 0 0 1px var(--colortheme-accent-primary);
        }
    }

    .nyk-form__file {
        border-color: var(--colortheme-accent-primary);

        &:focus-within {
            border-color: var(--colortheme-accent-primary);
            box-shadow: 0 0 0 1px var(--colortheme-accent-primary);
        }

        &__reset-icon {
            right: 4rem;
        }

        &__input {
            padding-right: 4.8rem;
            width: calc(100% - 6.4rem);
        }

        &__error-icon {
            display: block;
            pointer-events: none;
        }
    }

    .nyk-form--select__content {
        border-color: var(--colortheme-accent-primary);

        &::after {
            right: 5.6rem;
        }

        &__select {
            padding-right: 4.8rem;
            width: calc(100% - 6.4rem);

            &:focus {
                outline-color: var(--colortheme-accent-primary);
            }
        }

        &__error-icon {
            display: block;
        }
    }

    .nyk-form-choice__content__item__choice__input {
        &:hover {
            & + .nyk-form-choice__content__item__choice__circle {
                .nyk-form-choice__content__item__choice__circle__outer {
                    outline: 1px solid var(--colortheme-accent-primary);
                }

                .nyk-form-choice__content__item__choice__circle__inner {
                    width: 1rem;
                    height: 1rem;
                }
            }

            & + .nyk-form-choice__content__item__choice__square {
                .nyk-form-choice__content__item__choice__square__outer {
                    outline-color: var(--colortheme-accent-primary);
                }

                .nyk-form-choice__content__item__choice__square__inner {
                    border-color: var(--colortheme-accent-primary);
                    border-bottom: 1.5px solid var(--colortheme-accent-primary);
                    border-right: 1.5px solid var(--colortheme-accent-primary);
                    transform: rotate(45deg);

                    &::before {
                        content: unset;
                    }
                }
            }
        }

        &:checked {
            & + .nyk-form-choice__content__item__choice__circle {
                .nyk-form-choice__content__item__choice__circle__outer {
                    border-color: var(--colortheme-accent-primary);
                }

                .nyk-form-choice__content__item__choice__circle__inner {
                    width: 1.2rem;
                    height: 1.2rem;
                    background-color: var(--colortheme-accent-primary);
                }
            }

            & + .nyk-form-choice__content__item__choice__square {
                .nyk-form-choice__content__item__choice__square__outer {
                    border-color: var(--colortheme-accent-primary);
                    background-color: var(--colortheme-accent-primary);
                }

                .nyk-form-choice__content__item__choice__square__inner {
                    width: 0.5rem;
                    height: 1rem;
                    transform: rotate(45deg);
                    border-bottom: 1.5px solid var(--colortheme-neutral-7);
                    border-right: 1.5px solid var(--colortheme-neutral-7);
                }
            }

            &:hover {
                & + .nyk-form-choice__content__item__choice__circle {
                    .nyk-form-choice__content__item__choice__circle__outer {
                        outline: 1px solid var(--colortheme-accent-primary);
                    }

                    .nyk-form-choice__content__item__choice__circle__inner {
                        width: 1rem;
                        height: 1rem;
                    }
                }

                & + .nyk-form-choice__content__item__choice__square {
                    .nyk-form-choice__content__item__choice__square__outer {
                        border-color: var(--colortheme-accent-primary);
                        background-color: var(--colortheme-accent-primary);
                        outline: unset;
                    }

                    .nyk-form-choice__content__item__choice__square__inner {
                        border: none;
                        margin-bottom: 0;
                        transform: rotate(45deg);

                        &:before {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            content: "";
                            border: none;
                            height: 1.5px;
                            width: 1.2rem;
                            background-color: var(--colortheme-neutral-7);
                            transform: translate(-50%, -50%) rotate(90deg);
                        }

                        &:after {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            content: "";
                            height: 1.5px;
                            width: 1.2rem;
                            background-color: var(--colortheme-neutral-7);
                            transform: translate(-50%, -50%) rotate(180deg);
                        }
                    }
                }
            }

            &:active {
                & + .nyk-form-choice__content__item__choice__square {
                    .nyk-form-choice__content__item__choice__square__outer {
                        border-color: var(--colortheme-accent-primary);
                        background-color: var(--colortheme-accent-primary);
                        outline: unset;
                    }

                    .nyk-form-choice__content__item__choice__square__inner {
                        border: none;
                        margin-bottom: 0;

                        &:before {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            content: "";
                            height: 1.5px;
                            width: 1rem;
                            background-color: var(--colortheme-neutral-7);
                            transform: translate(-50%, -50%) rotate(90deg);
                        }

                        &:after {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            content: "";
                            height: 1.5px;
                            width: 1rem;
                            background-color: var(--colortheme-neutral-7);
                            transform: translate(-50%, -50%) rotate(180deg);
                        }
                    }
                }
            }
        }

        &:active {
            & + .nyk-form-choice__content__item__choice__circle {
                .nyk-form-choice__content__item__choice__circle__outer {
                    outline: 1px solid var(--colortheme-accent-primary);
                }

                .nyk-form-choice__content__item__choice__circle__inner {
                    width: 0.8rem;
                    height: 0.8rem;
                }
            }
        }
    }

    .nyk-form-choice__content__item__choice__square {
        &__outer {
            border-color: var(--colortheme-accent-primary);
        }

        &__inner {
            opacity: 1;
            border: none;
            transform: rotate(0deg);

            &::before {
                content: "!";
                position: absolute;
                top: 0;
                transform: translate(0, -45%);
                @include bodyText("small");
                color: var(--colortheme-accent-primary);
            }
        }
    }

    .nyk-form-choice__content__item__choice__circle {
        &__outer {
            border-color: var(--colortheme-accent-primary);
        }

        &__inner {
            background-color: var(--colortheme-accent-primary);
        }
    }

    .nyk-form__help-message {
        display: none;
    }

    .nyk-form__content__error-icon {
        display: block;
    }

    .nyk-form__error-message,
    .Form__Element__ValidationError:is(span),
    .EPiServerForms.EPiServerForms .Form__Element__ValidationError:is(span) {
        display: block;
    }
}

.Form__Element__ValidationError:is(span),
.EPiServerForms.EPiServerForms .Form__Element__ValidationError:is(span) {
    display: block;
    padding: 0.8rem 1.6rem;
    @include bodyText("x-small");
}
