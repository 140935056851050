.nyk-person-card {
  &:hover,
  &:focus {
    .nyk-person-card__content__image__img {
      transform: scale(1.09);
    }
  }

  &--horizontal {
    width: $column-aside-big;
    margin-left: auto;

    @media screen and (min-width: $breakpoint-large) {
      margin: 0 $margin-x-large 0 auto;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
      margin: 0 calc((100% - $max-width) / 2) 0 auto;
      max-width: calc($max-width * 0.6666666667);
    }

    @media screen and (max-width: $breakpoint-large) {
      margin: 0 $margin-large 0 auto;
    }

    @media screen and (max-width: $breakpoint-medium) {
      margin: 0 $margin-medium 0 auto;
    }

    @media screen and (max-width: $breakpoint-small) {
      margin: 0 $margin-medium;
      width: auto;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      margin: 0 $margin-x-small;

      .nyk-person-card__content.nyk-person-card__content {
        flex-direction: column;

        &__image {
          height: 24rem;
          aspect-ratio: calc(16 / 9);
        }

        &__body {
          padding: 2.4rem;
        }
      }
    }

    .nyk-person-card__content {
      flex-direction: row;

      &__image {
        flex: 0.75;
        height: auto;
        aspect-ratio: 2;
      }

      &__body {
        flex: 1;
        padding: 2.4rem;
      }
    }
  }

  &--no-image {
    .nyk-standard-card__content__body__content__title.nyk-standard-card__content__body__content__title {
      @include heading("x-small");
      font-weight: 700;
    }

    .nyk-standard-card__content__body__content__description.nyk-standard-card__content__body__content__description {
      & * {
        @include bodyText("medium");
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: var(--border-radius);
    overflow: hidden;
    background-color: var(--colortheme-neutral-7);

    &__image {
      width: 100%;
      height: 34rem;
      overflow: hidden;

      @media screen and (max-width: $breakpoint-small) {
        height: 30rem;
      }

      &__img:is(img) {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: 0.3s;
        border-radius: 0;
      }
    }

    &__body {
      padding: 2.4rem 2.4rem 1.6rem 2.4rem;
      display: flex;
      flex-direction: column;
      gap: 2.4rem;

      &__header {
        display: flex;
        flex-direction: column;

        &__title {
          @include heading("small");
          word-break: break-word;
        }

        &__job-title {
          @include heading("small");
          word-break: break-word;
        }
      }

      &__info {
        padding: 0;
        margin: 0;
        list-style: none;
        @include bodyText("medium");

        &__item {
          display: flex;
          align-items: center;
          gap: 1.6rem;

          &__label {
            min-width: 6rem;
          }

          &__value {
            word-break: break-word;
            text-decoration: none;
            color: var(--colortheme-primary-dark);
          }
        }
      }

      &__social {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1.2rem;

        &__item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 4.4rem;
          height: 4.4rem;
          border: 1px solid var(--colortheme-primary-dark);
          border-radius: 4.4rem;

          svg,
          svg path {
            height: 2rem;
            width: auto;
          }
        }
      }
    }

    &__bottom { 
      margin-top: auto;
      padding: 0 2.4rem 2.4rem 2.4rem;

      &__description {
        @include bodyText("medium");
      }

      &__actions {
        padding-top: 1.6rem;

        &__link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;

          &__name {
            @include bodyText("medium");
            font-weight: 500;
          }
        }
      }
    }
  }
}

.nyk-person-card-layout {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  padding: 6rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &__header {
    &__title {
      @include heading("medium");
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.6rem;
    row-gap: 3.2rem;

    @media screen and (max-width: $breakpoint-medium) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: $breakpoint-small) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $breakpoint-x-small) {
      grid-template-columns: repeat(1, 1fr);
    }

    &--4-cols {
      grid-template-columns: repeat(4, 1fr);

      @media screen and (max-width: $breakpoint-medium) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: $breakpoint-small) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: $breakpoint-x-small) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
  }
}
