.nyk-accordion-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 6rem;
  max-width: $max-width;
  margin: 0 auto;
  padding: 6rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    flex-direction: column;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  & + .nyk-accordion-container {
    padding-top: 2rem;

    @media screen and (max-width: $breakpoint-small) {
      padding-top: 0.4rem;
    }
  }

  &__header {
    width: 100%;
    @include heading("x-large", $breakpoint-small);
    font-weight: 400;
    margin-bottom: 8rem;

    @media screen and (max-width: $breakpoint-small) {
      margin-bottom: 6.4rem;
    }

    & + .nyk-accordion-container__title {
      margin-top: 0;
    }
  }

  &__title {
    width: calc(33% - 6rem);
    padding: 2rem 0 0 0;
    
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    @include heading("medium");

    @media screen and (max-width: $breakpoint-small) {
      margin: 0 0 3.2rem 0;
      padding: 0;
      width: unset;
    }

    & + .nyk-accordion {
      width: $column-aside-big;
      padding-left: 0;

      @media screen and (max-width: $breakpoint-small) {
        flex: unset;
        width: 100%;
      }
    }
  }
}

.nyk-accordion {
  width: 100%;
  padding-left: $column-aside-small;
  margin-left: auto;

  @media screen and (max-width: $breakpoint-small) {
    width: 100%;
    padding-left: 0;
    margin-left: 0;

    & + .nyk-accordion-container__title {
      margin-top: 6.4rem;
    }
  }

  &:first-of-type {
    width: $column-aside-big;
    padding-left: 0;
    border-top: 1px solid var(--colortheme-primary-dark-20);

    @media screen and (max-width: $breakpoint-small) {
      flex: unset;
      width: 100%;
    }
  }

  &[open] {
    .nyk-accordion__title {
      border-bottom: 0;

      &__icon {
        &__line {
          &:first-child {
            transform: translate(0, -50%) rotate(180deg);
          }

          &:last-child {
            transform: translate(0, -50%) rotate(180deg);
          }
        }
      }
    }

    .nyk-accordion__content {
      border-bottom: 1px solid var(--colortheme-primary-dark-20);
    }
  }

  &__title {
    position: relative;
    @include heading("small");
    padding: 2rem 7.6rem 2rem 0;
    border-bottom: 1px solid var(--colortheme-primary-dark-20);
    list-style: none;
    cursor: pointer;

    @media screen and (max-width: $breakpoint-small) {
      padding-right: 8.8rem;
    }

    &::-webkit-details-marker {
      display: none;
    }

    > * {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      color: inherit;
      margin: 0;
    }

    &__icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);

      &__line {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%) rotate(90deg);
        width: 1.6rem;
        height: 0.25rem;
        background-color: var(--colortheme-primary-dark);
        transition: 0.2s ease-in-out;

        @media screen and (max-width: $breakpoint-small) {
          width: 1.4rem;
          height: 0.15rem;
        }

        &:last-child {
          transform: translate(0, -50%) rotate(0deg);
        }
      }
    }
  }

  &__content {
    padding: 4.4rem 0 2.4rem 0;
    margin: 0;

    &.nyk-richtext {
      margin: 0;

      & > * {
        margin-left: unset;
        width: 100%;
      }
    }

    .nyk-richtext {
      margin: 0;

      & > * {
        margin-left: unset;
        width: 100%;
      }
    }

    @media screen and (max-width: $breakpoint-small) {
      padding: 2rem 0 6rem 0;
    }
  }
}


@import "./Accordion.contentarea-overrides.scss"