.nyk-signup-step-receipt {
  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    width: 4rem;
    border-radius: 4rem;
    background-color: var(--colortheme-neutral-7);
    border: none;
    cursor: pointer;

    .nyk-icon {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
}
