.nyk-search-input-field {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.8rem;
  width: 100%;
  height: 5.6rem;
  margin-bottom: 6rem;

  &__icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 2.4rem;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 10;

    svg {
      height: 2rem;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5.6rem;
    border: 1px solid var(--colortheme-primary-dark);
    border-radius: 5rem;
    background-color: transparent;
    padding: 0 6rem;
    @include bodyText("large");

    &:hover {
      box-shadow: inset 0 0 0 1px var(--colortheme-primary-dark);
    }

    &:focus {
      border-color: var(--colortheme-delta);
      box-shadow: inset 0 0 0 1px var(--colortheme-delta);
      outline: none;
    }
  }

  &__close {
    position: absolute;
    top: 50%;
    right: 2.4rem;
    transform: translate(0, -50%);
    display: none;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 10;

    &--show {
      display: block;
    }

    .nyk-icon {
      width: 2rem;
    }
  }
}
