.nyk-page-mit-nykredit {
  .nyk-card-medium {
    height: 34rem;

    @media screen and (max-width: $breakpoint-small) {
      height: unset;
    }

    &__content {
      margin-bottom: 3.6rem;

      & > p {
        margin: 0;
      }
    }

    &__footer {
      margin-top: 0;
    }
  }

  .nyk-accordion-container {
    padding-top: 0;

    @media screen and (max-width: $breakpoint-small) {
      padding: 3.2rem;
    }
  }

  .nyk-signature-card {
    padding-bottom: 14rem;

    @media screen and (max-width: $breakpoint-small) {
      padding-bottom: 6.4rem;
    }
  }
}
