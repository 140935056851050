.nyk-calculator-compare-table {
    flex: 1;

    &__group {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 6.4rem;

        @media screen and (max-width: $custom-card-gallery-breakpoint) {
            order: 3;
        }

        @media screen and (max-width: $breakpoint-small) {
            gap: $margin-x-small;
        }

        @media screen and (max-width: 350px) { //custom breakpoint
            grid-template-columns: 1fr;
            gap: 3.2rem;
        }

        &--last {

            & > div {
                grid-column: 2 / 3;
            }
        }

        &-title {
            @include heading('medium');
            word-break: break-word;

            @media screen and (max-width: $breakpoint-small) {
                @include heading('small');
            }

            @media screen and (max-width: $breakpoint-xx-small) {
                font-size: 2rem;
            }

            @media screen and (max-width: 350px) {
                display: none;
            }

            &:not(:first-child) {
                margin-top: 12rem;

                @media screen and (max-width: $breakpoint-small) {
                    margin-top: 6.4rem;
                }
            }

            &--show-mobile {
                display: none;

                @media screen and (max-width: 350px) {
                    display: block;
                }
            }
        }
    }

    &.nyk-table {
        padding: 0;
        margin: $margin-x-small 0;

        &__container {
            display: flex;
        }
    }

    .nyk-calculator-table.nyk-table {
        margin-bottom: 6rem;

        @media screen and (max-width: $breakpoint-small) {
            margin-bottom: 3.2rem;
        }

    }

}