.nyk-richtext {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  & > * {
    margin-left: auto !important;
    width: $column-aside-big;

    @media screen and (max-width: $breakpoint-small) {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    @include heading("xx-large", $breakpoint-small);
    margin: 0 0 1.6rem 0;
    width: 100%;
  }

  h2 {
    @include heading("large", $breakpoint-small);
    margin: 0 0 1.6rem 0;
  }

  h3 {
    @include heading("medium", $breakpoint-small);
    font-weight: 400;
    margin: 0 0 1.6rem 0;

    @media screen and (max-width: $breakpoint-small) {
      @include heading("small", $breakpoint-small);
    }
  }

  h4 {
    @include bodyText("large", $breakpoint-small);
    font-weight: 600;
    margin: 0;

    @media screen and (max-width: $breakpoint-small) {
      @include heading("small", $breakpoint-small);
    }
  }

  p,
  strong,
  b,
  em,
  i {
    @include bodyText("large", $breakpoint-small);
    margin: 0 0 3.2rem 0;

    @media screen and (max-width: $breakpoint-small) {
      margin-bottom: 2.4rem;
    }
  }

  small {
    margin: 0 0 1.6rem 0;
  }

  a {
    @include bodyText("large", $breakpoint-small);
    color: var(--colortheme-primary-dark);
  }

  ul {
    font-size: $fs-body-medium;
    line-height: $fs-body-medium;
    color: var(--colortheme-primary-dark);
    font-weight: 500;
    list-style-type: none !important;
    padding-left: 0;
    margin: 0 0 1.6rem 0;
  }

  li {
    position: relative;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    margin: 0;
    font-size: $fs-body-large !important;
    line-height: 2.8rem;
  }

  li::marker {
    content: "";
  }

  li:before {
    position: absolute;
    top: 1.1rem;
    left: 0;
    content: "";
    display: inline-block;
    margin-bottom: 2.5px;
    width: 6px;
    height: 6px;
    background-color: var(--colortheme-accent-primary);
    border-radius: 1px;
  }

  table {
    width: calc(100% - 1.6rem) !important;
    transform: scale(1) !important;
    border: none !important;
    height: unset !important;
    margin: 0 0.8rem !important;
    border-collapse: collapse !important;

    thead {
      border-bottom: 1px solid var(--colortheme-primary-dark-40) !important;
      border-collapse: collapse !important;
      height: unset !important;

      tr {
        height: unset !important;

        th,
        td {
          text-align: left;
          font-size: $fs-body-large !important;
          line-height: $fs-body-large * 1.5 !important;
          color: var(--colortheme-primary-dark) !important;
          font-weight: 700 !important;
          padding: 1.6rem 0 !important;
          vertical-align: top !important;
          border: none !important;
          height: unset !important;
          padding-right: 3.2rem !important;

          &:last-child {
            padding-right: 0 !important;
          }

          @media screen and (max-width: $breakpoint-medium) {
            font-size: $fs-body-large;
          }
        }
      }
    }

    tbody {
      border: none !important;
      border-collapse: collapse !important;
      height: unset !important;

      tr {
        height: unset !important;
        border: none !important;
        border-bottom: 1px dashed var(--colortheme-primary-dark-20) !important;
        transition: 0.2s;

        &:hover {
          background-color: var(--colortheme-neutral-2);
        }

        th,
        td {
          text-align: left;
          font-size: $fs-body-large !important;
          line-height: $fs-body-large * 1.5 !important;
          color: var(--colortheme-primary-dark) !important;
          font-weight: 400 !important;
          padding: 1.6rem 0 !important;
          border: none !important;
          height: unset !important;
          vertical-align: top !important;
          padding-right: 3.2rem !important;

          &:last-child {
            padding-right: 0 !important;
          }

          @media screen and (max-width: $breakpoint-medium) {
            font-size: $fs-body-large;
          }
        }
      }
    }
  }

  .disclosure.disclosure {
    opacity: 1;
    @include bodyText("x-small");
  }
}
