.nyk-switch-loan-type-content {
    margin: 1.2rem 0 2.8rem 0;

    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: $margin-x-small;
        list-style: none;
        padding: 0;

        @media screen and (max-width: $breakpoint-medium) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: $breakpoint-small) {
            grid-template-columns: repeat(1, 1fr);
        }

        &-item {
            padding: $margin-medium;
            background-color: var(--colortheme-neutral-7);
            border-radius: $margin-x-small;
            display: flex;
            flex-direction: column;
            gap: $margin-medium;

            &-title {
                @include heading('x-small');
                font-weight: 700;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: $margin-medium;

                @media screen and (max-width: $breakpoint-small) {
                    flex-direction: row;
                }
            }

            &-pill {
                padding: 0.4rem 1.2rem;
                border-radius: 0.6rem;
                @include bodyText('small');
                font-weight: 400;
                background-color: var(--colortheme-beta);
                width: fit-content;
                display: flex;
            }

            &-description {
                @include bodyText('medium');
                margin: 0;
            }

            &-bullets {
                margin-top: $margin-medium;
                padding: 0;
                list-style-type: square;

                @media screen and (max-width: $breakpoint-small) {
                    margin-top: 1.2rem;
                }

                &-item {
                    position: relative;
                    padding-bottom: $margin-x-small;
                    padding-left: $margin-x-small;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &::marker {
                        content: '';
                    }

                    &:before {
                        position: absolute;
                        top: 0.9rem;
                        left: 0;
                        content: '';
                        display: inline-block;
                        margin-bottom: 2.5px;
                        width: 6px;
                        height: 6px;
                        background-color: var(--colortheme-accent-primary);
                        border-radius: 1px;
                    }
                }
            }

            &-action {
                margin-top: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-decoration: none;
                @include bodyText('medium');
                font-weight: 500;
                border: none;
                padding: 0;
                background-color: transparent;
                text-align: left;
                cursor: pointer;
            }
        }
    }
}
