.nyk-chat-connection-error {
    background-color: white;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &__content {
        margin: auto;
        display: flex;
        flex: 1;
        justify-content: center;
        flex-direction: column;
        gap: 2.4rem;
        background: white;
        border-radius: 1.6rem;
        display: flex;
        padding: 6.8rem 3.4rem;
        border-radius: 1.6rem;
        margin: 0 2.4rem 2.4rem 2.4rem;
        @media screen and (min-width: $breakpoint-small) and (min-height: $chatPanelHeightBreakpoint) {
            background-color: #EDE8E6;
        }
        > svg {
            flex-shrink: 0;
            width: 4.5rem;
            height: 3.8rem;
        }
    }

    &__description {
        max-width: 25rem;
        font-size: 3.2rem;
        line-height: 38.4px;
    }
}