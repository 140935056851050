.nyk-send-calculation {
    z-index: $submenudropdown;
    padding: 2.4rem 2rem;
    background-color: var(--colortheme-gamma);
    border-radius: var(--border-radius);
    margin: 0 auto 1.8rem auto;
    max-width: $max-width;

    &--multi-buttons {
        .nyk-send-calculation__content {
            gap: 2.4rem;
        }
    }

    @media screen and (min-width: $breakpoint-large) {
        padding: 2.4rem 2rem;
    }

    @media screen and (max-width: $breakpoint-large) {
        padding: 2.4rem 2rem;
    }

    @media screen and (max-width: $breakpoint-medium) {
        padding: 2.4rem $margin-medium;
    }

    @media screen and (max-width: $breakpoint-x-small) {
        padding: 2.4rem $margin-x-small;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        row-gap: 2rem;
        column-gap: 4rem;
        flex-wrap: wrap;
        margin: 0 auto;
        width: 100%;
        color: var(--colortheme-primary-dark);

        &__info {
            display: flex;
            align-items: center;

            &__description {
                @include bodyText('large');
                margin: 0;

                &__label {
                    @include bodyText('large');
                    font-size: 3rem;
                    font-weight: 400;
                    padding-right: 0.5rem;
                }

                &__text {
                    font-weight: 400;
                    font-size: 1.5rem;
                    line-height: 2.4rem;
                }
            }

            &__date {
                @include bodyText('large');
                margin: 0;
            }
        }

        &__actions {
            display: flex;
            align-items: center;
            gap: 1.2rem;
        }

        &__link {
            margin-left: auto;
            font-weight: 500;
            background-color: #07094a;
            border-color: #07094a;
        }
    }
}
