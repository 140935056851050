.nyk-send-calculation-step-content {
    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }

    .nyk-signup-step__content__section--66-width {
        display: flex;
        flex-direction: column;

        .nyk-contact-step-onboarding-content__inputs {
            margin-top: auto;
        }
    }
}

.nyk-contact-step-content {
    &__is-customer {
        margin-bottom: 4rem;
        position: relative;

        &__content {
            display: flex;
            align-items: center;
            gap: 4rem;
            padding: 2.4rem 1.2rem;
            border-radius: 0.8rem;
            background-color: var(--colortheme-neutral-7-20);

            @media screen and (max-width: $breakpoint-small) {
                flex-direction: column;
                align-items: flex-start;
                gap: 1.2rem;
                padding: 0;
                background-color: transparent;
                margin-bottom: 1.2rem;
            }

            &__question {
                flex: 1;
                @include heading('medium');
            }

            &__inputs {
                flex: 1;
                display: flex;
                align-items: center;
                gap: 2rem;
            }
        }
    }

    .nyk-signup-step-receipt__close {
        margin-left: auto;
        margin-bottom: 2.8rem;
    }

    .nyk-signup-step-actions {
        .nyk-signup-step__stepcounter {
            display: none;
        }
    }
}
