.nyk-standard-card {
  display: block;
  text-decoration: none;

  &:hover,
  &:focus {
    .nyk-standard-card__content__image__img {
      transform: scale(1.09);
    }
  }

  &--no-image {
    .nyk-standard-card__content__body__content__title.nyk-standard-card__content__body__content__title {
      @include heading("x-small");
      font-weight: 700;
    }

    .nyk-standard-card__content__body__content__description.nyk-standard-card__content__body__content__description {
      & * {
        @include bodyText("medium");
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: var(--border-radius);
    overflow: hidden;
    background-color: var(--colortheme-neutral-7);

    &__image {
      width: 100%;
      height: 24rem;
      overflow: hidden;

      &__img:is(img) {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: 0.3s;
        border-radius: 0;
      }
    }

    &__body {
      padding: 2.4rem 2.4rem 1.6rem 2.4rem;
      flex: 1;
      display: flex;
      flex-direction: column;

      &__meta {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 2.4rem;

        &__info {
          display: flex;
          align-items: center;
          gap: 1.2rem;

          &__tag {
            @include bodyText("small");
            padding: 0.4rem 1.2rem;
            border-radius: 0.6rem;
            background-color: var(--colortheme-beta);
          }

          &__name {
            @include bodyText("small");
            color: var(--colortheme-primary-dark-60);
          }
        }

        &__read-time {
          @include bodyText("small");
          color: var(--colortheme-primary-dark-60);
        }
      }

      &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 2.4rem;

        &__title {
          @include heading("medium");
          word-break: break-word;
        }

        &__description {
          margin-top: auto;

          *:last-child {
            margin-bottom: 0;
          }

          &.nyk-richtext {
            margin: 0;

            & > * {
              margin-left: unset;
              width: 100%;
            }

            * {
              white-space: unset;
              @include bodyText("large");

              @media screen and (max-width: $breakpoint-medium) {
                @include bodyText("medium");
              }
            }
          }
        }
      }
    }

    &__footer {
      margin-top: auto;

      &__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        @include bodyText("medium");
        font-weight: 500;
        padding: 1.6rem 2.4rem 2.4rem 2.4rem;
      }
    }
  }
}

.nyk-standard-card-layout {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  padding: 6rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &__header {
    &__title {
      @include heading("medium");
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.6rem;
    row-gap: 3.2rem;

    @media screen and (max-width: $breakpoint-medium) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: $breakpoint-small) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $breakpoint-x-small) {
      grid-template-columns: repeat(1, 1fr);
    }

    &--4-cols {
      grid-template-columns: repeat(4, 1fr);

      @media screen and (max-width: $breakpoint-medium) {
        grid-template-columns: repeat(3, 1fr);

        .nyk-standard-card__content__body__content__title.nyk-standard-card__body__content__title {
          @include heading("medium");
        }
      }

      @media screen and (max-width: $breakpoint-small) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: $breakpoint-x-small) {
        grid-template-columns: repeat(1, 1fr);
      }

      .nyk-standard-card__content__body__content__title {
        @include heading("small");
      }

      .nyk-standard-card__content__body__content__description {
        & * {
          @include bodyText("medium");
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
  }
}
