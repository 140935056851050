.nyk-adjust-loan-content {
    &__inputs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $margin-medium;

        @media screen and (max-width: $breakpoint-small) {
            grid-template-columns: repeat(1, 1fr);
            gap: $margin-x-small;
        }

        &-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 9rem;

            @media screen and (max-width: $breakpoint-small) {
                grid-template-columns: repeat(1, 1fr);
                gap: 5.4rem;
            }
        }

        &-group {
            margin: 0;
            padding: 0;
            border: none;

            &-title {
                @include heading('x-small');
                font-weight: 700;
                margin-bottom: $margin-x-small;

                @media screen and (max-width: $breakpoint-small) {
                    margin-bottom: 0.8rem;
                }
            }

            .nyk-form-range {
                margin-top: 1.6rem;

                @media screen and (max-width: $breakpoint-small) {
                    &:nth-child(3) {
                        margin-top: 1.8rem;
                    }
                }
            }
        }
    }

    &__action {
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width: $breakpoint-small) {
            margin-top: 5.4rem;
            justify-content: flex-start;
        }
    }
}
