.nyk-info-collection {
    padding: 6rem 0;

    @media screen and (min-width: $breakpoint-large) {
        margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
        margin: 0 auto;
        max-width: $max-width;
    }

    @media screen and (max-width: $breakpoint-large) {
        margin: 0 $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
        margin: 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
        padding: 3.2rem 0;
    }

    @media screen and (max-width: $breakpoint-x-small) {
        margin: 0 $margin-x-small;
    }

    &--3-cols {
        .nyk-info-collection__list {
            grid-template-columns: repeat(3, 1fr);

            @media screen and (max-width: $breakpoint-small) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width: $breakpoint-x-small) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }

    &--2-cols {
        .nyk-info-collection__list {
            grid-template-columns: repeat(2, 1fr);

            @media screen and (max-width: $breakpoint-small) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }

    &--links {
        .nyk-info-collection__list__item__header__arrow {
            display: none;
        }

        .nyk-info-collection__list__item {
            padding-bottom: 6rem;

            @media screen and (max-width: $breakpoint-x-small) {
                padding-bottom: 4.4rem;
            }
        }

        .nyk-info-collection__list__item__link-list {
            display: flex;
            margin-top: 0;

            @media screen and (max-width: $breakpoint-x-small) {
                padding-left: 5.6rem;
            }
        }
    }

    &__header {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        margin-bottom: 6rem;
        gap: 6rem;

        @media screen and (max-width: $breakpoint-small) {
            flex-direction: column;
            gap: 3.2rem;
        }

        @media screen and (max-width: $breakpoint-x-small) {
            margin-bottom: 4.4rem;
        }

        &__title {
            @include heading("large");
            word-wrap: break-word;

            @media screen and (max-width: $breakpoint-small) {
                width: 100%;
            }
        }

        &__link {
            white-space: nowrap;
            margin-left: auto;

            @media screen and (max-width: $breakpoint-small) {
                white-space: unset;
            }
        }
    }

    &__list {
        list-style: none;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 1.6rem;
        row-gap: 6rem;

        @media screen and (max-width: $breakpoint-medium) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (max-width: $breakpoint-small) {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 4.4rem;
        }

        @media screen and (max-width: $breakpoint-x-small) {
            grid-template-columns: repeat(1, 1fr);
            row-gap: 0;

            & > li:last-child {
                padding: 2.4rem 0.8rem;
                margin-bottom: 2rem;
                border-bottom: 1px solid var(--colortheme-primary-dark-20);
            }
        }

        &__item {
            display: flex;
            flex-direction: column;
            padding: 2.8rem 0.8rem 1rem 0.8rem;
            border-top: 1px solid var(--colortheme-primary-dark-20);

            @media screen and (max-width: $breakpoint-x-small) {
                padding: 2.4rem 0.8rem;
            }

            & > a {
                text-decoration: none;
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            &--no-icon {
                padding-top: 2.8rem;

                .nyk-info-collection__header {
                    margin-bottom: 0;
                    flex-direction: row;
                    justify-content: flex-start;
                    padding-left: 0;

                    @media screen and (max-width: $breakpoint-x-small) {
                        align-items: flex-start;
                        padding-left: 5.6rem;
                    }
                }
            }

            &__header {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 2.4rem;

                @media screen and (max-width: $breakpoint-x-small) {
                    flex-direction: row;
                    align-items: center;
                    gap: 1.6rem;
                }

                &__icon {
                    height: 4rem;

                    svg,
                    img {
                        height: 4rem;
                        border-radius: 0;
                    }
                }

                &__title {
                    @include heading("x-small");
                    font-weight: 700;

                    @media screen and (max-width: $breakpoint-small) {
                        font-size: $fs-body-medium;
                    }
                }

                &__arrow {
                    margin-left: auto;
                    display: none;

                    @media screen and (max-width: $breakpoint-x-small) {
                        display: block;
                    }

                    svg {
                        width: 1.4rem;
                    }
                }
            }

            &__description {
                @include bodyText("large");
                margin: 0.8rem 0 1rem 0;

                @media screen and (max-width: $breakpoint-small) {
                    font-size: $fs-body-medium;
                }

                @media screen and (max-width: $breakpoint-x-small) {
                    display: none;

                    &.show-on-mobile {
                        display: block;
                    }
                }
            }

            &__link-list {
                margin-top: auto;
                list-style: none;
                padding: 3.4rem 0 0 0;
                display: flex;
                flex-direction: column;
                gap: 2.8rem;

                @media screen and (max-width: $breakpoint-small) {
                    padding: 4.4rem 0 0 0;
                }

                @media screen and (max-width: $breakpoint-x-small) {
                    display: none;
                    padding: 2.4rem 0 0 0;
                }

                &__item {
                    font-weight: 500;
                }
            }
        }
    }
}
