$arrow-init-pos-right: 1.2rem;

button.nyk-button,
a.nyk-button {
    font-family: var(--font-family);
    font-weight: 500;
}

.nyk-button {
    @include button();

    @media screen and (max-width: $breakpoint-small) {
        transition: none;

        * {
            transition: none;
        }
    }

    &:hover {
        background-color: var(--colortheme-neutral-2);
        border-color: var(--colortheme-neutral-2);

        .nyk-button__icon {
            right: $arrow-init-pos-right;
        }
    }

    &:focus {
        background-color: var(--colortheme-neutral-2);
        border-color: var(--colortheme-neutral-2);

        .nyk-button__icon {
            right: $arrow-init-pos-right;
        }
    }

    &:active {
        background-color: var(--colortheme-neutral-3);
        border-color: var(--colortheme-neutral-3);

        .nyk-button__icon {
            right: $arrow-init-pos-right;
        }
    }

    &:disabled {
        background-color: var(--colortheme-neutral-3);
        border-color: var(--colortheme-neutral-3);
        color: var(--colortheme-neutral-5);
        cursor: not-allowed;

        .nyk-button__icon {
            path {
                fill: var(--colortheme-neutral-5);
            }
        }

        &:hover {
            background-color: var(--colortheme-neutral-3);
            color: var(--colortheme-neutral-5);

            .nyk-button__icon {
                right: 1.6rem;
            }
        }
    }

    &__icon {
        position: absolute;
        right: 1.6rem; // 16px
        top: 50%;
        display: flex;
        transform: translate(0, -50%);
        width: 1.4rem; // 14px
        height: 1.2rem; // 12px
        transition: 0.3s;

        path {
            fill: var(--colortheme-primary-dark);
        }
    }

    &--filled-primary {
        background-color: var(--colortheme-primary);
        border-color: var(--colortheme-primary);
        color: var(--colortheme-neutral-7);

        .nyk-button__icon {
            path {
                fill: var(--colortheme-neutral-7);
            }
        }

        &:hover {
            background-color: var(--colortheme-primary-dark);
            border-color: var(--colortheme-primary-dark);
        }

        &:focus {
            background-color: var(--colortheme-primary-dark);
            border-color: var(--colortheme-primary-dark);
        }

        &:active {
            background-color: var(--colortheme-primary-darker);
            border-color: var(--colortheme-primary-darker);
        }

        &:disabled {
            background-color: var(--colortheme-neutral-3);
            border-color: var(--colortheme-neutral-3);
            color: var(--colortheme-neutral-5);
            cursor: not-allowed;

            &:hover {
                background-color: var(--colortheme-neutral-3);
                border-color: var(--colortheme-neutral-3);
                color: var(--colortheme-neutral-5);
            }
        }
    }

    &--filled-primary-dark {
        background-color: var(--colortheme-primary-dark);
        border-color: var(--colortheme-primary-dark);
        color: var(--colortheme-neutral-7);

        .nyk-button__icon {
            path {
                fill: var(--colortheme-neutral-7);
            }
        }

        &:hover {
            background-color: var(--colortheme-primary-dark);
            border-color: var(--colortheme-primary-dark);
        }

        &:focus {
            background-color: var(--colortheme-primary-dark);
            border-color: var(--colortheme-primary-dark);
        }

        &:active {
            background-color: var(--colortheme-primary-darker);
            border-color: var(--colortheme-primary-darker);
        }

        &:disabled {
            background-color: var(--colortheme-neutral-3);
            border-color: var(--colortheme-neutral-3);
            color: var(--colortheme-neutral-5);
            cursor: not-allowed;

            &:hover {
                background-color: var(--colortheme-neutral-3);
                border-color: var(--colortheme-neutral-3);
                color: var(--colortheme-neutral-5);
            }
        }
    }

    &--outline {
        background-color: transparent;
        border-color: var(--colortheme-neutral-7);
        color: var(--colortheme-neutral-7);
        transition: none;

        .nyk-button__icon {
            path {
                fill: var(--colortheme-neutral-7);
            }
        }
        // note: should not grow border width because it will push all content
        &:hover {
            box-shadow: inset 0 0 0 1px var(--colortheme-neutral-7);
            border-color: var(--colortheme-neutral-7);
            background-color: transparent;
        }

        &:focus {
            //outline: none;
            background-color: transparent;
            border-color: var(--colortheme-neutral-7);
            color: var(--colortheme-neutral-7);
            box-shadow: inset 0 0 0 1px var(--colortheme-neutral-7);
        }

        &:active {
            outline: none;
            color: var(--colortheme-neutral-7);
            background-color: var(--colortheme-neutral-7);
            border-color: var(--colortheme-neutral-7);
            color: var(--colortheme-primary-dark);

            &:hover {
                box-shadow: none;
            }

            .nyk-button__icon {
                path {
                    fill: var(--colortheme-primary-dark);
                }
            }
        }

        &:disabled {
            background-color: transparent;
            border-color: var(--colortheme-neutral-5);
            color: var(--colortheme-neutral-5);
            cursor: not-allowed;

            &:active {
                background-color: transparent;
            }

            &:hover {
                outline: 0;
                border-color: var(--colortheme-neutral-5);
                color: var(--colortheme-neutral-5);

                .nyk-button__icon {
                    path {
                        fill: var(--colortheme-neutral-5);
                    }
                }
            }
        }

        &-primary {
            background-color: transparent;
            border-color: var(--colortheme-primary-dark);
            color: var(--colortheme-primary-dark);

            &:hover {
                background-color: var(--colortheme-primary-dark);
                border-color: var(--colortheme-primary-dark);
                color: var(--colortheme-neutral-7);

                .nyk-button__icon {
                    path {
                        fill: var(--colortheme-neutral-7);
                    }
                }
            }

            &:focus {
                background-color: var(--colortheme-primary-dark);
                color: var(--colortheme-neutral-7);
                border-color: var(--colortheme-primary-dark);

                .nyk-button__icon {
                    path {
                        fill: var(--colortheme-neutral-7);
                    }
                }
            }

            &:active {
                background-color: var(--colortheme-primary-darker);
                border-color: var(--colortheme-primary-darker);
                color: var(--colortheme-neutral-7);

                .nyk-button__icon {
                    path {
                        fill: var(--colortheme-neutral-7);
                    }
                }
            }

            &:disabled {
                background-color: transparent;
                border-color: var(--colortheme-neutral-5);
                color: var(--colortheme-neutral-5);
                cursor: not-allowed;

                &:active {
                    background-color: transparent;
                }

                &:hover {
                    background-color: transparent;
                    border-color: var(--colortheme-neutral-5);
                    color: var(--colortheme-neutral-5);

                    .nyk-button__icon {
                        path {
                            fill: var(--colortheme-neutral-5);
                        }
                    }
                }
            }
        }
    }

    &--icon {
        position: relative;
        padding-right: 7rem; // 40px + 14px + 16px

        &-reverse {
            padding-left: 7rem;
            padding-right: 2.4rem;

            &:hover {
                .nyk-button__icon {
                    animation: arrow-move-left 0.3s;
                    animation-fill-mode: forwards;
                    animation-iteration-count: 1;
                    -webkit-animation-iteration-count: 1;
                    -webkit-animation-fill-mode: forwards;
                    left: 1.2rem;
                }
            }

            &:focus {
                .nyk-button__icon {
                    animation: arrow-move-left 0.3s;
                    animation-fill-mode: forwards;
                    animation-iteration-count: 1;
                    -webkit-animation-iteration-count: 1;
                    -webkit-animation-fill-mode: forwards;
                    left: 1.2rem;
                }
            }

            &:active {
                .nyk-button__icon {
                    animation: arrow-move-left 0.3s;
                    animation-fill-mode: forwards;
                    animation-iteration-count: 1;
                    -webkit-animation-iteration-count: 1;
                    -webkit-animation-fill-mode: forwards;
                    left: 1.2rem;
                }
            }

            .nyk-button__icon {
                right: unset;
                left: 1.6rem;
            }
        }
    }

    &--link {
        position: relative;
        background-color: transparent;
        border: none;
        padding: 0;
        padding-right: 5.4rem; // 40px + 14px

        .nyk-button__icon {
            right: 1.6rem;
        }

        &:hover {
            background-color: transparent;
            color: var(--colortheme-primary);

            .nyk-button__icon {
                right: $arrow-init-pos-right;
            }
        }

        &:focus {
            background-color: transparent;

            .nyk-button__icon {
                right: $arrow-init-pos-right;
            }
        }

        &:active {
            background-color: transparent;

            .nyk-button__icon {
                right: $arrow-init-pos-right;
            }
        }

        &:disabled {
            background-color: transparent;

            &:hover {
                background-color: transparent;
            }
        }

        &-menu {
            position: relative;
            background-color: transparent;
            border: none;
            padding: 0;
            padding-right: 0.4rem;

            .nyk-button__icon {
                position: relative;
                right: unset;
                top: unset;
                transform: unset;
                opacity: 0;
            }

            &:hover {
                background-color: transparent;

                .nyk-button__icon {
                    animation: arrow-move-right 0.3s;
                    animation-fill-mode: forwards;
                    animation-iteration-count: 1;
                    -webkit-animation-iteration-count: 1;
                    -webkit-animation-fill-mode: forwards;
                    display: flex;
                    right: -4px;
                    opacity: 1;
                }
            }

            &:focus {
                background-color: transparent;

                .nyk-button__icon {
                    animation: arrow-move-right 0.3s;
                    animation-fill-mode: forwards;
                    animation-iteration-count: 1;
                    -webkit-animation-iteration-count: 1;
                    -webkit-animation-fill-mode: forwards;
                    display: flex;
                    right: -4px;
                    opacity: 1;
                }
            }

            &:active {
                background-color: transparent;

                .nyk-button__icon {
                    animation: arrow-move-right 0.3s;
                    animation-fill-mode: forwards;
                    animation-iteration-count: 1;
                    -webkit-animation-iteration-count: 1;
                    -webkit-animation-fill-mode: forwards;
                    display: flex;
                    right: -4px;
                    opacity: 1;
                }
            }

            &--white {
                color: var(--colortheme-neutral-7);
                transition: 0.3s;

                &:hover {
                    color: var(--colortheme-neutral-2);

                    .nyk-button__icon {
                        path {
                            fill: var(--colortheme-neutral-2);
                        }
                    }
                }

                .nyk-button__icon {
                    path {
                        fill: var(--colortheme-neutral-7);
                    }
                }
            }
        }

        &-arrow {
            width: 3.2rem;
            height: 3.2rem;
            border-radius: 50%;
            padding: 0;
            background-color: transparent;
            border: none;

            .nyk-button__icon {
                position: relative;
                top: unset;
                right: unset;
                transform: none;
                margin: auto;
            }

            &.right {
                .nyk-button__icon {
                    right: unset;
                }
            }

            &.left {
                .nyk-button__icon {
                    transform: rotate(180deg);
                    right: unset;
                }
            }

            &:hover {
                background-color: transparent;

                &.right {
                    .nyk-button__icon {
                        animation: arrow-move-right 0.3s;
                        animation-fill-mode: forwards;
                        animation-iteration-count: 1;
                        -webkit-animation-iteration-count: 1;
                        -webkit-animation-fill-mode: forwards;
                    }
                }

                &.left {
                    .nyk-button__icon {
                        animation: arrow-move-left 0.3s;
                        animation-fill-mode: forwards;
                        animation-iteration-count: 1;
                        -webkit-animation-iteration-count: 1;
                        -webkit-animation-fill-mode: forwards;
                    }
                }
            }

            &:focus {
                background-color: transparent;

                &.right {
                    .nyk-button__icon {
                        animation: arrow-move-right 0.3s;
                        animation-fill-mode: forwards;
                        animation-iteration-count: 1;
                        -webkit-animation-iteration-count: 1;
                        -webkit-animation-fill-mode: forwards;
                    }
                }

                &.left {
                    .nyk-button__icon {
                        animation: arrow-move-left 0.3s;
                        animation-fill-mode: forwards;
                        animation-iteration-count: 1;
                        -webkit-animation-iteration-count: 1;
                        -webkit-animation-fill-mode: forwards;
                    }
                }
            }

            &:active {
                background-color: transparent;

                &.right {
                    .nyk-button__icon {
                        animation: arrow-move-right 0.3s;
                        animation-fill-mode: forwards;
                        animation-iteration-count: 1;
                        -webkit-animation-iteration-count: 1;
                        -webkit-animation-fill-mode: forwards;
                    }
                }

                &.left {
                    .nyk-button__icon {
                        animation: arrow-move-left 0.3s;
                        animation-fill-mode: forwards;
                        animation-iteration-count: 1;
                        -webkit-animation-iteration-count: 1;
                        -webkit-animation-fill-mode: forwards;
                    }
                }
            }

            &--white {
                .nyk-button__icon {
                    path {
                        fill: var(--colortheme-neutral-7);
                    }
                }
            }
        }

        &-icon {
            width: 4.4rem; // 44px
            height: 4.4rem; // 44px
            padding: 0;
            transition: 0.3s;
            .nyk-button__icon {
                position: relative;
                top: unset;
                right: unset;
                transform: none;
                margin: auto;
            }
            &:hover, &:focus, &:active {
                .nyk-button__icon {
                    right: 0;
                }
            }
        }

        &-circle {
            border-radius: 50%;
        }

        &-circle-arrow {
            width: 4.4rem; // 44px
            height: 4.4rem; // 44px
            border-radius: 50%;
            padding: 0;
            background-color: var(--colortheme-neutral-2);
            border-color: var(--colortheme-neutral-2);

            .nyk-button__icon {
                position: relative;
                top: unset;
                right: unset;
                transform: none;
                margin: auto;
            }

            &.right {
                .nyk-button__icon {
                    right: unset;
                }
            }

            &.left {
                .nyk-button__icon {
                    transform: rotate(180deg);
                    right: unset;
                }
            }

            &:hover {
                background-color: var(--colortheme-neutral-4);
                border-color: var(--colortheme-neutral-4);

                &.right {
                    .nyk-button__icon {
                        animation: arrow-move-right 0.3s;
                        animation-fill-mode: forwards;
                        animation-iteration-count: 1;
                        -webkit-animation-iteration-count: 1;
                        -webkit-animation-fill-mode: forwards;
                    }
                }

                &.left {
                    .nyk-button__icon {
                        animation: arrow-move-left 0.3s;
                        animation-fill-mode: forwards;
                        animation-iteration-count: 1;
                        -webkit-animation-iteration-count: 1;
                        -webkit-animation-fill-mode: forwards;
                    }
                }
            }

            &:focus {
                background-color: var(--colortheme-neutral-4);
                border-color: var(--colortheme-neutral-4);

                &.right {
                    .nyk-button__icon {
                        animation: arrow-move-right 0.3s;
                        animation-fill-mode: forwards;
                        animation-iteration-count: 1;
                        -webkit-animation-iteration-count: 1;
                        -webkit-animation-fill-mode: forwards;
                    }
                }

                &.left {
                    .nyk-button__icon {
                        animation: arrow-move-left 0.3s;
                        animation-fill-mode: forwards;
                        animation-iteration-count: 1;
                        -webkit-animation-iteration-count: 1;
                        -webkit-animation-fill-mode: forwards;
                    }
                }
            }

            &:active {
                background-color: var(--colortheme-neutral-5);
                border-color: var(--colortheme-neutral-5);

                &.right {
                    .nyk-button__icon {
                        animation: arrow-move-right 0.3s;
                        animation-fill-mode: forwards;
                        animation-iteration-count: 1;
                        -webkit-animation-iteration-count: 1;
                        -webkit-animation-fill-mode: forwards;
                    }
                }

                &.left {
                    .nyk-button__icon {
                        animation: arrow-move-left 0.3s;
                        animation-fill-mode: forwards;
                        animation-iteration-count: 1;
                        -webkit-animation-iteration-count: 1;
                        -webkit-animation-fill-mode: forwards;
                    }
                }
            }
        }

        &--text {
            font-weight: 500;
            transition: 0.3s;

            &:hover {
                font-weight: 700;
                color: var(--colortheme-primary-dark);
            }

            &:focus {
                font-weight: 700;
                color: var(--colortheme-primary-dark);
            }

            &:active {
                font-weight: 700;
                color: var(--colortheme-primary-dark);
            }

            &--white {
                color: var(--colortheme-neutral-7);

                &:hover {
                    color: var(--colortheme-neutral-7);
                }

                &:focus {
                    color: var(--colortheme-neutral-7);
                }

                &:active {
                    color: var(--colortheme-neutral-7);
                }
            }
        }
    }
}

@keyframes arrow-move-right {
    from {
        right: 0;
    }

    to {
        right: -4px;
    }
}

@keyframes arrow-move-left {
    from {
        right: 0;
    }

    to {
        right: 4px;
    }
}

.nyk-button-login {
    position: relative;
    @include button();
    background-color: var(--colortheme-accent-secondary);
    border-color: var(--colortheme-accent-secondary);
    align-self: center;

    @media screen and (max-width: $breakpoint-x-small) {
        gap: 0;
    }

    &:hover {
        background-color: var(--colortheme-accent-secondary-dark);
        border-color: var(--colortheme-accent-secondary-dark);
        color: var(--colortheme-neutral-7);

        .nyk-button-login__icon {
            right: unset;
            color: var(--colortheme-neutral-7);

            path {
                fill: var(--colortheme-neutral-7);
            }
        }
    }

    &:focus {
        background-color: var(--colortheme-accent-secondary-dark);
        border-color: var(--colortheme-accent-secondary-dark);
        color: var(--colortheme-neutral-7);

        .nyk-button-login__icon {
            right: unset;
            color: var(--colortheme-neutral-7);

            path {
                fill: var(--colortheme-neutral-7);
            }
        }
    }

    &:active {
        background-color: var(--colortheme-accent-secondary-darker);
        border-color: var(--colortheme-accent-secondary-darker);
        color: var(--colortheme-neutral-7);

        .nyk-button-login__icon {
            right: unset;
            color: var(--colortheme-neutral-7);

            path {
                fill: var(--colortheme-neutral-7);
            }
        }
    }

    &:disabled {
        background-color: var(--colortheme-neutral-3);
        border-color: var(--colortheme-neutral-3);
        color: var(--colortheme-neutral-5);
        cursor: not-allowed;

        .nyk-button-login__icon {
            right: unset;

            path {
                fill: var(--colortheme-neutral-5);
            }
        }

        &:hover {
            background-color: var(--colortheme-neutral-3);
            border-color: var(--colortheme-neutral-3);
            color: var(--colortheme-neutral-5);

            .nyk-button-login__icon {
                right: unset;

                path {
                    fill: var(--colortheme-neutral-5);
                }
            }
        }
    }

    &__icon {
        position: relative;
        display: flex;
        align-self: center;
        width: 1.6rem;
        height: 2rem;
        transition: 0.3s;

        path {
            right: unset;
            fill: var(--colortheme-primary-dark);
            transition: 0.3s;
        }

        @media screen and (max-width: $breakpoint-x-small) {
            aspect-ratio: 1;
            justify-content: center;
            width: auto;
        }
    }

    &__text {
        @media screen and (max-width: $breakpoint-x-small) {
            opacity: 0;
            height: 0;
            width: 0;
            overflow: hidden;
        }
    }

    @media screen and (max-width: $breakpoint-x-small) {
        padding: 1.6rem;
    }
}

.nyk-button-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: $max-width;
    flex-wrap: wrap;
    gap: 1.6rem;

    @media screen and (min-width: $breakpoint-large) {
        margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
        margin: 0 auto;
    }

    @media screen and (max-width: $breakpoint-large) {
        margin: 0 $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
        margin: 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
        padding: 3.2rem 0;
    }

    @media screen and (max-width: $breakpoint-x-small) {
        margin: 0 $margin-x-small;
    }
}

.nyk-button-container {
    display: flex;
    justify-content: center;
    gap: 1.6rem;
    padding: 1.2rem 0 3rem 0;

    @media screen and (min-width: $breakpoint-large) {
        margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
        margin: 0 auto;
    }

    @media screen and (max-width: $breakpoint-large) {
        margin: 0 $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
        margin: 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
        padding: 3.2rem 0;
    }

    @media screen and (max-width: $breakpoint-x-small) {
        margin: 0 $margin-x-small;
    }
}
