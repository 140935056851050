.nyk-chat-window {
  background: var(--colortheme-bg-color);
  border-radius: 1.6rem;
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: auto;
  transform-origin: right top;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  transition: top 0.3s;

  @media screen and (max-width: $breakpoint-small) {
    border-radius: 0;
  }
  // Window height with top-menu__top
  @media screen and (min-width: $breakpoint-small) and (min-height: $chatPanelHeightBreakpoint) {
    margin: 0 2.4rem;
    left: auto;
    height: calc(100dvh - 16.8rem);
    max-height: 76.6rem;
    max-width: 50.7rem;
  }
  @media screen and (min-width: $breakpoint-small) {
    background: white;
  }
  // Window height with top-menu__top and top-menu__bottom
  @media screen and (min-width: $breakpoint-medium) and (min-height: $chatPanelHeightBreakpoint) {
    height: calc(100dvh - 21.6rem);
    top: 0;
  }
  @media screen and (min-width: $breakpoint-medium) and (max-height: $chatPanelHeightBreakpoint) {
    left: auto;
    max-width: 50.7rem;
  }

  &__header {
    padding: 2.4rem;
    display: flex;
    justify-content: space-between;
    line-height: 1.2;
  }
  &__header-menu-icon-burger,
  &__header-menu-icon-back {
    margin-left: -1.6rem;
    background-color: transparent;
    border-color: transparent;
    > svg {
      width: 1.6rem;
      height: 1.6rem;
    }
   
  }
  &__header-menu-icon-back {
    >svg {
      path {
        fill: none;
      }
    }
  }
  &__initial-message {
    margin-bottom: 2.4rem;
    @media screen and (min-width: $breakpoint-x-small) {
      margin-bottom: 4rem;
    }
    > * {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__header-action-buttons {
    margin-left: auto;
    display: flex;
    gap: 1.2rem;
  }
  &__header-action-minimize {
    > svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  &__header-action-close,
  &__header-action-minimize {
    &:hover {
      background-color: var(--colortheme-neutral-2);
      border-color: var(--colortheme-neutral-2);
    }
    @media screen and (min-width: $breakpoint-x-small) {
      &:hover {
        background-color: var(--colortheme-neutral-4);
        border-color: var(--colortheme-neutral-4);
      }
    }
  }

  &__header-action-close {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      width: 2.4rem;
      height: 2.4rem;
    }
    background-color: white;
    border-color: white;
    &:hover {
      background-color: var(--colortheme-neutral-2);
      border-color: var(--colortheme-neutral-2);
    }
    @media screen and (min-width: $breakpoint-x-small) {
      background-color: var(--colortheme-neutral-2);
      border-color: var(--colortheme-neutral-2);
      &:hover {
        background-color: var(--colortheme-neutral-4);
        border-color: var(--colortheme-neutral-4);
      }
    }
  }
  &__header-action-minimize {
    background-color: transparent;
    border-color: transparent;
  }
  &__scroll-container {
    position: relative;
    height: 100%;
  }
  &__content {
    flex: 1;
    border-bottom-left-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem;
    overflow-y: auto;
    &--chat-active {
      margin-bottom: 8.9rem;
    }
  }
  &__message-container {
    padding: 0 2.4rem 2.4rem 2.4rem;

    > .nyk-chat-panel__message-section:first-child {
      margin-top: 0rem;
    }
  }
}

.menuAppear-enter-active {
  transition: all 0.1s ease-out;
}
.menuAppear-leave-active {
  transition: all 0.1s ease-in;
}
.menuAppear-enter-from,
.menuAppear-leave-to {
  opacity: 0;
}
