.nyk-annual-reports-table {
  max-width: $max-width;
  padding: 6rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &__table-wrapper {
    &--hidden {
      display: none;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 4.4rem;
    margin-bottom: 8rem;

    &__title {
      @include heading("x-large");
      word-break: break-word;
    }

    &__description {
      @include bodyText("large");
      margin: 0;
    }
  }

  .nyk-dynamic-table {
    padding-bottom: 2rem;
    margin: 0;
  }
}
