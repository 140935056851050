.nyk-calculator-summary {
    padding: 3.4rem $margin-medium;
    background-color: var(--colortheme-neutral-7);
    border-radius: 1.6rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6rem;

    @media screen and (max-width: $breakpoint-small) {
        grid-template-columns: repeat(1, 1fr);
        gap: $margin-x-small;
    }

    &--simple {
        display: flex;
        flex-direction: column;
        gap: 0;
        height: 100%;

        @media screen and (max-width: $breakpoint-small) {
            padding: 2.4rem $margin-x-small;
        }

        .nyk-calculator-summary__left {
            margin-bottom: auto;

            @media screen and (max-width: $breakpoint-small) {
                width: 100%;
            }

            &-header {
                display: grid;
                grid-template-columns: minmax(50%, 38rem) auto;
                grid-template-areas: 'summaryHeaderTitle summaryHeaderButton' 'summaryHeaderDescription .';
                column-gap: 3.2rem;
                max-width: unset;

                @media screen and (max-width: $breakpoint-small) {
                    grid-template-columns: 1fr;
                    grid-template-areas: 'summaryHeaderTitle' 'summaryHeaderButton' 'summaryHeaderDescription';
                    gap: 0;
                }
            }

            .nyk-button {
                margin: 0;
                height: fit-content;
                padding: 1rem 2rem;
            }
        }
    }

    &__left {
        &-header {

            margin-bottom: 8rem;

            @media screen and (max-width: $breakpoint-small) {
                margin: 0;
            }

            .nyk-button {
                margin-top: 3.2rem;
                grid-area: summaryHeaderButton;

                @media screen and (max-width: $breakpoint-small) {
                    order: 2;
                }
            }
        }

        &-title {
            grid-area: summaryHeaderTitle;
            @include heading('medium');

            @media screen and (max-width: $breakpoint-small) {
                @include heading('small');
                margin-bottom: 2.4rem;
                order: 1;
            }


            @media screen and (max-width: $breakpoint-x-small) {
                word-break: break-word;
            }

            @media screen and (max-width: $breakpoint-xx-small) {
                font-size: 2rem;
            }
        }

        &-description {
            grid-area: summaryHeaderDescription;
            @include bodyText('medium');
            margin: 1.2rem 0 0 0;

            @media screen and (max-width: $breakpoint-small) {
                @include bodyText('small');
                margin: 2.4rem 0 3rem 0;
                order: 3;
            }
        }

        &-info {
            margin-top: 6rem;

            &-group {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: $margin-x-small;

                &:last-child {
                    dt::before {
                        background-color: var(--colortheme-primary);
                    }
                }

                dt {
                    margin-left: 1.2rem;

                    &::before {
                        content: '';
                        display: inline-block;
                        width: 1.2rem;
                        height: 1.2rem;
                        background-color: var(--colortheme-delta);
                        border-radius: 50%;
                        margin-right: 1.2rem;
                        margin-top: 0.4rem;
                    }
                }

                dd {
                    font-weight: 700;
                }
            }
        }
    }

    &__right {
        &-svg-container {
            position: relative;
            height: 35.4rem;
            width: 35.4rem;
            margin: 0 auto;

            @media screen and (max-width: $breakpoint-small) {
                height: 100%;
                width: 100%;
                max-width: 26rem;
                max-height: 26rem;
                display: flex;
                justify-content: center;
            }
        }
    }

    &__svg {
        @media screen and (max-width: $breakpoint-small) {
            height: 100%;
            width: 100%;
            max-width: 26rem;
            max-height: 26rem;
            display: flex;
            justify-content: center;
        }

        &-tooltip {
            position: absolute;
            padding: $margin-x-small;
            border-radius: 0.8rem;
            transform: translate(-50%, -100%);
            color: var(--colortheme-neutral-7);
            box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.2);
        }

        &-circle {
            fill: none;
            -webkit-animation: progress 0.75s cubic-bezier(0.73, 0.16, 0.69, 0.94) forwards;
            animation: progress 0.75s cubic-bezier(0.73, 0.16, 0.69, 0.94) forwards;
            cursor: pointer;
        }

        &-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -45%);
            width: 100%;
            max-width: 21rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 2.4rem;

            @media screen and (max-width: $breakpoint-small) {
                max-width: 17rem;
            }

            @media screen and (max-width: $breakpoint-xx-small) {
                gap: 1.2rem;
            }

            dl {
                margin: 0;

                @media screen and (max-width: $breakpoint-small) {
                    margin-top: 1rem;
                }
            }

            &-label {
                @include bodyText('small');
                margin: 0;

                @media screen and (max-width: $breakpoint-small) {
                    &:nth-child(3) {
                        margin-top: 0.8rem;
                    }
                }

                @media screen and (max-width: $breakpoint-xx-small) {
                    @include bodyText('x-small');
                }
            }

            &-value {
                font-size: 6rem;
                font-weight: 700;
                line-height: 150%;
                margin: 0;

                @media screen and (max-width: $breakpoint-small) {
                    @include heading('x-large');
                    font-weight: 700;
                }

                &-small {
                    font-size: 2.8rem;
                    font-weight: 400;
                    line-height: 150%;
                    margin: 0;

                    @media screen and (max-width: $breakpoint-small) {
                        @include heading('medium');
                    }

                    @media screen and (max-width: $breakpoint-xx-small) {
                        @include heading('small');
                    }
                }
            }
        }
    }

    &__definiftion {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        @media screen and (max-width: $breakpoint-small) {
            gap: 2rem;
        }

        &-group {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            gap: 2rem;

            @media screen and (max-width: $breakpoint-small) {
                width: 100%;
                flex-direction: column;
                gap: 1rem;
                align-items: flex-start;
            }

            &:first-child {
                .nyk-calculator-summary__definiftion-label {
                    width: 0;
                    visibility: hidden;
                    height: 0;

                    @media screen and (max-width: $breakpoint-small) {
                        width: auto;
                        visibility: visible;
                        height: auto;
                    }
                }
            }
        }

        &-label {
            font-size: 2rem;
            font-weight: 400;
            margin: 0;

            @media screen and (max-width: $breakpoint-small) {
                @include bodyText('small');
            }
        }

        &-value {
            font-size: 6rem;
            font-weight: 700;
            line-height: 5rem;
            margin: 0;

            @media screen and (max-width: $breakpoint-small) {
                @include heading('large');
                font-weight: 700;

                &-prefix {
                    display: none;
                }
            }

            &-small {
                font-size: 2rem;
                font-weight: 400;
                margin: 0;

                @media screen and (max-width: $breakpoint-small) {
                    @include heading('small');
                }
            }
        }
    }
}

@-webkit-keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}
