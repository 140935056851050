.nyk-site-searching-result {
  list-style: none;
  padding: 0;

  &__link {
    display: block;
    text-decoration: none;
    padding: 3.2rem 0 7.2rem 0;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    border-bottom: 1px solid var(--colortheme-primary-dark-20);

    @media screen and (max-width: $breakpoint-medium) {
      padding: 1.6rem 0 9rem 0;
    }

    &:hover {
      background-color: var(--colortheme-neutral-2);

      .nyk-site-searching-result__link__arrow {
        opacity: 1;
        right: 2.4rem;
      }
    }

    &:focus {
      outline-offset: 0;
      background-color: var(--colortheme-neutral-2);

      .nyk-site-searching-result__link__arrow {
        opacity: 1;
        right: 2.4rem;
      }
    }

    &__tags {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      &__tag {
        @include bodyText("x-small");
        padding: 0.4rem 0.8rem;
        background-color: var(--colortheme-beta);
        display: inline-flex;
        border-radius: 0.4rem;
        margin-bottom: 2.4rem;

        &--file {
          background-color: var(--colortheme-delta);
        }
      }
    }

    &__title {
      @include heading("medium");
      margin-bottom: 2rem;

      @media screen and (max-width: $breakpoint-medium) {
        font-size: $fs-mobile-heading-small;
      }
    }

    &__description {
      @include bodyText("large");
      margin: 0;

      @media screen and (max-width: $breakpoint-medium) {
        font-size: $fs-body-medium;
      }
    }

    &__filename {
      @include bodyText("large");
      margin: 1.6rem 0 0 0;
      color: var(--colortheme-primary) !important;
    }

    &__arrow {
      position: absolute;
      opacity: 0;
      bottom: 2rem;
      right: 2.8rem;
      width: 1.4rem;
      transition: 0.3s;

      @media screen and (max-width: $breakpoint-x-small) {
        opacity: 1;
      }
    }
  }
}
