.nyk-footer-contact {
    max-width: $max-width;
    margin: 0 auto;
    padding: 6rem 0;

    @media screen and (min-width: $breakpoint-large) {
        margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
        margin: 0 auto;
        max-width: $max-width;
    }

    @media screen and (max-width: $breakpoint-large) {
        margin: 0 $margin-large;
        max-width: unset;
    }

    @media screen and (max-width: $breakpoint-medium) {
        margin: 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
        padding: 3.2rem 0;
    }

    @media screen and (max-width: $breakpoint-x-small) {
        margin: 0 $margin-x-small;
    }

    &__title {
        @include heading("x-large");
        margin-bottom: 6rem;

        @media screen and (max-width: $breakpoint-medium) {
            margin-bottom: 4.4rem;
        }

        @media screen and (max-width: $breakpoint-small) {
            margin-bottom: 3.2rem;
        }
    }

    &__cards-group {
        display: grid;
        grid-template-areas: "card-search card-search ." "card-find-us card-write-to-us card-call-us";
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.6rem;

        @media screen and (max-width: 1024px) {
            grid-template-areas: "card-search card-search" "card-find-us card-write-to-us" "card-call-us . ";
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: $breakpoint-x-small) {
            display: flex;
            flex-direction: column;
        }

        &__card {
            @include cardBody();
            min-height: 50rem;
            display: flex;
            flex-direction: column;
            background-color: var(--colortheme-neutral-7);

            @media screen and (max-width: $breakpoint-x-small) {
                min-height: 36rem;
            }

            &__title,
            &__link-title {
                @include heading("medium");
                margin-bottom: 1.6rem;

                @media screen and (max-width: 1024px) {
                    font-size: $fs-mobile-heading-medium;
                }
            }

            &__bottom {
                margin-top: auto;

                &__input {
                    @include heading("xx-large");
                    border: none;
                    border-bottom: 1px solid var(--colortheme-primary-dark-20);
                    background-color: transparent;
                    padding: 2rem 1rem 1.6rem 1.2rem;
                    width: 100%;
                    font-family: var(--font-family);

                    @media screen and (max-width: $breakpoint-small) {
                        font-size: 3.6rem;
                        line-height: 1;
                        padding-bottom: 1.3rem;

                        &::placeholder {
                            @include heading("xx-large");
                        }
                    }

                    @media screen and (max-width: $breakpoint-xx-small) {
                        font-size: $fs-mobile-heading-small;

                        &::placeholder {
                            @include heading("x-large");
                        }
                    }

                    &:focus {
                        outline: none;
                    }

                    &::placeholder {
                        color: var(--colortheme-primary-dark);
                    }
                }

                &__description {
                    margin: 0;
                    @include heading("medium");

                    @media screen and (max-width: 1024px) {
                        font-size: $fs-mobile-heading-medium;
                    }

                    @media screen and (max-width: $breakpoint-x-small) {
                        @include heading("small");
                    }
                }

                &__description-small {
                    margin: 0;
                    @include bodyText("medium");
                    font-weight: 400;

                    @media screen and (max-width: 1024px) {
                        font-size: $fs-body-medium;
                    }
                }

                &__link-call {
                    @include heading("medium");
                    display: block;
                    margin-top: 2rem;
                    text-decoration: none;
                    cursor: pointer;

                    @media screen and (max-width: $breakpoint-medium) {
                        font-size: $fs-mobile-heading-medium;
                    }

                    @media screen and (max-width: $breakpoint-x-small) {
                        font-size: $fs-mobile-heading-large;
                    }

                    @media screen and (max-width: $breakpoint-xx-small) {
                        font-size: $fs-mobile-heading-medium;
                    }
                }

                &__action {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 2.4rem;
                    margin-top: 4.8rem;
                    text-decoration: none;
                    color: var(--colortheme-primary-dark);
                    background-color: transparent;
                    border: none;

                    @media screen and (max-width: $breakpoint-x-small) {
                        margin-top: 3.6rem;
                    }

                    &__text {
                        @include bodyText("medium");
                        font-weight: 500;
                    }

                    &__button {
                        background-color: var(--colortheme-neutral-7) !important;
                        border-color: var(--colortheme-neutral-7) !important;

                        &:hover {
                            background-color: var(--colortheme-neutral-4) !important;
                            border-color: var(--colortheme-neutral-4) !important;
                        }

                        &:focus {
                            background-color: var(--colortheme-neutral-4) !important;
                            border-color: var(--colortheme-neutral-4) !important;
                        }

                        &:active {
                            background-color: var(--colortheme-neutral-5) !important;
                            border-color: var(--colortheme-neutral-5) !important;
                        }
                    }
                }
            }

            &--search {
                grid-area: card-search;
                background-color: var(--colortheme-alfa);

                .nyk-footer-contact__cards-group__card__bottom__action {
                    margin-left: auto;
                    margin-top: 5.6rem;

                    @media screen and (max-width: $breakpoint-x-small) {
                        min-height: 3.6rem;
                    }
                }
            }

            &--find-us {
                grid-area: card-find-us;
            }

            &--write-to-us {
                grid-area: card-write-to-us;
            }

            &--call-us {
                grid-area: card-call-us;
                height: fit-content;
                min-height: 25rem;
                background-color: var(--colortheme-accent-secondary);

                @media screen and (max-width: 1024px) {
                    height: unset;
                }

                @media screen and (max-width: $breakpoint-x-small) {
                    min-height: 25rem;
                }
            }
        }
    }
}
