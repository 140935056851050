// Nykredit Sans
@font-face {
  font-family: "Nykredit Sans";
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSans-Light.woff") format("woff"),
    url("#{$baseurl-font}NykreditSans-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Nykredit Sans";
  font-weight: 200;
  font-style: italic;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSans-LightItalic.woff") format("woff"),
    url("#{$baseurl-font}NykreditSans-LightItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Nykredit Sans";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSans-Regular.woff") format("woff"),
    url("#{$baseurl-font}NykreditSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Nykredit Sans";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSans-RegularItalic.woff") format("woff"),
    url("#{$baseurl-font}NykreditSans-RegularItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Nykredit Sans";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSans-Medium.woff") format("woff"),
    url("#{$baseurl-font}NykreditSans-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Nykredit Sans";
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSans-MediumItalic.woff") format("woff"),
    url("#{$baseurl-font}NykreditSans-MediumItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Nykredit Sans";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSans-Bold.woff") format("woff"),
    url("#{$baseurl-font}NykreditSans-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Nykredit Sans";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSans-BoldItalic.woff") format("woff"),
    url("#{$baseurl-font}NykreditSans-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Nykredit Sans";
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSans-ExtraBold.woff") format("woff"),
    url("#{$baseurl-font}NykreditSans-ExtraBold.woff2") format("woff2");
}

@font-face {
  font-family: "Nykredit Sans";
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSans-ExtraBoldItalic.woff") format("woff"),
    url("#{$baseurl-font}NykreditSans-ExtraBoldItalic.woff2") format("woff2");
}

// Nykredit Sans Text
@font-face {
  font-family: "Nykredit Sans Text";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSansText-Regular.woff") format("woff"),
    url("#{$baseurl-font}NykreditSansText-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Nykredit Sans Text";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSansText-RegularItalic.woff") format("woff"),
    url("#{$baseurl-font}NykreditSansText-RegularItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Nykredit Sans Text";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSansText-Medium.woff") format("woff"),
    url("#{$baseurl-font}NykreditSansText-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Nykredit Sans Text";
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSansText-MediumItalic.woff") format("woff"),
    url("#{$baseurl-font}NykreditSansText-MediumItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Nykredit Sans Text";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSansText-Bold.woff") format("woff"),
    url("#{$baseurl-font}NykreditSansText-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Nykredit Sans Text";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("#{$baseurl-font}NykreditSansText-BoldItalic.woff") format("woff"),
    url("#{$baseurl-font}NykreditSansText-BoldItalic.woff2") format("woff2");
}
