.chat-panel-floating-trigger {
    &:has(:focus-visible), &:has(:hover) {
      .chat-panel-floating-trigger__trigger-content {
        background: var(--colortheme-neutral-2)
      }
      .chat-panel-floating-trigger__trigger-text-content {
        background: var(--colortheme-neutral-2)
      }
    }
    &:has(:active) {
      .chat-panel-floating-trigger__trigger-content {
        background: var(--colortheme-neutral-4)
      }
      .chat-panel-floating-trigger__trigger-text-content {
        background: var(--colortheme-neutral-4)
      }
    }
    &__trigger {
      position: absolute;
      top: auto;
      bottom: 2.8rem;
      right: 0;
      left: 0;
      height: 6.8rem;
      margin: auto;
      width: 100%;
      display: flex;
      gap: 1.2rem;
      max-width: 35.3rem;
      padding: 0 2.4rem;
      @media screen and (max-width: 400px) {
        max-width: 27.3rem;
      }
      @media screen and (min-width: $breakpoint-x-small) {
        left: auto;
        right: 10%;
        top: 46%;
        max-width: 37rem;
        height: 7.6rem;
        gap: 1.9rem;
        padding: 0;
      }
    }
    &__trigger-content {
      background-color: var(--colortheme-neutral-7);
      width: 6.8rem;
      height: 6.8rem;
      border-radius: 50%;
      flex: 0 0 auto;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 400px) {
        display: none;
      }
      @media screen and (min-width: $breakpoint-x-small) {
        width: 7.6rem;
        height: 7.6rem;
      }
    }
    &__response-loader-dots {
      width: 1.8rem;
      height: 1rem;
      --chat-response-loader-dots: no-repeat
        radial-gradient(circle closest-side, var(--colortheme-primary-dark) 90%, transparent);
      background: var(--chat-response-loader-dots) 0% 50%,
        var(--chat-response-loader-dots) 50% 50%,
        var(--chat-response-loader-dots) 100% 50%;
      background-size: calc(100% / 4) 50%;
      animation: responseLoader 4s infinite linear;
  
      @keyframes responseLoader {
        0%, 25%, 50%, 100% {
          background-position: 0% 50%, 50% 50%, 100% 50%;
        }
        6.25%, 31.25% {
          background-position: 0% 100%, 50% 50%, 100% 50%;
        }
        12.5%, 37.5% {
          background-position: 0% 50%, 50% 100%, 100% 50%;
        }
        18.75%, 43.75% {
          background-position: 0% 50%, 50% 50%, 100% 100%;
        }
      }
    }
    &__trigger-text-content {
      color: var(--colortheme-primary-dark);
      background-color: var(--colortheme-neutral-7);
      text-align: left;
      flex: 0 0 auto;
      font-size: 1.6rem;
      padding: 1.6rem 2.4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 1.6rem;
      width: calc(100% - 8rem);
      line-height: 1.2;
      @media screen and (max-width: 400px) {
        width: 100%;
      }
      @media screen and (min-width: $breakpoint-x-small) {

        font-size: 1.8rem;
        width: calc(100% - 9.5rem);
      }
    }
    &__trigger-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }
  
}