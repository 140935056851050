$baseurl-font: "/assets/fonts/" !default;

//Foundation
@import "./foundation/scss/legacy-overrides";
@import "./foundation/scss/normalize";
@import "./foundation/scss/mixins";
@import "./foundation/scss/variables";
@import "./foundation/scss/fonts";
@import "./foundation/scss/icons";
@import "./foundation/scss/background-box";
@import "./foundation/scss/utils/font-sizes";
@import "./foundation/scss/richtext";
@import "./foundation/scss/old-richtext-overrides";
@import "./foundation/scss/keyframes";
@import "./foundation/scss/signupStepsGeneral";
@import "./components/TheForms/TheForms.EPiServerOverrides";
@import "./foundation/scss/calculator-wrapper";
@import "./foundation/scss/accessibility";

//Blocks
@import "../../Features/Blocks/Newsletters/NewslettersSignup/NewslettersSignup";


@layer theme {
  :root {
    /* 
      Set root font size to 62.5% for easier rem calculations 
      (1rem = 10px based on default browser font size of 16px).

      Note:
      ----- 
      This approach allows for scalable and flexible typography, 
      but ensure text sizes respect user preferences.
    */
    font-size: 62.5%;
    --font-family: Nykredit Sans, "Arial";
    --border-radius: 1.6rem;
    --border-radius-button: 3.2rem;

    --colortheme-bg-color: #f6f4f3;
    --color-error: #fb264e;
    --color-warning: #8b0000;

    --colortheme-primary: #0f1e82;
    --colortheme-primary-dark: #07094a;
    --colortheme-primary-dark-60: #6a6b92;
    --colortheme-primary-dark-40: rgba(7, 9, 74, 0.4);
    --colortheme-primary-dark-20: rgba(7, 9, 74, 0.2);
    --colortheme-primary-dark-40: rgba(7, 9, 74, 0.4);
    --colortheme-primary-darker: #050634;
    --colortheme-accent-primary: #fb264e;
    --colortheme-accent-secondary: #68d2df;
    --colortheme-accent-secondary-dark: #26a4b5;
    --colortheme-accent-secondary-darker: #135058;
    --colortheme-alfa: #ffd568;
    --colortheme-beta: #fead63;
    --colortheme-gamma: #49bca3;
    --colortheme-delta: #4192dc;
    --colortheme-epsilon: #875ba3;
    --colortheme-neutral-1: #f6f4f3;
    --colortheme-neutral-2: #ede8e6;
    --colortheme-neutral-3: #e6e1db;
    --colortheme-neutral-4: #ccc3b9;
    --colortheme-neutral-5: #948d86;
    --colortheme-neutral-5-20: rgba(148, 141, 134, 0.2);
    --colortheme-neutral-5-40: rgba(148, 141, 134, 0.4);
    --colortheme-neutral-6: #131313;
    --colortheme-neutral-7: #ffffff;
    --colortheme-neutral-7-20: rgba(255, 255, 255, 0.2);
    --colortheme-neutral-7-40: rgba(255, 255, 255, 0.4);
    --colortheme-neutral-7-28: rgba(255, 255, 255, 0.28);
    --colortheme-neutral-8: #c4c4c4;
  }

  body {
    font-family: var(--font-family);
    background-color: var(--colortheme-bg-color);
    position: relative;

    &.NykreditDk {
      font-family: var(--font-family);
      background-color: var(--colortheme-bg-color);
    }
  }
}

* :focus-visible {
  outline-offset: 1rem; // 10px
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--colortheme-primary-dark);
}

// Ensure that EPiServer preview doesn't create
// vertical scrollbars from horizontal overflow
// due to EPiServer field edit borders
main:has(.epi-editContainer) {
  overflow-x: clip;
}

.nyk-overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background-color: var(--colortheme-neutral-5-20);
  z-index: 999;

  &--shown {
    display: block;
  }
}

@import "./component-styles.generated.scss";
