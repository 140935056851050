.nyk-form {
    margin: 2.4rem 0;

    &--select {
        &__content {
            position: relative;
            display: flex;
            border: 1px solid var(--colortheme-primary-dark);
            border-radius: 0.9rem;

            &:focus-within {
                border-color: var(--colortheme-delta);

                &::after {
                    transform: rotate(135deg);
                }
            }

            &::after {
                content: '';
                position: absolute;
                top: 45%;
                right: 2rem;
                height: 1rem;
                width: 1rem;
                border: 1.5px solid var(--colortheme-primary-dark);
                border-top: none;
                border-right: none;
                transform: translate(0, -50%) rotate(-45deg);
            }

            &__select:is(select) {
                flex: 1;
                padding: 2.2rem 1.6rem 0.8rem 1.6rem;
                height: 5.8rem;
                border: none;
                border-radius: 0.9rem;
                @include bodyText('large');
                font-weight: 400;
                color: var(--colortheme-primary-dark);
                -webkit-appearance: none;
                -moz-appearance: none;
                background-color: transparent;
                font-family: var(--font-family);

                &:focus {
                    outline-offset: 0;
                    outline-color: var(--colortheme-delta);
                }

                &:disabled {
                    background-color: transparent;
                    color: var(--colortheme-neutral-5);
                    cursor: not-allowed;

                    & + label {
                        color: var(--colortheme-neutral-5);
                    }
                }
            }

            &__label {
                position: absolute;
                left: 1.6rem;
                top: 0.6rem;
                @include bodyText('x-small');
                pointer-events: none;
                transition: 0.2s;
            }

            &__error-icon {
                display: none;
                position: absolute;
                top: 50%;
                right: 1.6rem;
                transform: translate(0, -50%);
                width: 2rem;
                height: 2rem;

                .nyk-icon {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }

    &__content {
        position: relative;
        display: flex;
        border: 1px solid var(--colortheme-primary-dark);
        border-radius: 0.9rem;

        &:focus-within {
            border-color: var(--colortheme-delta);
            box-shadow: 0 0 0 1px var(--colortheme-delta);

            .nyk-form__content__prefix {
                display: flex;
            }

            .nyk-form__content__reset-icon {
                display: flex;
            }
        }

        &:not(:focus-within) {
            .nyk-form__content__prefix {
                display: none;
            }
        }

        &__prefix {
            display: flex;
            align-self: center;
            padding-left: 1.6rem;
            padding-top: 1.6rem;
            @include bodyText('large');
            order: -1;
        }

        &__input:is(input) {
            flex: 1;
            padding: 2.2rem 1.6rem 0.6rem 1.6rem;
            height: 5.8rem;
            border: none;
            border-radius: 0.9rem;
            @include bodyText('large');
            font-weight: 400;
            color: var(--colortheme-primary-dark);
            background-color: transparent;
            -webkit-box-shadow: 0 0 0 30px var(--colortheme-bg-color) inset !important;
            font-family: var(--font-family);

            &[type='number'] {
                -moz-appearance: textfield; // remove html standard arrow up/down
            }

            &[type='text'],
            &[type='number'],
            &[type='tel'] {
                @include bodyText('large');
                font-weight: 400;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none; // remove html standard arrow up/down
                margin: 0;
            }

            &::-webkit-input-placeholder {
                color: transparent;
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                color: transparent;
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                color: transparent;
            }

            &:-ms-input-placeholder {
                color: transparent;
            }

            &:required {
                & + label {
                    &:after {
                        content: '*';
                    }
                }
            }

            &:not(:placeholder-shown) {
                & + label {
                    top: 0.6rem;
                    transform: none;
                    @include bodyText('x-small');
                }
            }

            &:focus {
                outline: none;
                -webkit-box-shadow: 0 0 0 30px var(--colortheme-bg-color) inset !important;

                & + label {
                    top: 0.6rem;
                    transform: none;
                    @include bodyText('x-small');
                }
            }

            &:disabled {
                background-color: transparent;
                color: var(--colortheme-neutral-5);

                & + label {
                    color: var(--colortheme-neutral-5);
                }
            }
        }

        &__label {
            position: absolute;
            left: 1.6rem;
            top: 50%;
            transform: translate(0, -50%);
            @include bodyText('large');
            pointer-events: none;
            transition: 0.2s;
        }

        &__reset-icon {
            display: none;
            position: absolute;
            align-items: center;
            justify-content: center;
            top: 50%;
            right: 1rem;
            transform: translate(0, -50%);
            height: 100%;
            background-color: transparent;
            border: none;
            cursor: pointer;

            .nyk-icon {
                width: 2rem;
                height: 2rem;
            }
        }

        &__error-icon {
            display: none;
            position: absolute;
            top: 50%;
            right: 1.6rem;
            transform: translate(0, -50%);
            width: 2rem;
            height: 2rem;

            .nyk-icon {
                width: 2rem;
                height: 2rem;
            }
        }
    }

    &--files {
        .nyk-form__error-message {
            padding-left: 2.4rem;
        }
    }

    &__file {
        position: relative;
        border: 1px solid var(--colortheme-primary-dark);
        border-radius: 3rem;
        display: flex;
        align-items: center;
        gap: 1.2rem;
        width: fit-content;
        overflow: hidden;
        padding: 1rem 2.4rem;

        &:focus-within {
            outline-offset: 1rem; // 10px
            outline-width: 2px;
            outline: solid;
            outline-color: var(--colortheme-delta);
        }

        &__input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }

        &__icon {
            width: 1.5rem;
        }

        &__label {
            @include bodyText('medium');
        }
    }

    &__files {
        margin-top: 2.4rem;
        padding-left: 2.4rem;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        &:empty {
            display: none;
        }

        &__file {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            &__name {
                @include bodyText('medium');
            }

            &__button {
                padding: 0;
                border: none;
                background-color: transparent;
                cursor: pointer;

                .nyk-icon {
                    width: 1.6rem;
                    height: 1.6rem;
                }
            }
        }
    }

    &__textfield {
        position: relative;

        &__textarea:is(textarea) {
            min-height: 12rem;
            padding: 2.4rem 1.6rem;
            @include bodyText('large');
            font-weight: 400;
            border-radius: 0.8rem;
            border: 1px solid var(--colortheme-primary-dark);
            background-color: transparent;
            resize: vertical;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            width: 100%;
            font-family: var(--font-family);

            &:focus {
                border-color: var(--colortheme-delta);
                box-shadow: 0 0 0 1px var(--colortheme-delta);
                outline-offset: 0;
            }

            &::placeholder {
                opacity: 0;
            }
        }

        &__label {
            position: absolute;
            left: 1px;
            top: 1px;
            margin: 1px;
            padding: 0.8rem 1.5rem 0.3rem 1.5rem;
            border-top-left-radius: 0.8rem;
            border-top-right-radius: 0.8rem;
            width: calc(100% - 4px);
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            background: rgb(2, 0, 36);
            background: linear-gradient(180deg, var(--colortheme-bg-color) 0%, var(--colortheme-bg-color) 90%, transparent 100%);
            @include bodyText('x-small');
        }

        &__count {
            margin-left: auto;
            padding: 0.8rem 1.6rem 0 0;
        }
    }

    &__help-message {
        display: block;
        padding: 0.8rem 1.6rem;
        @include bodyText('x-small');

        &:empty {
            padding: 0;
        }
    }

    &__error-message {
        display: block;
        padding: 0.8rem 1.6rem;
        @include bodyText('x-small');
        display: none;
    }

    &--prefix {
        .nyk-form__content__input {
            padding-left: 0.8rem;

            &:not(:placeholder-shown) {
                & + .nyk-form__content__label + .nyk-form__content__prefix {
                    display: flex;
                }
            }
        }
    }

    &--error {
        .nyk-form__content {
            border-color: var(--colortheme-accent-primary);

            &:focus-within {
                border-color: var(--colortheme-accent-primary);
                box-shadow: 0 0 0 1px var(--colortheme-accent-primary);
            }

            &__reset-icon {
                right: 4rem;
            }

            &__input {
                padding-right: 4.8rem;
                width: calc(100% - 6.4rem);
            }

            &__error-icon {
                display: block;
                pointer-events: none;
            }
        }

        .nyk-form__file {
            border-color: var(--colortheme-accent-primary);
        }

        .nyk-form--select__content {
            border-color: var(--colortheme-accent-primary);

            &::after {
                right: 5.6rem;
            }

            &__select {
                padding-right: 4.8rem;
                width: calc(100% - 6.4rem);

                &:focus {
                    outline-color: var(--colortheme-accent-primary);
                }
            }

            &__error-icon {
                display: block;
            }
        }

        .nyk-form__help-message {
            display: none;
        }

        .nyk-form__error-message {
            display: block;
        }
    }

    &--disabled {
        .nyk-form__content {
            border-color: var(--colortheme-neutral-5);

            &__input {
                cursor: not-allowed;
            }
        }

        .nyk-form--select__content {
            border-color: var(--colortheme-neutral-5);

            &__select {
                cursor: not-allowed;
            }
        }

        &.nyk-form--prefix {
            .nyk-form__content__prefix {
                color: var(--colortheme-neutral-5);
                cursor: not-allowed;
            }
        }

        .nyk-form__help-message {
            color: var(--colortheme-neutral-5);
        }

        .nyk-form__error-message {
            color: var(--colortheme-neutral-5);
        }
    }
}

.nyk-form-choice {
    margin: 4rem 0 0 0;

    &__label {
        @include heading('medium');
        margin-bottom: 0.8rem;
    }

    &__content {
        &__item {
            display: flex;
            align-items: center;
            padding: 0 1.2rem 0 0.4rem;

            &:focus-within {
                .nyk-form-choice__content__item__choice__input {
                    & + .nyk-form-choice__content__item__choice__circle {
                        .nyk-form-choice__content__item__choice__circle__outer {
                            outline: 1px solid var(--colortheme-primary-dark);
                        }

                        .nyk-form-choice__content__item__choice__circle__inner {
                            width: 1rem;
                            height: 1rem;
                        }
                    }

                    & + .nyk-form-choice__content__item__choice__square {
                        .nyk-form-choice__content__item__choice__square__outer {
                            outline: 1px solid var(--colortheme-primary-dark);
                        }

                        .nyk-form-choice__content__item__choice__square__inner {
                            width: 0.5rem;
                            height: 1rem;
                            opacity: 1;
                        }
                    }

                    &:checked {
                        & + .nyk-form-choice__content__item__choice__circle {
                            .nyk-form-choice__content__item__choice__circle__outer {
                                outline: 1px solid var(--colortheme-delta);
                            }

                            .nyk-form-choice__content__item__choice__circle__inner {
                                width: 1rem;
                                height: 1rem;
                            }
                        }

                        & + .nyk-form-choice__content__item__choice__square {
                            .nyk-form-choice__content__item__choice__square__outer {
                                outline: unset;
                            }

                            .nyk-form-choice__content__item__choice__square__inner {
                                border: none;
                                margin-bottom: 0;
                                transform: rotate(45deg);

                                &:before {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    content: '';
                                    border: none;
                                    height: 1.5px;
                                    width: 1.2rem;
                                    background-color: var(--colortheme-neutral-7);
                                    transform: translate(-50%, -50%) rotate(90deg);
                                }

                                &:after {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    content: '';
                                    height: 1.5px;
                                    width: 1.2rem;
                                    background-color: var(--colortheme-neutral-7);
                                    transform: translate(-50%, -50%) rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }

            &__choice {
                position: relative;
                height: 4.4rem;

                &__input {
                    width: 4.4rem;
                    height: 4.4rem;
                    margin: 0;
                    opacity: 0;
                    cursor: pointer;

                    &:checked {
                        & + .nyk-form-choice__content__item__choice__circle {
                            .nyk-form-choice__content__item__choice__circle__outer {
                                border-color: var(--colortheme-delta);
                            }

                            .nyk-form-choice__content__item__choice__circle__inner {
                                width: 1.2rem;
                                height: 1.2rem;
                                background-color: var(--colortheme-delta);
                            }
                        }

                        & + .nyk-form-choice__content__item__choice__square {
                            .nyk-form-choice__content__item__choice__square__outer {
                                border-color: var(--colortheme-delta);
                                background-color: var(--colortheme-delta);
                            }

                            .nyk-form-choice__content__item__choice__square__inner {
                                width: 0.5rem;
                                height: 1rem;
                                opacity: 1;
                                border-color: var(--colortheme-neutral-7);
                            }
                        }

                        &:hover {
                            & + .nyk-form-choice__content__item__choice__circle {
                                .nyk-form-choice__content__item__choice__circle__outer {
                                    outline: 1px solid var(--colortheme-delta);
                                }

                                .nyk-form-choice__content__item__choice__circle__inner {
                                    width: 1rem;
                                    height: 1rem;
                                }
                            }

                            & + .nyk-form-choice__content__item__choice__square {
                                .nyk-form-choice__content__item__choice__square__outer {
                                    border-color: var(--colortheme-delta);
                                    background-color: var(--colortheme-delta);
                                    outline: unset;
                                }

                                .nyk-form-choice__content__item__choice__square__inner {
                                    border: none;
                                    margin-bottom: 0;
                                    transform: rotate(45deg);

                                    &:before {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        content: '';
                                        border: none;
                                        height: 1.5px;
                                        width: 1.2rem;
                                        background-color: var(--colortheme-neutral-7);
                                        transform: translate(-50%, -50%) rotate(90deg);
                                    }

                                    &:after {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        content: '';
                                        height: 1.5px;
                                        width: 1.2rem;
                                        background-color: var(--colortheme-neutral-7);
                                        transform: translate(-50%, -50%) rotate(180deg);
                                    }
                                }
                            }
                        }

                        &:active {
                            & + .nyk-form-choice__content__item__choice__circle {
                                .nyk-form-choice__content__item__choice__circle__outer {
                                    outline: 1px solid var(--colortheme-delta);
                                }

                                .nyk-form-choice__content__item__choice__circle__inner {
                                    width: 0.8rem;
                                    height: 0.8rem;
                                }
                            }

                            & + .nyk-form-choice__content__item__choice__square {
                                .nyk-form-choice__content__item__choice__square__outer {
                                    border-color: var(--colortheme-delta);
                                    background-color: var(--colortheme-delta);
                                    outline: unset;
                                }

                                .nyk-form-choice__content__item__choice__square__inner {
                                    border: none;
                                    margin-bottom: 0;

                                    &:before {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        content: '';
                                        height: 1.5px;
                                        width: 1rem;
                                        background-color: var(--colortheme-neutral-7);
                                        transform: translate(-50%, -50%) rotate(90deg);
                                    }

                                    &:after {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        content: '';
                                        height: 1.5px;
                                        width: 1rem;
                                        background-color: var(--colortheme-neutral-7);
                                        transform: translate(-50%, -50%) rotate(180deg);
                                    }
                                }
                            }
                        }
                    }

                    &:hover {
                        & + .nyk-form-choice__content__item__choice__circle {
                            .nyk-form-choice__content__item__choice__circle__outer {
                                outline: 1px solid var(--colortheme-primary-dark);
                            }

                            .nyk-form-choice__content__item__choice__circle__inner {
                                width: 1rem;
                                height: 1rem;
                            }
                        }

                        & + .nyk-form-choice__content__item__choice__square {
                            .nyk-form-choice__content__item__choice__square__outer {
                                outline: 1px solid var(--colortheme-primary-dark);
                            }

                            .nyk-form-choice__content__item__choice__square__inner {
                                width: 0.5rem;
                                height: 1rem;
                                opacity: 1;
                            }
                        }
                    }

                    &:active {
                        & + .nyk-form-choice__content__item__choice__circle {
                            .nyk-form-choice__content__item__choice__circle__outer {
                                outline: 1px solid var(--colortheme-primary-dark);
                            }

                            .nyk-form-choice__content__item__choice__circle__inner {
                                width: 0.8rem;
                                height: 0.8rem;
                            }
                        }

                        & + .nyk-form-choice__content__item__choice__square {
                            .nyk-form-choice__content__item__choice__square__outer {
                                outline: 1px solid var(--colortheme-primary-dark);
                            }

                            .nyk-form-choice__content__item__choice__square__inner {
                                width: 0.4rem;
                                height: 0.8rem;
                                opacity: 1;
                            }
                        }
                    }
                }

                &__circle {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4.4rem;
                    height: 4.4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    pointer-events: none;

                    &__outer {
                        width: 2rem;
                        height: 2rem;
                        border: 1px solid var(--colortheme-primary-dark);
                        border-radius: 50%;
                    }

                    &__inner {
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-radius: 50%;
                        background-color: var(--colortheme-primary-dark);
                        transition: 0.2s;
                    }
                }

                &__square {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4.4rem;
                    height: 4.4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    pointer-events: none;

                    &__outer {
                        width: 2rem;
                        height: 2rem;
                        border: 1px solid var(--colortheme-primary-dark);
                        border-radius: 0.4rem;
                    }

                    &__inner {
                        position: absolute;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        opacity: 0;
                        width: 0;
                        height: 0;
                        margin-bottom: 0.35rem;
                        border-bottom: 1.5px solid var(--colortheme-primary-dark);
                        border-right: 1.5px solid var(--colortheme-primary-dark);
                        transform: rotate(45deg);
                        transition: 0.2s;
                    }
                }
            }

            &__label {
                @include bodyText('large');
                cursor: pointer;
            }

            &--disabled {
                .nyk-form-choice__content__item__choice__input {
                    cursor: not-allowed;

                    &:checked {
                        & + .nyk-form-choice__content__item__choice__circle {
                            .nyk-form-choice__content__item__choice__circle__outer {
                                border-color: var(--colortheme-neutral-5);
                            }

                            .nyk-form-choice__content__item__choice__circle__inner {
                                width: 1.2rem;
                                height: 1.2rem;
                                background-color: var(--colortheme-neutral-5);
                                display: flex;
                            }
                        }

                        & + .nyk-form-choice__content__item__choice__square {
                            cursor: not-allowed;

                            .nyk-form-choice__content__item__choice__square__outer {
                                background-color: var(--colortheme-neutral-5);
                                border-color: var(--colortheme-neutral-5);
                            }

                            .nyk-form-choice__content__item__choice__square__inner {
                                display: inline-flex;
                            }
                        }

                        &:hover {
                            & + .nyk-form-choice__content__item__choice__square {
                                cursor: not-allowed;

                                .nyk-form-choice__content__item__choice__square__outer {
                                    background-color: var(--colortheme-neutral-5);
                                    border-color: var(--colortheme-neutral-5);
                                }

                                .nyk-form-choice__content__item__choice__square__inner {
                                    width: 0.5rem;
                                    height: 1rem;
                                    opacity: 1;
                                    border-bottom: 1.5px solid var(--colortheme-neutral-7);
                                    border-right: 1.5px solid var(--colortheme-neutral-7);
                                    margin-bottom: 0.35rem;

                                    &::before {
                                        content: unset;
                                    }

                                    &::after {
                                        content: unset;
                                    }
                                }
                            }
                        }
                    }
                }

                .nyk-form-choice__content__item__label {
                    color: var(--colortheme-neutral-5);
                    cursor: not-allowed;
                }

                .nyk-form-choice__content__item__choice__circle {
                    cursor: not-allowed;

                    &__outer {
                        outline: unset !important;
                        border-color: var(--colortheme-neutral-5);
                    }

                    &__inner {
                        display: none;
                    }
                }

                .nyk-form-choice__content__item__choice__square {
                    cursor: not-allowed;

                    &__outer {
                        outline: unset !important;
                        border-color: var(--colortheme-neutral-5);
                    }

                    &__inner {
                        display: none;
                    }
                }
            }

            &--error {
                .nyk-form-choice__content__item__choice__input {
                    &:hover {
                        & + .nyk-form-choice__content__item__choice__square {
                            .nyk-form-choice__content__item__choice__square__outer {
                                outline-color: var(--colortheme-accent-primary);
                            }

                            .nyk-form-choice__content__item__choice__square__inner {
                                border-color: var(--colortheme-accent-primary);
                                border-bottom: 1.5px solid var(--colortheme-accent-primary);
                                border-right: 1.5px solid var(--colortheme-accent-primary);
                                transform: rotate(45deg);

                                &::before {
                                    content: unset;
                                }
                            }
                        }
                    }

                    &:checked {
                        & + .nyk-form-choice__content__item__choice__square {
                            .nyk-form-choice__content__item__choice__square__outer {
                                border-color: var(--colortheme-accent-primary);
                                background-color: var(--colortheme-accent-primary);
                            }

                            .nyk-form-choice__content__item__choice__square__inner {
                                width: 0.5rem;
                                height: 1rem;
                                transform: rotate(45deg);
                                border-bottom: 1.5px solid var(--colortheme-neutral-7);
                                border-right: 1.5px solid var(--colortheme-neutral-7);
                            }
                        }

                        &:hover {
                            & + .nyk-form-choice__content__item__choice__square {
                                .nyk-form-choice__content__item__choice__square__outer {
                                    border-color: var(--colortheme-accent-primary);
                                    background-color: var(--colortheme-accent-primary);
                                    outline: unset;
                                }

                                .nyk-form-choice__content__item__choice__square__inner {
                                    border: none;
                                    margin-bottom: 0;
                                    transform: rotate(45deg);

                                    &:before {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        content: '';
                                        border: none;
                                        height: 1.5px;
                                        width: 1.2rem;
                                        background-color: var(--colortheme-neutral-7);
                                        transform: translate(-50%, -50%) rotate(90deg);
                                    }

                                    &:after {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        content: '';
                                        height: 1.5px;
                                        width: 1.2rem;
                                        background-color: var(--colortheme-neutral-7);
                                        transform: translate(-50%, -50%) rotate(180deg);
                                    }
                                }
                            }
                        }

                        &:active {
                            & + .nyk-form-choice__content__item__choice__square {
                                .nyk-form-choice__content__item__choice__square__outer {
                                    border-color: var(--colortheme-accent-primary);
                                    background-color: var(--colortheme-accent-primary);
                                    outline: unset;
                                }

                                .nyk-form-choice__content__item__choice__square__inner {
                                    border: none;
                                    margin-bottom: 0;

                                    &:before {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        content: '';
                                        height: 1.5px;
                                        width: 1rem;
                                        background-color: var(--colortheme-neutral-7);
                                        transform: translate(-50%, -50%) rotate(90deg);
                                    }

                                    &:after {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        content: '';
                                        height: 1.5px;
                                        width: 1rem;
                                        background-color: var(--colortheme-neutral-7);
                                        transform: translate(-50%, -50%) rotate(180deg);
                                    }
                                }
                            }
                        }
                    }
                }

                .nyk-form-choice__content__item__choice__square {
                    &__outer {
                        border-color: var(--colortheme-accent-primary);
                    }

                    &__inner {
                        opacity: 1;
                        border: none;
                        transform: rotate(0deg);

                        &::before {
                            content: '!';
                            position: absolute;
                            top: 0;
                            transform: translate(0, -45%);
                            @include bodyText('small');
                            color: var(--colortheme-accent-primary);
                        }
                    }
                }
            }
        }
    }

    &--error {
        .nyk-form-choice__help-message {
            display: none;
        }

        .nyk-form-choice__error-message {
            display: block;
        }
    }

    &__help-message {
        padding: 0.8rem 1.6rem;
        @include bodyText('x-small');

        &:empty {
            padding: 0;
        }
    }

    &__error-message {
        padding: 0.8rem 1.6rem;
        @include bodyText('x-small');
        display: none;
    }
}

.nyk-form-range {
    &__label {
        @include bodyText('medium');
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__input {
        width: 100%;
        appearance: none;
        background-color: transparent;
        cursor: pointer;

        &::-webkit-slider-runnable-track {
            position: relative;
            height: 0.4rem;
            background-color: #e7e0ec;
            border-radius: 0.4rem;
        }

        &::-webkit-slider-thumb {
            box-sizing: border-box;
            appearance: none;
            position: relative;
            background-color: var(--colortheme-delta);
            border-radius: 2rem;
            width: 2rem;
            height: 2rem;
            top: 50%;
            translate: 0 -50%;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
        }

        &::-moz-range-track {
            position: relative;
            height: 0.4rem;
            background-color: #e7e0ec;
            border-radius: 0.4rem;
        }

        &::-moz-range-thumb {
            box-sizing: border-box;
            appearance: none;
            position: relative;
            background-color: var(--colortheme-delta);
            border-radius: 2rem;
            width: 2rem;
            height: 2rem;
            top: 50%;
            translate: 0 -50%;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
        }
    }
}

.nyk-form-layout {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    @media screen and (max-width: $breakpoint-x-small) {
        gap: 1.6rem;
    }

    &__row {
        display: flex;
        align-items: flex-start;
        gap: 2.4rem;

        @media screen and (max-width: $breakpoint-x-small) {
            flex-direction: column;
            align-items: unset;
            gap: 1.6rem;
        }

        .nyk-form {
            flex: 1;
        }
    }
}

@import './TheForms.container.scss';
