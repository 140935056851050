.nyk-signup-step-select-newsletters {
  &__error {
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: 2.4rem;
    padding: 1.6rem;
    margin-bottom: 1.2rem;
    border-radius: var(--border-radius);
    background-color: var(--colortheme-primary-dark);

    @media screen and (max-width: $breakpoint-small) {
      margin: 1.6rem auto 0 auto;
    }

    &__text {
      @include bodyText("small");
      color: var(--colortheme-neutral-7);
    }

    &__icon {
      display: flex;
      align-items: center;

      svg {
        height: 2rem;
      }
    }
  }

  &__title-spacing {
    margin-bottom: 11.6rem;

    @media screen and (max-width: $breakpoint-small) {
      margin-bottom: 10rem;
    }
  }
}
