.nyk-multi-select-dropdown {
    position: relative;
    flex: 1;
    flex-grow: 0;
    border: 1px solid var(--colortheme-primary-dark);
    height: 6rem;
    width: 100%;
    min-width: 25rem;
    max-width: 35rem;
    border-radius: 0.9rem;
    @include bodyText('large');
    color: var(--colortheme-primary-dark);
    background-color: transparent;

    @media screen and (max-width: $breakpoint-small) {
        max-width: unset;
        height: unset;
        border: none;
    }

    &--popup-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        @media screen and (max-width: $breakpoint-small) {
            border-bottom: none;
            width: 100%;
        }

        .nyk-multi-select-dropdown__button {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .nyk-multi-select-dropdown__popup {
            display: block;
        }

        .nyk-multi-select-dropdown__button__arrow {
            transform: rotate(135deg);
        }
    }

    &__button {
        position: relative;
        padding: 0.4rem 1.6rem 0.8rem 1.6rem;
        height: 100%;
        width: 100%;
        background-color: transparent;
        border-radius: 0.9rem;
        border: none;
        text-align: left;
        transition: 0.3s;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-small) {
            gap: 0.8rem;
            padding: 1rem 1.2rem;
            background-color: transparent;
            border: 1px solid var(--colortheme-primary-dark);
            transition: none;
        }

        &:focus-visible {
            outline: 2px solid;
            outline-offset: 0;
            outline-color: var(--colortheme-primary-dark);
        }

        &__label {
            @include bodyText('x-small');
            line-height: 1.6rem;

            @media screen and (max-width: $breakpoint-small) {
                white-space: nowrap;

                &::after {
                    content: ':';
                }
            }

            &--empty {
                @include bodyText('large');
                line-height: 2rem;
                color: var(--colortheme-primary-dark);
            }
        }

        &__selected {
            @include bodyText('large');
            line-height: 2rem;
            color: var(--colortheme-primary-dark);
            padding-right: 2rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media screen and (max-width: $breakpoint-small) {
                line-height: 2rem;
                padding-right: 3.2rem;
            }
        }

        &__close {
            display: none;
            position: absolute;
            top: 50%;
            right: 1.6rem;
            transform: translateY(-50%);
            border: none;
            background-color: transparent;
            padding: 0;
            cursor: pointer;

            &--show {
                display: block;

                @media screen and (max-width: $breakpoint-small) {
                    top: 3.2rem;
                }
            }

            .nyk-icon {
                width: 2rem;
                height: 2rem;
            }
        }

        &__arrow {
            position: absolute;
            top: 50%;
            right: 2rem;
            height: 1rem;
            width: 1rem;
            border: 1.5px solid var(--colortheme-primary-dark);
            border-top: none;
            border-right: none;
            transform: translate(0, -50%) rotate(-45deg);
        }
    }

    &__popup {
        position: absolute;
        top: 5.8rem;
        left: -1px;
        display: none;
        width: calc(100% + 2px);
        padding: 1.6rem 0;
        overflow-y: auto;
        background-color: var(--colortheme-neutral-7);
        transition: 0.3s;
        max-height: 40rem;
        border: 1px solid var(--colortheme-primary-dark);
        border-bottom-left-radius: 0.9rem;
        border-bottom-right-radius: 0.9rem;
        z-index: 10;

        @media screen and (max-width: $breakpoint-small) {
            position: relative;
            background-color: transparent;
            top: unset;
            left: 0;
            width: 100%;
            border-top: none;
        }

        &__form {
            &__item {
                overflow: hidden;
                text-overflow: ellipsis;
                border-bottom: 1px dashed var(--colortheme-primary-dark-20);
                padding: 0.8rem 0;

                &:last-child {
                    border-bottom: none;
                }

                &.nyk-form {
                    margin: 0;
                }

                .nyk-form-choice__content__item {
                    padding: 0;

                    &:focus-within {
                        outline: 2px solid;
                        outline-offset: -0.5rem;
                        outline-color: var(--colortheme-primary-dark);
                    }
                }

                .nyk-form-choice__content__item__label {
                    padding: 0.5rem 0;
                    padding-right: 1rem;
                    word-break: break-word;
                    width: 100%;
                    // auto hyphen doesn't work in chrome at the moment
                    -webkit-hyphens: auto;
                    -moz-hyphens: auto;
                    -ms-hyphens: auto;
                    hyphens: auto;
                }

                .nyk-form-choice__content__item__choice__input {
                    height: 3.8rem;
                }

                .nyk-form-choice__content__item__label {
                    word-wrap: break-word;
                }

                .nyk-form-choice__content__item__choice__square__outer {
                    transform: scale(0.75);
                }

                .nyk-form-choice__content__item__choice__square__inner {
                    transform: scale(0.75) rotate(45deg);

                    &:before {
                        width: 1rem !important;
                    }

                    &:after {
                        width: 1rem !important;
                    }
                }
            }
        }
    }
}
