.nyk-articles-insight {
  padding: 6rem 0;

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1.6rem;

    max-width: $max-width;
    padding-bottom: 3.2rem;

    @media screen and (min-width: $breakpoint-large) {
      margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
      margin: 0 auto;
      max-width: $max-width;
    }

    @media screen and (max-width: $breakpoint-large) {
      margin: 0 $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
      margin: 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
      flex-direction: column;
      gap: 0.8rem;
      align-items: flex-start;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      margin: 0 $margin-x-small;
    }

    &__title {
      flex: 1;
      @include heading("medium");

      @media screen and (max-width: $breakpoint-small) {
        margin-bottom: 2.4rem;
      }
    }

    .nyk-multi-select-dropdown {
      @media screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
    }
  }

  .nyk-standard-card-layout {
    padding-top: 0;
  }
}
