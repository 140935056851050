.nyk-filter-wrapper {
    overflow-x: auto;
}

.nyk-filter-tabs {
    position: relative;
    cursor: all-scroll;
    display: flex;
    align-items: center;

    &::-webkit-scrollbar {
        display: none;
    }

    &--mobile-hidden {
        @media screen and (max-width: $breakpoint-xx-small) {
            display: none;
        }
    }

    &__line {
        position: absolute;
        bottom: 0;
        height: 2px;
        background-color: var(--colortheme-delta);
        transition: 0.3s;
        transition-timing-function: ease-in-out;
        z-index: 3;
    }

    &__tab {
        position: relative;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid var(--colortheme-primary-dark-20);
        background-color: transparent;
        padding: 0;
        border: 0;
        transition: 0.3s;

        &:hover {
            background-color: var(--colortheme-neutral-2);
        }

        &:focus-visible {
            outline-offset: -0.5rem;
        }

        &:focus-within {
            background-color: var(--colortheme-neutral-3);
        }

        &:active {
            background-color: var(--colortheme-neutral-2);
        }

        &__button {
            position: relative;
            @include bodyText('medium');
            white-space: nowrap;
            border: none;
            background-color: transparent;
            padding: 1.6rem 3.2rem;
            width: 100%;
            cursor: pointer;
            z-index: 2;
            overflow: hidden;

            &:focus {
                outline: none;
            }

            &:active {
                &:after {
                    animation: tabPressed 0.3s;
                    animation-delay: 0.1s;
                    animation-fill-mode: forwards;
                    animation-iteration-count: 1;
                    -webkit-animation-iteration-count: 1;
                    -webkit-animation-fill-mode: forwards;
                }
            }

            &::after {
                content: '';
                position: absolute;
                right: -10rem;
                top: -3.2rem;
                opacity: 0;
                height: 12rem;
                width: 12rem;
                border-top-left-radius: 12rem;
                border-bottom-left-radius: 12rem;
                background-color: var(--colortheme-neutral-4);
                transition: 0.3s;
                z-index: 1;
            }

            &__text {
                position: relative;
                z-index: 3;
                font-weight: 500;
            }
        }
    }
}

.nyk-filter-dropdown {
    display: none; 
    
    @media screen and (max-width: $breakpoint-xx-small) {
        display: block;
    }

    .nyk-form--select__content__select {
        padding-top: 0.8rem;
    }
}
