.nyk-signup-single-newsletter {
  .nyk-signup-step__content {
    width: $column-aside-big;

    @media screen and (max-width: $breakpoint-small) {
      width: 100%;
    }

    .nyk-signup-step-actions--no-prev {
      padding-top: 4rem;
    }
  }

  &--nykredit-bolignyt {
    .nyk-signup-step__content {
      background-color: var(--colortheme-gamma);
    }
  }

  &--inspiration-om-boligkøb {
    .nyk-signup-step__content {
      background-color: var(--colortheme-gamma);
    }
  }

  &--nykredit-invest {
    .nyk-signup-step__content {
      background-color: var(--colortheme-accent-secondary);
    }
  }

  &--nykredit-erhvervsnyt {
    .nyk-signup-step__content {
      background-color: var(--colortheme-delta);
    }
  }

  &--international-bolignyt {
    .nyk-signup-step__content {
      background-color: var(--colortheme-beta);
    }
  }

  &--nykredit-landbrug {
    .nyk-signup-step__content {
      background-color: var(--colortheme-alfa);
    }
  }

  &--elite-nyhedsbrev {
    .nyk-signup-step__content {
      background-color: var(--colortheme-primary-dark);

      * {
        color: var(--colortheme-neutral-7);
      }

      .nyk-signup-step__input {
        border-color: rgba(255, 255, 255, 0.2);
        &::placeholder {
          color: var(--colortheme-neutral-7);
        }

        /* Chrome, Safari, and Edge */
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
          -webkit-text-fill-color: var(--colortheme-neutral-7) !important;
        }
      }
    }
  }

  &--kundepanel {
    .nyk-signup-step__content {
      background-color: var(--colortheme-epsilon);


      * :focus-visible {
        outline-color: var(--colortheme-neutral-7);
      }

      * {
        color: var(--colortheme-neutral-7);
      }

      .nyk-signup-step__input {
        border-color: rgba(255, 255, 255, 0.2);
        &::placeholder {
          color: var(--colortheme-neutral-7);
        }

        /* Chrome, Safari, and Edge */
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
          -webkit-text-fill-color: var(--colortheme-neutral-7) !important;
        }
      }
    }
  }
}
