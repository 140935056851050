@import "./ChatFeedbackInput/ChatFeedbackInput.scss";
@import "./ChatFeedbackPhoneNumber/ChatFeedbackPhoneNumber.scss";
@import "./ChatFeedbackReview/ChatFeedbackReview.scss";

.nyk-chat-feedback {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--colortheme-neutral-1);
  display: flex;
  flex-direction: column;
  border-radius: 1.6rem;

  @media screen and (min-width: $breakpoint-small), screen and (max-height: $chatPanelHeightBreakpoint) {
    background-color: white;
}
}
