.nyk-mit-nykredit-menu {
    position: relative;
    background-color: var(--colortheme-neutral-7);
    z-index: $menuZIndex;

    &--scrolling {

        &.nyk-mit-nykredit-menu--submenu-open {
            .nyk-mit-nykredit-menu__submenu {
                position: fixed;
                top: 8.7rem;
            }

            .nyk-mit-nykredit-menu__top-menu__bottom {
                position: fixed;
                margin-top: 0;
                top: 0;
            }
        }

        .nyk-mit-nykredit-menu__top-menu__bottom {
            position: fixed;
            top: -8.8rem;
        }
    }

    &--sticky-top {

        .nyk-mit-nykredit-menu__top-menu__bottom {
            position: fixed;
            margin-top: 0;
            top: 0;
        }

        &.nyk-mit-nykredit-menu--submenu-open {
            .nyk-mit-nykredit-menu__submenu {
                position: fixed;
                top: 8.7rem;
            }

            .nyk-mit-nykredit-menu__top-menu__bottom {
                position: fixed;
                margin-top: 0;
                top: 0;
            }
        }
    }

    &--submenu-open {

        .nyk-mit-nykredit-menu__submenu {
            display: flex;
        }

        &.nyk-mit-nykredit-menu--scrolling {
            .nyk-mit-nykredit-menu__submenu {
                position: fixed;
                top: 8.7rem;
            }
        }

    }

    &__top-menu {
        position: relative;
        padding-bottom: 8.8rem;

        &__top {
            height: 4.8rem;
            display: flex;
            justify-content: flex-end;

            &::after {
                content: "";
                position: absolute;
                border-bottom: 1px solid var(--colortheme-neutral-3);
                width: 100%;
                left: 0;
                top: 4.8rem;
            }

            @media screen and (min-width: $breakpoint-large) {
                padding: 0 $margin-x-large;
            }
        
            @media screen and (min-width: $breakpoint-computed-x-large) {
                margin: 0 auto;
                max-width: $max-width;
                padding: 0;
            }
        
            @media screen and (max-width: $breakpoint-large) {
                padding: 0 $margin-large;
            }
        
            @media screen and (max-width: $breakpoint-medium) {
                padding: 0 $margin-medium;
                width: unset;
                height: 0;
                overflow: hidden;
            }    
            
            @media screen and (max-width: $breakpoint-x-small) {
                padding: 0 $margin-x-small;
            }

            &__menu-items {
                display: flex;
                align-items: center;
                gap: 3.2rem;
                list-style: none;
                padding-left: 0;
                margin: 0;

                &__item {
                    position: relative;
                    @include bodyText('small');

                    &__link {
                        text-decoration: none;
                        color: var(--colortheme-primary-dark);
                        border-bottom: 1px solid transparent;
                        transition: 0.2s;

                        &:hover {
                            border-bottom: 1px solid;
                            border-color: var(--colortheme-netrual-3);
                        }

                        &--active {
                            border-bottom: 1px solid;
                            border-color: var(--colortheme-netrual-3);
                        }
                        
                    }
                }
            }
        }

        &__bottom {
            margin-top: 0.1rem;
            position: absolute;
            height: 8.7rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: var(--colortheme-neutral-7);
            transition: top 0.3s;
            width: 100%;

            @media screen and (min-width: $breakpoint-large) {
                padding: 0 $margin-x-large;
            }
        
            @media screen and (min-width: $breakpoint-computed-x-large) {
                margin: 0 auto;
                margin-top: 0.1rem;
            }
        
            @media screen and (max-width: $breakpoint-large) {
                padding: 0 $margin-large;
            }
        
            @media screen and (max-width: $breakpoint-medium) {
                padding: 0 $margin-medium;
            }

            @media screen and (max-width: $breakpoint-x-small) {
                padding: 0 $margin-x-small;
            }

            &__container {
                display: flex;
                align-items: center;
                width: 100%;
                max-width: $max-width;
                margin: 0 auto;

                &__logo {
                    display: flex;
                    align-items: center;

                    svg {
                        width: 13.6rem;

                        @media screen and (max-width: $breakpoint-x-small) {
                            width: 14.3rem;
                        }

                        @media screen and (max-width: $breakpoint-x-small) {
                            width: 13.6rem;
                        }
                    }
                }

                &__menu-items {
                    display: flex;
                    align-items: center;
                    gap: 4.8rem;
                    padding-left: 12.6rem;
                    list-style: none;

                    @media screen and (max-width: $breakpoint-large) {
                        gap: 3.2rem;
                    }

                    @media screen and (max-width: $breakpoint-medium) {
                        padding: 0 $margin-medium;
                        width: unset;
                        display: none;
                    }

                    &__item {

                        &__link {
                            @include bodyText('medium');
                            text-decoration: none;
                            border: none;
                            padding: 0;
                            border-bottom: 1px solid transparent;
                            transition: 0.2s;
                            background-color: transparent;
                            cursor: pointer;

                            &:hover {
                                border-bottom: 1px solid;
                                border-color: var(--colortheme-netrual-3);
                            }

                            &--active {
                                border-bottom: 1px solid;
                                border-color: var(--colortheme-netrual-3);
                            }
                        }
                    }
                }

                &__actions {
                    display: flex;
                    align-items: center;
                    gap: 3.2rem;
                    margin-left: auto;

                    @media screen and (max-width: $breakpoint-x-small) {
                        gap: 1.6rem;
                    }

                    &__link {
                        @include bodyText('medium');
                        text-decoration: none;
                        border-bottom: 1px solid transparent;
                        transition: 0.2s;

                        @media screen and (max-width: $breakpoint-medium) {
                            display: none;
                        }

                        &:hover {
                            border-bottom: 1px solid;
                            border-color: var(--colortheme-netrual-3);
                        }
                    }

                    &__burgermenu {
                        padding: 0;
                        background-color: transparent;
                        border: none;
                        width: 4.4rem;
                        height: 4.4rem;
                        display: none;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        
                        @media screen and (max-width: $breakpoint-medium) {
                            display: flex;
                        }

                        @media screen and (max-width: $breakpoint-small) {
                            z-index: $menuZIndex;
                        }

                        &--open {
                            .nyk-mit-nykredit-menu__top-menu__bottom__container__actions__burgermenu__symbol {

                                &__line {
                                    &:first-child {
                                        animation: burgermenu-top-close-line-1 0.3s; 
                                        animation-fill-mode:forwards;
                                        animation-iteration-count: 1;
                                        -webkit-animation-iteration-count: 1;
                                        -webkit-animation-fill-mode: forwards;
                                    }

                                    &:nth-child(2) {
                                        animation: burgermenu-top-close-line-2 0.3s; 
                                        animation-fill-mode:forwards;
                                        animation-iteration-count: 1;
                                        -webkit-animation-iteration-count: 1;
                                        -webkit-animation-fill-mode: forwards;
                                    }

                                    &:last-child {
                                        animation: burgermenu-top-close-line-3 0.3s; 
                                        animation-fill-mode:forwards;
                                        animation-iteration-count: 1;
                                        -webkit-animation-iteration-count: 1;
                                        -webkit-animation-fill-mode: forwards;
                                    }
                                }
                            }
                        }

                        &--close {
                            .nyk-mit-nykredit-menu__top-menu__bottom__container__actions__burgermenu__symbol {

                                &__line {
                                    &:first-child {
                                        animation: burgermenu-top-open-line-1 0.3s; 
                                        animation-fill-mode:forwards;
                                        animation-iteration-count: 1;
                                        -webkit-animation-iteration-count: 1;
                                        -webkit-animation-fill-mode: forwards;
                                    }

                                    &:nth-child(2) {
                                        animation: burgermenu-top-open-line-2 0.3s; 
                                        animation-fill-mode:forwards;
                                        animation-iteration-count: 1;
                                        -webkit-animation-iteration-count: 1;
                                        -webkit-animation-fill-mode: forwards;
                                    }

                                    &:last-child {
                                        animation: burgermenu-top-open-line-3 0.3s; 
                                        animation-fill-mode:forwards;
                                        animation-iteration-count: 1;
                                        -webkit-animation-iteration-count: 1;
                                        -webkit-animation-fill-mode: forwards;
                                    }
                                }
                            }
                        }

                        &__symbol {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            width: 3.2rem;
                            height: 1.8rem;
                            -moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
                            -o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
                            -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
                            -webkit-transition-delay: 0.3s, 0.1s;
                            transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;

                            &__line {
                                height: 0.2rem;
                                width: 100%;
                                background-color: var(--colortheme-primary-dark);
                                transition: 0.3s;

                                &:first-child {
                                    transform: translateY(0) rotate(0);
                                    transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
                                }

                                &:nth-child(2) {
                                    transform: translateY(0) rotate(0);
                                    transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
                                }

                                &:last-child {
                                    transform: translateY(0) rotate(0);
                                    transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
                                }
                            }         
                        }           
                    }
                }
            }
        }
    } 

    &__submenu {
        position: absolute;
        top: 13.6rem;
        left: 0;
        width: 100%;
        background-color: var(--colortheme-neutral-7);
        min-height: calc(100vh - 25.6rem);
        padding: 6rem 0;
        display: none;
        gap: 1.6rem;
        border-top: 1px solid var(--colortheme-neutral-3);

        @media screen and (max-width: $breakpoint-medium) {
            min-height: calc(100vh - 18rem);
            padding-top: 3.2rem;
            top: 8.8rem;
        }

        @media screen and (max-width: 1150px) {
            gap: 6rem;
        }
        
        @media screen and (max-width: $breakpoint-small) {
            min-height: calc(100vh - 8.6rem);
            top: 0;
            margin: 0;
            padding-top: 0;
        }
        
        @media screen and (max-width: $breakpoint-x-small) {
            margin: 0 $margin-x-small;
            top: 0;
            margin: 0;
            width: 100%;
        }

        &__content {
            width: 100%;
            display: flex;
            gap: 1.6rem;


            @media screen and (min-width: $breakpoint-large) {
                margin: 0 $margin-x-large;
            }

            @media screen and (max-width: $breakpoint-large) {
                margin: 0 $margin-large;
            }
        
            @media screen and (min-width: $breakpoint-computed-x-large) {
                margin: 0 auto;
                max-width: $max-width;
            }

            @media screen and (max-width: $breakpoint-medium) {
                margin: 0 $margin-medium;
                gap: 8rem;
            }

            @media screen and (max-width: 1150px) {
                gap: 6rem;
            }

            @media screen and (max-width: $breakpoint-small) {
                flex-direction: column;
            }

            @media screen and (max-width: $breakpoint-x-small) {
                margin: 0 $margin-x-small;
                padding: 0 $margin-x-small;
                width: calc(100% - ($margin-x-small * 2));
            }

            &__items-mobile {
                flex: 0.75;
                display: none;
                flex-direction: column;

                @media screen and (max-width: $breakpoint-medium) {
                    display: flex;
                }

                &__lang-list {
                    height: 8.8rem;
                    padding-left: 0;
                    list-style: none;
                    display: none;
                    align-items: center;
                    gap: 3.2rem;
                    margin: 0 7.6rem 3rem 7.6rem;
                    
                    @media screen and (max-width: $breakpoint-small) {
                        display: flex;
                    }

                    @media screen and (max-width: $breakpoint-x-small) {
                        margin: 0 0.8rem;
                    }

                    &__item {

                        &__link {
                            @include bodyText('small');
                            text-decoration: none;
                            transition: 0.2s;

                            &:hover {
                                border-bottom: 1px solid;
                                border-color: var(--colortheme-netrual-3);
                            }

                            &--active {
                                border-bottom: 1px solid;
                                border-color: var(--colortheme-netrual-3);
                            }
                        }
                    }
                }

                &__header {
                    position: relative;
                    padding-left: 7.6rem;
                    margin-top: 6.8rem;

                    @media screen and (max-width: $breakpoint-x-small) {
                        padding-left: 0.8rem;
                    }

                    &__title {
                        @include heading('medium');
                        display: flex;
                        align-items: center;
                        gap: 1.2rem;
                        cursor: pointer;
                        background-color: transparent;
                        padding: 0;
                        border: none;
                        margin-bottom: 2.4rem;
                    }
                }

                &__list {
                    flex: 1;
                    list-style: none;
                    padding: 0 0 0 9.2rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1.6rem;

                    @media screen and (max-width: $breakpoint-small) {
                        padding: 0 7.6rem 0 9.2rem;
                    }

                    @media screen and (max-width: $breakpoint-x-small) {
                        padding: 0 2.4rem;
                    }
        
                    @media screen and (max-width: $breakpoint-xx-small) {
                        padding: 0 0.8rem;
                    }

                    &--hide {
                        display: none;
                    }

                    &__item {

                        &__link {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            background-color: transparent;
                            border: none;
                            padding: 0;
                            width: 100%;
                            color: var(--colortheme-primary-dark);
                            text-decoration: none;
                            cursor: pointer;

                            &__text {
                                @include bodyText('large');

                                &--highlight {
                                    font-weight: 700;
                                }
                            }

                            &__icon {

                                svg {
                                    width: 1.6rem;
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}