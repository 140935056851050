.nyk-tooltip {
    background-color: var(--colortheme-primary-dark);
    border-radius: 0.8rem;
    padding: 1.6rem;
    min-width: 36rem;
    position: absolute;
    z-index: 50;
    border: 0;
    margin: 0;
    left: 1.4rem;
    bottom: 6rem;
    transform: translate(-50%, 2rem);

    @media screen and (max-width: $breakpoint-small) {
        min-width: 25rem;
    }

    &__container {
        position: relative;
        margin: 0 1rem;
    }

    &__tooltip-button {
        width: 2rem;
        height: 2rem;
        border-radius: 2rem;
        background-color: transparent;
        border: 1px solid var(--colortheme-primary-dark);
        font-style: italic;
        padding: 0;
        cursor: pointer;

        &:focus-visible {
            outline: 2px solid;
            outline-color: var(--colortheme-primary-dark);
            outline-offset: 0.6rem;
        }

        &--active {
            background-color: var(--colortheme-primary-dark);
            color: var(--colortheme-neutral-7);
        }
    }

    &__triangle {
        height: 2rem;
        width: 2rem;
        transform: rotate(45deg) translate(-50%, 10px);
        background-color: var(--colortheme-primary-dark);
        position: absolute;
        bottom: -1rem;
        left: 50%;
    }

    &__definition {
        margin: 0;
        background-color: var(--colortheme-primary-dark);
        @include bodyText('small');
        color: var(--colortheme-neutral-7);
    }
}
