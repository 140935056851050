.nyk-chat-feedback-phone-number {
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  flex: 1;
  &__heading {
    font-weight: 400;
    font-size: 3.2rem;
    margin: 0.4 0 1.4rem;
    line-height: 1.2;
  }
  &__input {
    border: none;
    border-bottom: 1px solid #CDCEDB;
    background-color: transparent;
    margin-top: auto;
    white-space: pre-line;
    word-break: break-word;
    width: 100%;
    @include fluid-size('font-size', '44', '56', '375', '1420');
    font-size: 4.4rem;
    line-height: 1;
    color: var(--colortheme-primary-dark);
    margin-top: 1.2rem;
    border-radius: 0;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color:#07094a;
    }
  }
  &__disclaimer {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-top: 1.2rem;
    > * {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__form {
    display: flex;
    margin-top: auto;
    flex-direction: column;
    gap: 0.6rem;
  }
  &__send-button {
    text-transform: none;
    margin-left: auto;
    margin-top: 1.2rem;
    position: relative;
    background-color: transparent;
    border: none;
    color: #07094A;
  }
  &__send-button-text {
    display: flex;
    align-items: center;
    align-self: flex-end;
    gap: 2.4rem;
    margin-top: 3.6rem;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
    font-weight: 500;

    &:first-child {
      margin-top: 0;
    }
    > svg {
      width: 1.6rem;
      height: 1.2rem;
    }
    .nyk-button {
      @media screen and (max-width: $breakpoint-x-small) {
        background-color: white;
        border-color:white;
      }
    }
  }
  &__form-error-text {
    @include bodyText("x-small");
    color: var(--color-error);
  }
  &__close-btn {
    margin-bottom: 2.4rem;
    position: relative;
  }
}
