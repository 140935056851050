.nyk-job-application {
  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
    width: unset;
    padding-top: 0;
  }

  &__form {
    &__section {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
    }
  }

  &__content {
    width: $column-aside-big;
    margin-left: auto;

    @media screen and (max-width: $breakpoint-small) {
      width: 100%;
    }

    &__title {
      @include heading("x-large");
      word-break: break-word;

      @media screen and (max-width: $breakpoint-small) {
        margin-bottom: 2.4rem;
      }
    }

    &__info {
      display: grid;
      grid-template-columns: auto auto;
      gap: 4rem;

      @media screen and (max-width: $breakpoint-small) {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    &__form {
      &__group {
        margin-bottom: 4rem;
      }

      &__button {
        margin-top: 2rem;
        margin-bottom: 12rem;
      }

      &__gender {
        margin-top: 1.6rem;
      }

      &__section {
        margin: 1.6rem 0 0.8rem 0;
      }

      &__button {
        margin: 6rem 0 1.6rem 0;
        min-height: 4.4rem;
      }

      &__error-text {
        display: flex;
        align-items: center;
        gap: 2.4rem;
        padding: 1.6rem;
        border-radius: 1.6rem;
        border: 1px solid var(--color-error);
        margin-top: auto;
        @include bodyText("x-small");
        color: var(--color-error);
        background-color: #fb264e10;
        width: fit-content;

        &:empty {
          display: none;
        }

        .nyk-icon {
          width: 2rem;
          height: 2rem;
        }
      }

      .nyk-form {
        margin: 0;
      }

      .nyk-form-layout {
        .nyk-form-layout {
          // 2.4/1.6rem is gap inside outermost nyk-form-layout (can't be removed as it controls spacings generally)

          @media screen and (max-width: $breakpoint-x-small) {
            gap: 2rem;
          }

          .nyk-form .nyk-form__files {
            @media screen and (max-width: $breakpoint-x-small) {
              margin-top: 1.6rem;
              margin-bottom: 2rem;
            }
          }
        }
      }

      .nyk-form-choice,
      .nyk-job-application__form__section {
        // 2.4/1.6rem is gap inside outermost nyk-form-layout (can't be removed as it controls spacings generally)
        margin-top: calc(4rem - 2.4rem);

        @media screen and (max-width: $breakpoint-x-small) {
          margin-top: calc(4.4rem - 1.6rem);
        }
      }

      .nyk-job-application__form__section {
        // 2.4/1.6rem is gap inside outermost nyk-form-layout (can't be removed as it controls spacings generally)
        margin-bottom: calc(3.2rem - 2.4rem);

        @media screen and (max-width: $breakpoint-x-small) {
          margin-bottom: calc(2.4rem - 1.6rem);
        }
      }
    }

    .nyk-image {
      margin: 0;

      &__content {
        max-width: 100%;
      }
    }
  }
}

// bruger komponent class da jeg skal ramme nyk-hero-topic-pages over nyk-job-application
.nyk-component--job-application {
  .nyk-hero-topic-pages {
    padding-bottom: 6rem;

    @media screen and (max-width: $breakpoint-small) {
      padding-bottom: 2.4rem;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      padding-bottom: 4.4rem;
    }

    .nyk-hero-topic-pages__description.nyk-richtext {
      padding-bottom: 0;
    }
  }
}
