.nyk-job-postings-table {
  &__filter {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2.4rem;

    @media screen and (max-width: $breakpoint-small) {
      grid-template-columns: 1fr;
    }

    &__num-of-results {
      @include bodyText("large");
      font-weight: 700;
    }

    .nyk-form {
      flex: 1;
    }
  }

  &__filter {
    margin-bottom: 2rem;
  }

  .nyk-table {
    margin: 0;

    .nyk-table__container__table__body__row {
      a {
        display: block;
      }
    }
  }
}
