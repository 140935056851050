.nyk-sticky-box {
  position: sticky;
  top: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4.4rem;
  height: fit-content;
  padding: 3.2rem 2.4rem;
  background-color: var(--colortheme-neutral-7);
  border-radius: var(--border-radius);
  transition: 0.3s;

  @media screen and (max-width: $breakpoint-small) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;
  }

  &--scroll {
    @media screen and (max-width: $breakpoint-small) {
      .nyk-sticky-box__mobile {
        bottom: 0;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    &__group {
      &__title {
        @include bodyText("large");
        font-weight: 700;
        margin: 0;
      }

      &__date {
        @include bodyText("large");
        margin: 0;
      }
    }
  }

  &__mobile {
    position: fixed;
    bottom: -10rem;
    left: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 1.6rem 2.4rem;
    background-color: var(--colortheme-neutral-7);
    transition: 0.3s;
  }
}

.nyk-menu-is-sticky {
  .nyk-sticky-box.nyk-sticky-box {
    top: 10rem;

    @media screen and (max-width: $breakpoint-small) {
      top: unset;
    }
  }
}
