.nyk-contact-step-onboarding-content {
  &__header {
    margin-bottom: 4.4rem;

    @media screen and (max-width: $breakpoint-small) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .nyk-signup-step__stepcounter {
        display: block;
      }
    }
  }

  &__inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;

    @media screen and (max-width: $breakpoint-small) {
      gap: 2rem;
    }

    &__input {
      width: calc(50% - 2rem);
      position: relative;

      &--firstname {
        order: 1;
      }

      &--lastname {
        order: 3;
      }

      &--email {
        order: 4;
      }

      &--phone {
        order: 2;
      }

      &--cpr {
        width: 100%;
      }

      @media screen and (max-width: $breakpoint-small) {
        width: 100%;

        &--firstname,
        &--lastname,
        &--email,
        &--phone {
          order: unset;
        }
      }
    }
  }

  &__choices {
    margin-top: 4rem;
    position: relative;

    @media screen and (max-width: $breakpoint-small) {
      margin-top: 2rem;
    }
  }

  &__choice {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: $breakpoint-small) {
      flex-direction: column;
      align-items: flex-start;
    }

    &__tooltip-wrapper {
      display: flex;
      align-items: center;
      gap: 0;
    }

    &__question {
      @include bodyText("large");
    }

    &__inputs {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }

  .nyk-signup-step-actions {
    .nyk-signup-step__stepcounter {
      display: none;
    }
  }

  .nyk-signup-step__summary {
    margin-bottom: 2.4rem;
  }
}
