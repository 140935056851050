.nyk-hero-image-message {
  display: flex;
  flex-direction: column;
  gap: 8rem;
  padding: 8rem 0 6rem 0;

  @media screen and (max-width: $breakpoint-medium) {
    gap: 6rem;
    padding: 6rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    gap: 3rem;
    padding: 4rem 0 3.2rem 0;
  }

  &--desktop-large-7 {
    .nyk-hero-image-message__content__header__title {
      max-width: 58.33%;

      @media screen and (max-width: $breakpoint-x-small) {
        max-width: unset;
      }
    }
  }

  &--desktop-large-10 {
    .nyk-hero-image-message__content__header__title {
      max-width: 83.33%;

      @media screen and (max-width: $breakpoint-x-small) {
        max-width: unset;
      }
    }
  }

  @media screen and (max-width: $breakpoint-medium) {
    &--desktop-6 {
      .nyk-hero-image-message__content__header__title {
        max-width: 50%;

        @media screen and (max-width: $breakpoint-x-small) {
          max-width: unset;
        }
      }
    }

    &--desktop-9 {
      .nyk-hero-image-message__content__header__title {
        max-width: 75%;

        @media screen and (max-width: $breakpoint-x-small) {
          max-width: unset;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-small) {
    &--tablet-5 {
      .nyk-hero-image-message__content__header__title {
        max-width: 62.5%;

        @media screen and (max-width: $breakpoint-x-small) {
          max-width: unset;
        }
      }
    }

    &--tablet-7 {
      .nyk-hero-image-message__content__header__title {
        max-width: 87.5%;

        @media screen and (max-width: $breakpoint-x-small) {
          max-width: unset;
        }
      }
    }
  }

  &--no-description {
    .nyk-hero-image-message__content__header {
      grid-template-areas: "hero-image-message-title hero-image-message-button";

      @media screen and (max-width: $breakpoint-small) {
        grid-template-areas: "hero-image-message-title" "hero-image-message-button";
      }
    }
  }

  &__content {
    max-width: $max-width;

    @media screen and (min-width: $breakpoint-large) {
      margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
      margin: 0 auto;
    }

    @media screen and (max-width: $breakpoint-large) {
      margin: 0 $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
      margin: 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
      margin: 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      margin: 0 $margin-x-small;
    }

    &__header {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-areas: "hero-image-message-title hero-image-message-title" "hero-image-message-description hero-image-message-button";
      column-gap: 17rem;
      row-gap: 8rem;
      align-items: flex-end;

      @media screen and (max-width: $breakpoint-medium) {
        grid-template-columns: 65% auto;
        grid-template-areas:
          "hero-image-message-title hero-image-message-title"
          "hero-image-message-description hero-image-message-button";
        column-gap: 2.4rem;
        row-gap: 5.2rem;
      }

      @media screen and (max-width: $breakpoint-small) {
        grid-template-columns: 1fr;
        grid-template-areas:
          "hero-image-message-title" "hero-image-message-description"
          "hero-image-message-button";
      }

      @media screen and (max-width: $breakpoint-x-small) {
        row-gap: 3rem;
      }

      &__title {
        grid-area: hero-image-message-title;
        @include heading("xx-large");
        max-width: 85%;
        word-break: break-word;

        @media screen and (max-width: $breakpoint-medium) {
          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          -ms-hyphens: auto;
          hyphens: auto;
          width: 100%;
        }

        @media screen and (max-width: $breakpoint-small) {
          word-break: break-word;
          max-width: unset;
        }

        @media screen and (max-width: $breakpoint-x-small) {
          font-size: 4.4rem;
          line-height: 52px;
          font-weight: 400;
        }
      }

      &__description {
        grid-area: hero-image-message-description;
        font-size: $fs-heading-x-small !important;
        line-height: 2.8rem !important;
        font-weight: 400 !important;
        color: var(--colortheme-primary-dark) !important;

        @media screen and (max-width: $breakpoint-small) {
          margin-bottom: 0.8rem !important;
        }

        @media screen and (max-width: $breakpoint-x-small) {
          padding: 1rem 0 0 0;
        }

        &.nyk-richtext {
          margin: 0;

          & > * {
            margin-left: unset;
            width: 100%;
          }
        }

        * {
          font-size: $fs-heading-x-small !important;
          line-height: 2.8rem !important;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &__button {
        grid-area: hero-image-message-button;
        margin-left: auto;
        font-weight: 500;
      }
    }
  }

  &__image-container {
    @media screen and (min-width: $breakpoint-large) {
      margin: 0 2.4rem 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
      margin: 0 calc((100% - calc($max-width + 9.2rem)) / 2) 0 calc((100% - $max-width) / 2);
      max-width: calc($max-width + 9.2rem);
    }

    @media screen and (max-width: $breakpoint-large) {
      margin: 0 2.4rem 0 $margin-large;
    }

    @media screen and (max-width: $breakpoint-medium) {
      margin: 0 1.2rem 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
      padding: 0;
      margin: 0 0.8rem 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      margin: 0 $margin-x-small;
    }

    &__image:is(img) {
      object-fit: cover;
      border-radius: 1.2rem;
      height: 100%;
      width: 100%;
      aspect-ratio: calc(1370 / 770);

      @media screen and (max-width: $breakpoint-medium) {
        aspect-ratio: calc(1150 / 646);
      }

      @media screen and (max-width: $breakpoint-small) {
        aspect-ratio: calc(712 / 400);
      }

      @media screen and (max-width: $breakpoint-x-small) {
        aspect-ratio: calc(361 / 361);
        border-radius: 1.6rem;
      }
    }
  }
}
