.nyk-card-small {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 25.2rem;
  padding: 2.4rem;
  border-radius: var(--border-radius);
  background-color: var(--colortheme-neutral-7);
  overflow: hidden;

  @media screen and (max-width: $breakpoint-small) {
    height: unset;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    gap: 2.4rem;
  }

  &--color-primary {
    background-color: var(--colortheme-primary);

    * :focus-visible {
      outline-color: var(--colortheme-neutral-7);
    }

    .nyk-card-small__header__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    .nyk-card-small__content__text-big,
    .nyk-card-small__content__description,
    .nyk-card-small__content__title {
      color: var(--colortheme-neutral-7);
    }
  }

  &--color-primary-dark {
    background-color: var(--colortheme-primary-dark);

    * :focus-visible {
      outline-color: var(--colortheme-neutral-7);
    }

    .nyk-card-small__header__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    .nyk-card-small__content__text-big,
    .nyk-card-small__content__description,
    .nyk-card-small__content__title {
      color: var(--colortheme-neutral-7);
    }
  }

  &--color-accent-primary {
    background-color: var(--colortheme-accent-primary);

    * :focus-visible {
      outline-color: var(--colortheme-neutral-7);
    }

    .nyk-card-small__header__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    .nyk-card-small__content__text-big,
    .nyk-card-small__content__description,
    .nyk-card-small__content__title {
      color: var(--colortheme-neutral-7);
    }
  }

  &--color-accent-secondary {
    background-color: var(--colortheme-accent-secondary);

    .nyk-card-small__header__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }
  }

  &--color-alfa {
    background-color: var(--colortheme-alfa);

    .nyk-card-small__header__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }
  }

  &--color-beta {
    background-color: var(--colortheme-beta);

    .nyk-card-small__header__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }
  }

  &--color-gamma {
    background-color: var(--colortheme-gamma);

    .nyk-card-small__header__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }
  }

  &--color-delta {
    background-color: var(--colortheme-delta);

    .nyk-card-small__header__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }
  }

  &--color-epsilon {
    background-color: var(--colortheme-epsilon);

    .nyk-card-small__header__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }
  }

  &--color-neutral-2 {
    background-color: var(--colortheme-neutral-2);

    .nyk-card-small__header__link {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }
  }

  &__header {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: $breakpoint-small) {
      align-items: flex-end;
    }
  }

  &__content {

    &__text-big {
      @include heading('xxx-large', $breakpoint-small);
      margin: 0;

      @media screen and (max-width: $custom-card-gallery-breakpoint) {
        font-size: $fs-heading-x-large;
        line-height: $fs-heading-x-large * 1.1;
      }

      @media screen and (max-width: $breakpoint-small) {
        margin-top: 9.6rem;
        font-size: $fs-heading-x-large;
        line-height: $fs-heading-x-large * 1.1;
      }

      @media screen and (max-width: $breakpoint-xx-small) {
        margin-top: 3.2rem;
      }
    }

    &__description {
      @include bodyText('large', $breakpoint-small);
      margin: 0;

      @media screen and (max-width: $custom-card-gallery-breakpoint) {
        @include bodyText('medium');
      }

      @media screen and (max-width: $breakpoint-small) {
        @include bodyText('large');
        margin-bottom: 10.8rem;
      }
    }

    &__title {
      @include heading('medium', $breakpoint-small);
      margin: 0;

      @media screen and (max-width: $custom-card-gallery-breakpoint) {
        @include heading('small');
      }

      @media screen and (max-width: $breakpoint-small) {
        @include heading('medium');
        margin-bottom: 7.6rem;
      }
    }
  }
}