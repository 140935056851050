@keyframes burgermenu-top-close-line-1 {
    0% {
        transform: translateY(0) rotate(0);
    }
    50% {
        transform: translateY(8px) rotate(0);
    }
    100% {
        transform: translateY(8px) rotate(-45deg);
    }
}

@keyframes burgermenu-top-close-line-2 {
    0% {
        transform: translateY(0) rotate(0);
    }
    50% {
        transform: translateY(0) rotate(0);
    }
    100% {
        transform: translateY(0) rotate(-45deg);
    }
}

@keyframes burgermenu-top-close-line-3 {
    0% {
        transform: translateY(0) rotate(0);
    }
    50% {
        transform: translateY(-8px) rotate(0);
    }
    100% {
        transform: translateY(-8px) rotate(45deg);
    }
}


@keyframes burgermenu-top-open-line-1 {
    0% {
        transform: translateY(8px) rotate(-45deg);
    }
    50% {
        transform: translateY(8px) rotate(0);
    }
    100% {
        transform: translateY(0) rotate(0);
    }
}

@keyframes burgermenu-top-open-line-2 {
    0% {
        transform: translateY(0) rotate(-45deg);
    }
    50% {
        transform: translateY(0) rotate(0);
    }
    100% {
        transform: translateY(0) rotate(0);
    }
}

@keyframes burgermenu-top-open-line-3 {
    0% {
        transform: translateY(-8px) rotate(45deg);
    }
    50% {
        transform: translateY(-8px) rotate(0);
    }
    100% {
        transform: translateY(0) rotate(0);
    }
}