.nyk-card-medium {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50rem;
  padding: 2.4rem;
  border-radius: var(--border-radius);
  background-color: var(--colortheme-neutral-7);
  overflow: hidden;

  @media screen and (max-width: $breakpoint-small) {
    height: unset;
  }

  &--transparent {
    height: 50rem;
    padding: 0;
    background-color: transparent;

    @media screen and (max-width: $breakpoint-small) {
      height: unset;

      .nyk-card-medium__header {
        margin-bottom: 3.2rem !important;
      }
    }

    .nyk-card-medium__header__title {
      @include heading("x-large", $breakpoint-small);
    }
  }

  &--image {
    min-height: 50rem;
    padding: 0;

    & img {
      border-radius: 1.6rem;
    }

    @media screen and (max-width: $breakpoint-small) {
      min-height: 45rem;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      min-height: 36rem;
    }

    .nyk-card-medium__footer {
      position: absolute;
      right: 2.4rem;
      bottom: 2.4rem;
      margin-left: 2.4rem;
    }
  }

  &--graphic {
    height: 50rem;
    padding: 0;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $breakpoint-small) {
      height: 43rem;
    }

    @media screen and (max-width: $breakpoint-x-small) {
      height: 36rem;
    }

    svg {
      width: 100%;
    }
  }

  &--spacer {
    background-color: transparent;

    @media screen and (max-width: $breakpoint-small) {
      display: none;
    }
  }

  &--color-primary {
    background-color: var(--colortheme-primary);

    * :focus-visible {
      outline-color: var(--colortheme-neutral-7);
    }

    .nyk-card-medium__footer__link__button {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    .nyk-card-medium__header__title,
    .nyk-card-medium__content__description,
    .nyk-card-medium__footer__link {
      color: var(--colortheme-neutral-7);
    }

    &.nyk-card-medium--graphic {
      svg {
        path {
          fill: var(--colortheme-neutral-7);
        }
      }
    }
  }

  &--color-primary-dark {
    background-color: var(--colortheme-primary-dark);

    * :focus-visible {
      outline-color: var(--colortheme-neutral-7);
    }

    .nyk-card-medium__footer__link__button {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    .nyk-card-medium__header__title,
    .nyk-card-medium__content__description,
    .nyk-card-medium__footer__link {
      color: var(--colortheme-neutral-7);
    }

    &.nyk-card-medium--graphic {
      svg {
        path {
          fill: var(--colortheme-neutral-7);
        }
      }
    }
  }

  &--color-accent-primary {
    background-color: var(--colortheme-accent-primary);

    * :focus-visible {
      outline-color: var(--colortheme-neutral-7);
    }

    .nyk-card-medium__footer__link__button {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    .nyk-card-medium__header__title,
    .nyk-card-medium__content__description,
    .nyk-card-medium__footer__link {
      color: var(--colortheme-neutral-7);
    }

    &.nyk-card-medium--graphic {
      svg {
        path {
          fill: var(--colortheme-neutral-7);
        }
      }
    }
  }

  &--color-accent-secondary {
    background-color: var(--colortheme-accent-secondary);

    .nyk-card-medium__footer__link__button {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    &.nyk-card-medium--graphic {
      svg {
        path {
          fill: var(--colortheme-primary);
        }
      }
    }
  }

  &--color-alfa {
    background-color: var(--colortheme-alfa);

    .nyk-card-medium__footer__link__button {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    &.nyk-card-medium--graphic {
      svg {
        path {
          fill: var(--colortheme-primary);
        }
      }
    }
  }

  &--color-beta {
    background-color: var(--colortheme-beta);

    .nyk-card-medium__footer__link__button {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    &.nyk-card-medium--graphic {
      svg {
        path {
          fill: var(--colortheme-primary);
        }
      }
    }
  }

  &--color-gamma {
    background-color: var(--colortheme-gamma);

    .nyk-card-medium__footer__link__button {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    &.nyk-card-medium--graphic {
      svg {
        path {
          fill: var(--colortheme-primary);
        }
      }
    }
  }

  &--color-delta {
    background-color: var(--colortheme-delta);

    .nyk-card-medium__footer__link__button {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    &.nyk-card-medium--graphic {
      svg {
        path {
          fill: var(--colortheme-primary);
        }
      }
    }
  }

  &--color-epsilon {
    background-color: var(--colortheme-epsilon);

    .nyk-card-medium__footer__link__button {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    &.nyk-card-medium--graphic {
      svg {
        path {
          fill: var(--colortheme-primary);
        }
      }
    }
  }

  &--color-neutral-2 {
    background-color: var(--colortheme-neutral-2);

    .nyk-card-medium__footer__link__button {
      background-color: var(--colortheme-neutral-7);
      border-color: var(--colortheme-neutral-7);
    }

    &.nyk-card-medium--graphic {
      svg {
        path {
          fill: var(--colortheme-primary);
        }
      }
    }
  }

  &__header {
    @media screen and (max-width: $breakpoint-small) {
      margin-bottom: 8rem;
    }

    &__title {
      @include heading("medium");
      word-break: break-word;

      @media screen and (max-width: $custom-card-gallery-breakpoint) {
        @include heading("small");
      }

      @media screen and (max-width: $breakpoint-small) {
        @include heading("medium");
      }
    }
  }

  &__content {
    margin-top: auto;

    @media screen and (max-width: $breakpoint-small) {
      margin-bottom: 3.6rem;
    }

    &__description {
      @include bodyText("large");

      @media screen and (max-width: $custom-card-gallery-breakpoint) {
        @include bodyText("medium");
      }

      @media screen and (max-width: $breakpoint-small) {
        @include bodyText("large");
      }

      &:is(p) {
        margin: 0;
      }

      & + .nyk-card-medium__content__text-big {
        margin-top: 1.2rem;
      }
    }

    &__text-big {
      @include heading("medium");

      a {
        color: var(--colortheme-primary-dark);
      }
    }
  }

  &__footer {
    margin-top: 4.8rem;

    @media screen and (max-width: $breakpoint-small) {
      margin-top: 0;
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      @include bodyText("medium");
      font-weight: 500;
    }
  }

  &__bg-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
