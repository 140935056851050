.nyk-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;

  &--inline {
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 100%;
    .nyk-loader__content {
      width: 100%;
      height: 100%;
    }
    .nyk-loader__content__circle {
      width: 100%;
      height: 100%;
      border-color: transparent;
      border: none;
      &:after {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

  &--size-small {
    .nyk-loader__content__circle {
      &:after {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  &--color-primary-dark {
    .nyk-loader__content__circle {
      &:after {
        border-right-color: var(--colortheme-alpha);
      }
    }
  }

  &__content {
    &__circle {
      position: relative;
      width: 12.6rem;
      height: 12.6rem;
      border-radius: 12.6rem;
      border: 0.8rem solid var(--colortheme-neutral-7);

      &::after {
        content: "";
        position: absolute;
        top: -0.8rem;
        left: -0.8rem;
        width: calc(100% + 1.6rem);
        height: calc(100% + 1.6rem);
        border-radius: 12.6rem;
        border: 0.8rem solid var(--colortheme-delta);
        border-top: 0.8rem solid transparent;
        border-left: 0.8rem solid transparent;
        border-bottom: 0.8rem solid transparent;
        transform: rotate(0deg);
        animation: spin 1s cubic-bezier(0.68, 0.13, 0.24, 1) infinite;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(315deg);
  }
}
