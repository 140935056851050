.nyk-section-block__content {
  .nyk-richtext {
    margin: 0;
    padding: 0 0 2.8rem;

    &.nyk-table__container {
      padding-bottom: 0;
    }

    & > * {
      margin-left: unset;
      width: 100%;
    }
  }

  .nyk-image {
    margin: 0;
    padding: 0 0 2.8rem 0;

    &__content {
      width: 100%;
      max-width: unset;
    }
  }

  .nyk-video {
    padding: 0 0 2.8rem 0;
    margin: 0;
    width: 100%;

    &__content {
      width: 100%;
      max-width: unset;
    }
  }

  .nyk-button-container {
    margin: 0;
    padding: 0;
    justify-content: flex-start;
  }

  small {
    @include bodyText(x-small, $breakpoint-small);
  }

  .nyk-accordion-container {
    padding: 0 0 2.8rem 0;
    flex-direction: column;
    margin: 0;
  }

  .nyk-accordion-container__title {
    width: 100%;
    margin-bottom: 3.2rem;
    padding-top: 0;
  }

  .nyk-accordion {
    flex: unset;
    width: 100%;
    padding: 0;

    & + .nyk-accordion-container__title {
      margin-top: 6.4rem;
    }
  }

  .nyk-table {
    margin: 0;
    padding: 0 0 2.8rem 0;

    &__header {
      flex-direction: column;
      gap: 3.2rem;
    }
  }

  .nyk-quote {
    margin: 0;
    padding: 0 0 2.8rem 0;

    &__content {
      width: 100%;
    }
  }
  .nyk-form-container {
    margin: 0;
  }

  .nyk-signup-step {
    padding: 0;
    margin: 0 0 2.8rem 0;

    &__content {
      width: 100%;
    }
  }
}
