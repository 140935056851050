.nyk-new-loan-calculator-result {
    max-width: $max-width;
    margin: 0 auto;
    padding: 4rem 0 0 0;

    @media screen and (min-width: $breakpoint-large) {
        margin: 0 $margin-x-large;
    }

    @media screen and (min-width: $breakpoint-computed-x-large) {
        max-width: $max-width;
        width: 100%;
        margin: 0 auto;
    }

    @media screen and (max-width: $breakpoint-large) {
        padding: 4rem $margin-large 0 $margin-large;
    }

    @media screen and (max-width: $custom-card-gallery-breakpoint) {
        display: flex;
        flex-direction: column;
    }

    @media screen and (max-width: $breakpoint-medium) {
        padding: 4rem $margin-medium 0 $margin-medium;
    }

    @media screen and (max-width: $breakpoint-small) {
        padding: 3.2rem 2.4rem 0 2.4rem;
    }

    @media screen and (max-width: $breakpoint-x-small) {
        padding: 3.2rem $margin-x-small 0 $margin-x-small;
    }

    &--compare {
        .nyk-table__container__table {
            &__body__row {
                @media screen and (max-width: $breakpoint-small) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.8rem;
                    padding: 1.6rem 0;

                    &__item.nyk-table__container__table__body__row__item {
                        @media screen and (max-width: $breakpoint-small) {
                            padding: 0 0 0 0.8rem;
                        }

                        * {
                            margin: 0;

                            @media screen and (max-width: $breakpoint-small) {
                                @include bodyText('medium');
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }

        .nyk-button {

            @media screen and (max-width: $breakpoint-xs-custom-small) {
                font-size: $fs-body-small;
                line-height: $fs-body-small;
            }
        }

        .nyk-calculator-table__button {
            @media screen and (max-width: $breakpoint-small) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.8rem;
                padding: 1.6rem 0.8rem;
                @include bodyText('medium');
    
                &-value {
                    text-align: left;
                }
            }
        }
    }

    .nyk-send-calculation {
        max-width: 80rem;
        margin-top: 4rem;
        margin-right: 0;

        @media screen and (max-width: $custom-card-gallery-breakpoint) {
            margin-top: 0;
            margin-bottom: 6.4rem;

            .nyk-send-calculation__content__info__description__label {
                @include heading('small');
            }
        }
    }

    .nyk-contact-card {
        @media screen and (max-width: $custom-card-gallery-breakpoint) {
            order: 2;
        }
    }

    .nyk-signup-step {
        margin: 0;
        padding-top: 0;
        margin-top: 4rem;
        margin-left: auto;
        width: 100%;
        max-width: 100.54rem;

        @media screen and (max-width: $custom-card-gallery-breakpoint) {
            margin-top: 0;
        }
    }

    &__send-calc {
        @media screen and (max-width: $custom-card-gallery-breakpoint) {
            display: none;
        }
    }

    &__back-button {
        background-color: transparent;
        border: none;
        display: flex;
        width: fit-content;
        align-items: center;
        gap: 0.8rem;
        margin-bottom: 4rem;
        cursor: pointer;

        &-icon {
            position: relative;
            width: 4.4rem;
            height: 4.4rem;
            border-radius: 50%;
            background-color: var(--colortheme-neutral-7);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__title {
        @include heading('medium');
        margin-bottom: 4rem;

        @media screen and (max-width: $breakpoint-small) {
            @include heading('small');
            margin-bottom: 3.2rem;
        }
    }

    &__info-container {
        display: grid;
        grid-template-columns: auto minmax(auto, 41.6rem);
        grid-template-areas:
            'calcSummary calcInfo'
            'calcActions calcActions';
        column-gap: $margin-x-small;
        row-gap: 4rem;
        margin-bottom: 4rem;

        @media screen and (max-width: $custom-card-gallery-breakpoint) {
            grid-template-columns: 1fr;
            grid-template-areas:
                'calcSummary'
                'calcActions'
                'calcInfo';

            column-gap: 3.2rem;
            row-gap: 3.2rem;
            order: 1;
            margin-bottom: 3.2rem;
        }

        .nyk-calculator-summary {
            grid-area: calcSummary;
        }

        .nyk-calculator-info-box {
            grid-area: calcInfo;
        }

        &--multiple {
            display: flex;
            flex-wrap: wrap;
            row-gap: 4rem;
            column-gap: $margin-x-small;
            margin-bottom: 6rem;

            @media screen and (max-width: $custom-card-gallery-breakpoint) {
                flex-direction: row;
                column-gap: $margin-x-small;
            }


            @media screen and (max-width: 350px) { // custom breakpoint
                flex-direction: column;
            }

            .nyk-new-loan-calculator-result__info-container-wrapper {
                position: relative;
                width: calc(50% - 0.8rem);
                display: flex;
                flex-direction: column;
                gap: 4rem;

                @media screen and (max-width: $custom-card-gallery-breakpoint) {
                    gap: 3.2rem;
                }

                @media screen and (max-width: 350px) { // custom breakpoint
                    width: 100%;
                }

                .nyk-button {
                    margin-left: auto;

                    @media screen and (max-width: $breakpoint-small) {
                        margin: 0;
                    }
                }
            }
        }

        &-actions {
            grid-area: calcActions;
            margin-left: auto;
            display: flex;
            gap: 1.2rem;

            @media screen and (max-width: $custom-card-gallery-breakpoint) {
                justify-content: space-between;
                width: 100%;
            }
        }

        &__skip-link {
            position: absolute;
            bottom: -4rem;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            width: 0;
            padding: 0;
            height: 0;

            @media screen and (max-width: $breakpoint-x-small) {
                background-color: var(--colortheme-neutral-7);
            }

            &:focus {
                width: auto;
                height: auto;
                padding: 0.8rem;
                opacity: 1;
            }
        }
    }

    .nyk-button {
        @media screen and (max-width: $breakpoint-xs-custom-small) {
            font-size: $fs-body-small;
            line-height: $fs-body-small;
        }
    }

    .nyk-button--send-calc.nyk-button {
        @media screen and (max-width: $custom-card-gallery-breakpoint) {
            display: none;
        }
    }

    &__table-sections {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 6.4rem;

        @media screen and (max-width: $custom-card-gallery-breakpoint) {
            order: 3;
        }

        @media screen and (max-width: $breakpoint-small) {
            gap: $margin-x-small;
        }

        @media screen and (max-width: 350px) { //custom breakpoint
            flex-direction: column;
            gap: 3.2rem;
        }

        &__content {
            width: 100%;

            &-title {
                @include heading('medium');
                word-break: break-word;

                @media screen and (max-width: $breakpoint-small) {
                    @include heading('small');
                }

                @media screen and (max-width: $breakpoint-xx-small) {
                    font-size: 2rem;
                }

                &:not(:first-child) {
                    margin-top: 12rem;

                    @media screen and (max-width: $breakpoint-small) {
                        margin-top: 6.4rem;
                    }
                }
            }

            .nyk-calculator-table {
                margin-bottom: 6rem;

                @media screen and (max-width: $breakpoint-small) {
                    margin-bottom: 3.2rem;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
