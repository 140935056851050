.nyk-visually-hidden {
    border: 0px;
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0px;
    position: absolute;
    width: 1px;
}

.nyk-visibility-hidden {
    visibility: hidden;
}