.nyk-skip-navigation {
    position: absolute;
    top: -15rem;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f6f5;
    padding: 4.6rem 0;
    width: 100%;
    transition: 0.3s;

    &:focus-within {
        top: 0;
        z-index: $submenudropdownOverlay;
    }

    .nyk-button {
        &:focus {
            background-color: transparent;
            color: var(--colortheme-primary-dark);
        }
    }
}
