.nyk-info-box {
  padding: 6rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &--right {
    .nyk-info-box__content {
      margin-left: auto;
    }
  }

  &--color-alfa {
    .nyk-info-box__content {
      background-color: var(--colortheme-alfa);
    }
  }

  &--color-beta {
    .nyk-info-box__content {
      background-color: var(--colortheme-beta);
    }
  }

  &--color-gamma {
    .nyk-info-box__content {
      background-color: var(--colortheme-gamma);
    }
  }

  &--color-delta {
    .nyk-info-box__content {
      background-color: var(--colortheme-delta);
    }
  }

  &--color-epsilon {

    * :focus-visible {
      outline-color: var(--colortheme-neutral-7);
    }

    .nyk-info-box__content {
      background-color: var(--colortheme-epsilon);

      &__description {
        color: var(--colortheme-neutral-7);
      }

      &__action__name {
        color: var(--colortheme-neutral-7);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3.2rem 2.4rem;
    margin: 6rem 0;
    border-radius: var(--border-radius);
    height: calc(50rem - 6.4rem);
    width: calc($column-aside-big - 4.8rem);
    background-color: var(--colortheme-delta);

    @media screen and (max-width: $breakpoint-small) {
      width: calc(100% - 4.8rem);
      height: unset;
    }

    &__description {
      @include heading("medium");

      @media screen and (max-width: $breakpoint-small) {
        @include heading("small");
      }

      & p {
        margin: 0;
      }
    }

    &__action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 2.4rem;
      text-decoration: none;

      @media screen and (max-width: $breakpoint-small) {
        margin-top: 10.6rem;
      }

      &__name {
        @include bodyText("medium");
      }

      &__button {
        background-color: var(--colortheme-neutral-7) !important;
        border-color: var(--colortheme-neutral-7) !important;
      }
    }
  }
}
