.nyk-newsletters-signup {
  margin: 0 auto;

  @media screen and (min-width: $breakpoint-computed-x-large) {
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }
}