.nyk-chat-message {
  margin: 0.8rem 0;
  display: flex;
  &:has(.nyk-chat-message__message-link) {
    margin: 1.2rem 0;
  }
  &:last-child {
    margin: 1.2rem 0 0;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    align-items: flex-start;
    padding: 1.6rem;
    border-radius: 1.6rem;

    &--user {
      margin-left: auto;
      color: #ffffff;
      background-color: var(--colortheme-primary);
    }
    &--bot {
      background-color: var(--colortheme-neutral-2);
    }
    &--links {
      padding: 0;
      background-color: transparent;
    }
    &--status-message {
      width: 100%;
      background-color: transparent;
      position: relative;
      &:before {
        content: '';
        width: 5rem;
        left: 2.4rem;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        border-top: 1px solid #E6E1DB;
        height: 1px;
      }
      &:after {
        content: '';
        width: 5rem;
        right: 2.4rem;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        border-top: 1px solid #E6E1DB;
        height: 1px;
      }
      .nyk-chat-message__message-status {
        text-align: center;
        width: 100%;
        padding: 0 6.2rem;
        margin: 0;
      }
    }
  }
  &__message-html,
  &__message-status {
    > * {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    > ul {
      margin-left: 1.6rem;
    }
  }
  &__message-html {
    ul {
      list-style-type: none !important;
      padding-left: 0;
      margin: 0 0 1.6rem 0;
    }
  
    li {
      position: relative;
      padding-bottom: 0.6rem;
      padding-left: 1.6rem;
      margin: 0;
      &:last-child {
        padding-bottom: 0;
      }
    }
  
    li::marker {
      content: "";
    }
  
    li:before {
      position: absolute;
      top: 0.8rem;
      left: 0;
      content: "";
      display: inline-block;
      margin-bottom: 2.5px;
      width: 6px;
      height: 6px;
      background-color: var(--colortheme-accent-primary);
      border-radius: 1px;
    }
  }
  &__external-link {
    padding-right: 4rem;
    .nyk-button__icon {
      > svg {
        path {
          fill: none;
        }
      }
    }
    &:hover {
      .nyk-button__icon {
        right: 1.6rem;
        > svg {
          path {
            transition: 0.3s;
            fill: none;
            stroke: white;
          }
        }
      }
    }
  }
}
