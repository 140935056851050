.nyk-hero-3-cards {
    background-color: var(--colortheme-bg-color);

    &--desktop-8 {
        .nyk-hero-3-cards__image-container__content__title {
            width: $column-aside-big;
        }
    }

    &--desktop-7 {
        .nyk-hero-3-cards__image-container__content__title {
            width: 58.3333333333%;
        }
    }

    &--desktop-6 {
        .nyk-hero-3-cards__image-container__content__title {
            width: 50%;
        }
    }

    &--desktop-5 {
        .nyk-hero-3-cards__image-container__content__title {
            width: 41.6666666667%;
        }
    }

    &--color-primary {
        .nyk-hero-3-cards__image-container__content__title {
            color: var(--colortheme-primary-dark);
        }

        .nyk-hero-3-cards__image-container__content__button {
            border-color: var(--colortheme-primary-dark);
            color: var(--colortheme-primary-dark);

            &:hover,
            &:focus {
                background-color: var(--colortheme-primary-dark);
                border-color: var(--colortheme-primary-dark);
                box-shadow: inset 0 0 0 1px var(--colortheme-primary-dark);
                color: var(--colortheme-neutral-7);

                svg path {
                    fill: var(--colortheme-neutral-7);
                }
            }

            svg path {
                fill: var(--colortheme-primary-dark);
            }
        }
    }

    @media screen and (max-width: $breakpoint-small) {
        &--tablet-7 {
            .nyk-hero-3-cards__image-container__content__title {
                width: 87.5%;
            }
        }

        &--tablet-5 {
            .nyk-hero-3-cards__image-container__content__title {
                width: 62.5%;
            }
        }
    }

    @media screen and (max-width: $breakpoint-x-small) {
        .nyk-hero-3-cards__image-container__content__title {
            width: unset !important;
        }
    }

    &__image-container {
        position: relative;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $breakpoint-large) {
            aspect-ratio: calc(1728 / 793);
        }

        @media screen and (max-width: $breakpoint-large) {
            aspect-ratio: calc(1439 / 660);
        }

        @media screen and (max-width: $breakpoint-medium) {
            aspect-ratio: calc(1239 / 569);
        }

        @media screen and (max-width: $breakpoint-small) {
            aspect-ratio: calc(904 / 815);
        }

        @media screen and (max-width: $breakpoint-x-small) {
            aspect-ratio: calc(599 / 860);
        }

        &__image {
            height: 100%;
            width: 100%;
            object-fit: cover;
            position: absolute;
            z-index: 1;
            border-radius: 0 !important;
        }

        &__content {
            max-width: $max-width;
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            z-index: 2;
            padding-left: 2.4rem;

            @media screen and (min-width: $breakpoint-large) {
                margin: 0 $margin-x-large 16rem;
            }

            @media screen and (min-width: $breakpoint-computed-x-large) {
                margin: 0 auto 14rem;
                max-width: $max-width;
            }

            @media screen and (max-width: $breakpoint-large) {
                margin: 0 $margin-large 16rem;
            }

            @media screen and (max-width: $breakpoint-medium) {
                margin: 0 $margin-medium 14rem;
            }

            @media screen and (max-width: $breakpoint-small) {
              margin-bottom: 18rem;
            }

            @media screen and (max-width: $breakpoint-x-small) {
                margin: 0 $margin-x-small 18rem;
                padding-left: 0;
            }

            &__title {
                @include heading("xxx-large", $breakpoint-medium);
                color: var(--colortheme-neutral-7);
                margin-bottom: 4.4rem;
                padding-top: 2.4rem;

                @media screen and (max-width: $breakpoint-small) {
                    font-size: 5.6rem;
                    line-height: 6.8rem;
                }

                @media screen and (max-width: $breakpoint-x-small) {
                    font-size: 4.4rem;
                    line-height: 5.2rem;
                }
            }

            &__button {
                
            }
        }
    }

    &__cards {
        max-width: $max-width;
        position: relative;
        z-index: 2;
        display: flex;
        column-gap: 1.6rem;
        padding-bottom: 6rem;

        @media screen and (min-width: $breakpoint-large) {
            margin: -6rem $margin-x-large 0;
        }

        @media screen and (min-width: $breakpoint-computed-x-large) {
            margin: -6rem auto 0;
            max-width: $max-width;
        }

        @media screen and (max-width: $breakpoint-large) {
            margin: -6rem $margin-large 0;
        }

        @media screen and (max-width: $breakpoint-medium) {
            margin: -6rem $margin-medium 0;
        }

        @media screen and (max-width: $breakpoint-small) {
            flex-direction: column;
            row-gap: 1.6rem;
            padding-bottom: 3.2rem;
        }

        @media screen and (max-width: $breakpoint-x-small) {
            margin: -6rem $margin-x-small 0;
        }

        &__card {
            padding: $margin-medium;
            border-radius: var(--border-radius);
            background-color: var(--colortheme-neutral-7);
            flex: 0.33;
            display: flex;
            flex-direction: column;

            &__title {
                font-size: 1.6rem;
                color: var(--colortheme-primary-dark);
                font-weight: 700;
                line-height: 2.4rem;
                margin: 0;
                margin-bottom: 1.6rem;

                @media screen and (max-width: $breakpoint-medium) {
                    margin-bottom: 1.2rem;
                }

                @media screen and (max-width: $breakpoint-small) {
                    margin-bottom: 0.8rem;
                    font-size: 1.8rem;
                    line-height: 2.8rem;
                }
            }

            &__body {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 4.8rem;

                &__description {
                    font-size: $fs-body-medium !important;
                    line-height: $fs-body-medium * 1.5 !important;
                    font-weight: 400 !important;
                    color: var(--colortheme-primary-dark) !important;

                    @media screen and (max-width: $breakpoint-small) {
                        font-size: $fs-body-medium !important;
                        line-height: $fs-body-medium * 1.5 !important;
                    }

                    &.nyk-richtext {
                        margin: 0;

                        & > * {
                            margin-left: unset;
                            width: 100%;
                        }
                    }

                    & > * {
                        font-size: $fs-body-medium !important;
                        line-height: $fs-body-medium * 1.5 !important;
                        font-weight: 400 !important;
                        color: var(--colortheme-primary-dark) !important;
                        margin: 0 !important;
                        word-break: break-word !important;

                        @media screen and (max-width: $breakpoint-small) {
                            font-size: $fs-body-medium !important;
                            line-height: $fs-body-medium * 1.5 !important;
                        }
                    }
                }

                &__footer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-decoration: none;

                    @media screen and (max-width: $breakpoint-medium) {
                        margin-bottom: -0.8rem;
                        margin-right: -0.8rem;
                    }

                    @media screen and (max-width: $breakpoint-small) {
                        margin-bottom: 0;
                        margin-right: 0;
                    }

                    &__text {
                        font-size: 1.6rem;
                        color: var(--colortheme-primary-dark);
                        font-weight: 500;
                        line-height: 2.4rem;

                        @media screen and (max-width: $breakpoint-small) {
                            font-weight: 700;
                        }

                        @media screen and (max-width: $breakpoint-x-small) {
                            font-weight: 500;
                        }
                    }
                }

                @media screen and (max-width: $breakpoint-small) {
                    gap: 2rem;
                }

                @media screen and (max-width: $breakpoint-x-small) {
                    margin: 3.2 $margin-x-small;
                }
            }
        }
    }
}
