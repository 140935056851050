.nyk-menu {
    position: relative;
    background-color: var(--colortheme-neutral-7);
    z-index: $menuZIndex;

    &--scrolling {
        &.nyk-menu--submenu-open {
            .nyk-menu__submenu {
                position: fixed;
                height: calc(100vh - 8.7rem);
            }

            .nyk-menu__top-menu__bottom {
                position: fixed;
                margin-top: 0;
                top: 0;
            }
        }

        .nyk-menu__top-menu__bottom {
            position: fixed;
            top: -8.8rem;
        }
    }

    &--sticky-top {
        .nyk-menu__top-menu__bottom {
            position: fixed;
            margin-top: 0;
            top: 0;
        }

        &.nyk-menu--submenu-open {
            .nyk-menu__submenu {
                position: fixed;
                top: 8.7rem;
                height: calc(100vh - 8.7rem);

                @media screen and (max-width: $breakpoint-small) {
                    top: 0;
                    height: 100vh;
                }
            }

            .nyk-menu__top-menu__bottom {
                position: fixed;
                margin-top: 0;
                top: 0;
            }
        }
    }

    &--submenu-open {
        .nyk-menu__submenu {
            display: flex;
            top: 8.7rem;
            height: calc(100vh - 8.7rem);
            overflow-y: auto;

            @media screen and (max-width: $breakpoint-medium) {
                height: calc(100vh - 8.7rem);
            }

            @media screen and (max-width: $breakpoint-small) {
                height: 100vh;
                top: 0;
            }
        }

        &.nyk-menu--with-topnav {
            .nyk-menu__submenu {
                display: flex;
                top: 13.6rem;
                height: calc(100vh - 13.6rem);
                overflow-y: auto;

                @media screen and (max-width: $breakpoint-medium) {
                    top: 8.7rem;
                    height: calc(100vh - 8.7rem);
                }

                @media screen and (max-width: $breakpoint-small) {
                    height: 100vh;
                    top: 0;
                }
            }

            &.nyk-menu--sticky-top {
                .nyk-menu__submenu {
                    top: 8.7rem;
                    height: calc(100vh - 8.7rem);

                    @media screen and (max-width: $breakpoint-small) {
                        height: 100vh;
                        top: 0;
                    }
                }
            }
        }

        @media screen and (min-width: $breakpoint-medium) {
            .nyk-menu__submenu__content__left__cards__card--yellow {
                margin-bottom: 2.4rem;
            }
        }

        .nyk-menu__top-menu__bottom {
            @media screen and (max-width: $breakpoint-small) {
                z-index: 1000;
                background-color: transparent;
                right: $margin-medium;
                top: 0;
                width: 4.4rem;
                position: absolute;

                .nyk-menu__top-menu__bottom__container__logo {
                    display: none;
                }

                .nyk-menu__top-menu__bottom__container__actions {
                    top: 2.4rem;
                    right: $margin-medium;
                    position: absolute;

                    .nyk-button-login {
                        display: none;
                    }
                }
            }

            @media screen and (max-width: $breakpoint-x-small) {
                right: $margin-x-small;

                .nyk-menu__top-menu__bottom__container__actions {
                    right: $margin-x-small;
                }
            }
        }

        &.nyk-menu--scrolling {
            .nyk-menu__submenu {
                position: fixed;
                top: 8.7rem;

                @media screen and (max-width: $breakpoint-small) {
                    top: 0;
                }
            }
        }
    }

    &__top-menu {
        position: relative;
        padding-bottom: 8.8rem;

        &__top {
            height: 4.8rem;
            display: flex;
            justify-content: space-between;

            &::after {
                content: "";
                position: absolute;
                border-bottom: 1px solid var(--colortheme-neutral-3);
                width: 100%;
                left: 0;
                top: 4.8rem;
            }

            @media screen and (min-width: $breakpoint-large) {
                padding: 0 $margin-x-large;
            }

            @media screen and (min-width: $breakpoint-computed-x-large) {
                margin: 0 auto;
                max-width: $max-width;
                padding: 0;
            }

            @media screen and (max-width: $breakpoint-large) {
                padding: 0 $margin-large;
            }

            @media screen and (max-width: $breakpoint-medium) {
                display:  none;
            }

            @media screen and (max-width: $breakpoint-x-small) {
                padding: 0 $margin-x-small;
            }

            &__menu-items {
                display: flex;
                align-items: center;
                gap: 3.2rem;
                list-style: none;
                margin: 0;
                padding-left: 0;

                &__item {
                    position: relative;
                    @include bodyText("small");

                    &--dropdown {
                        display: flex;
                        align-items: center;
                        gap: 1.2rem;
                    }

                    &__link {
                        text-decoration: none;
                        color: var(--colortheme-primary-dark);
                        border-bottom: 1px solid transparent;
                        transition: 0.2s;

                        &:hover {
                            border-bottom: 1px solid;
                            border-color: var(--colortheme-netrual-3);
                        }

                        &--active {
                            border-bottom: 1px solid;
                            border-color: var(--colortheme-netrual-3);
                        }
                    }

                    &__button {
                        display: flex;
                        align-items: center;
                        gap: 1.2rem;
                        padding: 0;
                        border: none;
                        border-bottom: 1px solid transparent;
                        background-color: transparent;
                        color: var(--colortheme-primary-dark);
                        cursor: pointer;

                        &:hover {
                            border-bottom: 1px solid;
                            border-color: var(--colortheme-netrual-3);
                        }

                        &:focus-visible {
                            outline: 2px solid;
                            outline-color: var(--colortheme-primary-dark);
                        }

                        &[aria-expanded="true"] {
                            svg {
                                transform: rotate(180deg);
                            }
                        }

                        svg {
                            height: 1.2rem;
                            width: 1.2rem;
                        }
                    }

                    &__dropdown {
                        position: absolute;
                        top: 3.4rem;
                        left: -2.4rem; // magic number to place dropdown based on design
                        display: none;
                        padding: 3.2rem 2.4rem;
                        border-radius: 0.8rem;
                        background-color: var(--colortheme-neutral-7);
                        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
                        z-index: $menuTopLevelDropdwon;

                        &--shown {
                            display: block;
                        }

                        &__list {
                            list-style: none;
                            padding-left: 0;
                            display: flex;
                            flex-direction: column;
                            gap: 1.2rem;

                            &__item {
                                @include bodyText("small");

                                &__link {
                                    white-space: nowrap;
                                    text-decoration: none;
                                    color: var(--colortheme-primary-dark);
                                    border-bottom: 1px solid transparent;
                                    transition: 0.2s;

                                    &:hover {
                                        border-bottom: 1px solid;
                                        border-color: var(--colortheme-netrual-3);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &__bottom {
            margin-top: 0.1rem;
            position: absolute;
            height: 8.7rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: var(--colortheme-neutral-7);
            transition: top 0.3s;
            -webkit-transition: top 0.3s;
            -moz-transition: top 0.3s;
            -o-transition: top 0.3s;
            width: 100%;

            @media screen and (min-width: $breakpoint-large) {
                padding: 0 $margin-x-large;
            }

            @media screen and (min-width: $breakpoint-computed-x-large) {
                margin: 0 auto;
                margin-top: 0.1rem;
            }

            @media screen and (max-width: $breakpoint-large) {
                padding: 0 $margin-large;
            }

            @media screen and (max-width: $breakpoint-medium) {
                padding: 0 $margin-medium;
            }

            @media screen and (max-width: $breakpoint-x-small) {
                padding: 0 $margin-x-small;
            }

            &__container {
                display: grid;
                align-items: center;
                justify-content: space-between;
                grid-template-columns: 1fr 5fr 2fr;
                gap: 4.4rem;
                width: 100%;
                max-width: $max-width;
                margin: 0 auto;

                @media screen and (max-width: $breakpoint-xx-small) {
                    gap: 1rem;
                }

                @media screen and (max-width: $breakpoint-medium) {
                    grid-template-columns: 1fr 3fr;
                }

                &__logo {
                    display: flex;
                    align-items: center;

                    img,
                    svg {
                        flex: 1;
                        min-width: 13.6rem;
                        max-width: 13.6rem;
                        border-radius: 0;

                        @media screen and (max-width: $breakpoint-x-small) {
                            max-width: 14.3rem;
                        }

                        @media screen and (max-width: $breakpoint-x-small) {
                            width: 13.6rem;
                        }
                    }
                }

                &__menu-items {
                    display: flex;
                    align-items: center;
                    gap: 4.8rem;
                    list-style: none;
                    font-size: 1.4rem;
                    padding: 0 0 0 4.8rem;

                    @media screen and (max-width: $breakpoint-large) {
                        gap: 3.2rem;
                    }

                    @media screen and (max-width: $breakpoint-medium) {
                        padding: 0 $margin-medium;
                        width: unset;
                        display: none;
                    }

                    &__item {
                        flex-shrink: 1;
                        &__link {
                            @include bodyText("medium");
                            text-decoration: none;
                            border: none;
                            padding: 0;
                            border-bottom: 1px solid transparent;
                            transition: 0.2s;
                            background-color: transparent;
                            cursor: pointer;

                            &:hover {
                                border-bottom: 1px solid;
                                border-color: var(--colortheme-netrual-3);
                            }

                            &:focus-visible {
                                outline: 2px solid;
                                outline-color: var(--colortheme-primary-dark);
                            }

                            &--active {
                                border-bottom: 1px solid;
                                border-color: var(--colortheme-netrual-3);
                            }

                            &[aria-expanded="true"] {
                                border-bottom: 1px solid;
                                border-color: var(--colortheme-netrual-3);
                            }
                        }
                    }
                }

                &__actions {
                    display: flex;
                    align-items: center;
                    gap: 3.2rem;
                    justify-content: flex-end;
                    justify-self: flex-end;
                    width: fit-content;

                    @media screen and (max-width: $breakpoint-x-small) {
                        gap: 1.6rem;
                    }

                    &__search {
                        @include bodyText("medium");
                        display: flex;
                        align-items: center;
                        gap: 1.2rem;
                        padding: 0;
                        background-color: transparent;
                        border: none;
                        cursor: pointer;
                        border-bottom: 1px solid transparent;
                        transition: 0.2s;

                        @media screen and (max-width: $breakpoint-small) {
                            display: none;
                        }

                        &:hover {
                            border-bottom: 1px solid var(--colortheme-primary-dark);
                        }

                        &:focus-visible {
                            outline: 2px solid;
                            outline-color: var(--colortheme-primary-dark);
                        }

                        &--active {
                            span:hover {
                                border-bottom: 1px solid var(--colortheme-primary-dark);
                            }
                        }

                        svg {
                            height: 1.8rem;
                        }
                    }

                    .nyk-button-login {
                        flex-shrink: 0;
                    }

                    &__burgermenu {
                        padding: 0;
                        background-color: transparent;
                        border: none;
                        width: 4.4rem;
                        height: 4.4rem;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        display: none;

                        &:focus-visible {
                            outline: 2px solid;
                            outline-color: var(--colortheme-primary-dark);
                        }

                        @media screen and (max-width: $breakpoint-medium) {
                            display: flex;
                        }

                        @media screen and (max-width: $breakpoint-small) {
                            z-index: $menuZIndex;
                        }

                        &--open {
                            .nyk-menu__top-menu__bottom__container__actions__burgermenu__symbol {
                                &__line {
                                    &:first-child {
                                        animation: burgermenu-top-close-line-1 0.3s;
                                        animation-fill-mode: forwards;
                                        animation-iteration-count: 1;
                                        -webkit-animation-iteration-count: 1;
                                        -webkit-animation-fill-mode: forwards;
                                    }

                                    &:nth-child(2) {
                                        animation: burgermenu-top-close-line-2 0.3s;
                                        animation-fill-mode: forwards;
                                        animation-iteration-count: 1;
                                        -webkit-animation-iteration-count: 1;
                                        -webkit-animation-fill-mode: forwards;
                                    }

                                    &:last-child {
                                        animation: burgermenu-top-close-line-3 0.3s;
                                        animation-fill-mode: forwards;
                                        animation-iteration-count: 1;
                                        -webkit-animation-iteration-count: 1;
                                        -webkit-animation-fill-mode: forwards;
                                    }
                                }
                            }
                        }

                        &--close {
                            .nyk-menu__top-menu__bottom__container__actions__burgermenu__symbol {
                                &__line {
                                    height: 0.2rem;
                                    width: 100%;
                                    background-color: var(--colortheme-primary-dark);
                                    transition: 0.3s;

                                    &:first-child {
                                        transform: translateY(0) rotate(0);
                                        transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
                                    }

                                    &:nth-child(2) {
                                        transform: translateY(0) rotate(0);
                                        transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
                                    }

                                    &:last-child {
                                        transform: translateY(0) rotate(0);
                                        transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
                                    }
                                }
                            }
                        }

                        &__symbol {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            width: 3.2rem;
                            height: 1.8rem;
                            -moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
                            -o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
                            -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
                            -webkit-transition-delay: 0.3s, 0.1s;
                            transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;

                            &:focus-visible {
                                outline: 2px solid;
                                outline-color: var(--colortheme-primary-dark);
                            }

                            &__line {
                                height: 0.2rem;
                                width: 100%;
                                background-color: var(--colortheme-primary-dark);
                                transition: 0.3s;

                                &:first-child {
                                    transform: translateY(0) rotate(0);
                                    transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
                                }

                                &:nth-child(2) {
                                    transform: translateY(0) rotate(0);
                                    transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
                                }

                                &:last-child {
                                    transform: translateY(0) rotate(0);
                                    transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__submenu {
        position: absolute;
        top: 13.6rem;
        left: 0;
        width: 100%;
        background-color: var(--colortheme-neutral-7);
        padding: 6rem 0;
        display: none;
        gap: 1.6rem;
        border-top: 1px solid var(--colortheme-neutral-3);

        @media screen and (max-width: $breakpoint-medium) {
            padding-top: 3.2rem;
            top: 8.8rem;
        }

        @media screen and (max-width: 1150px) {
            gap: 6rem;
        }

        @media screen and (max-width: $breakpoint-small) {
            top: 0;
            margin: 0;
            padding-top: 0;
        }

        @media screen and (max-width: $breakpoint-x-small) {
            margin: 0 $margin-x-small;
            top: 0;
            margin: 0;
            width: 100%;
        }

        &--search-overlay-shown {
            .nyk-menu__submenu__content__items {
                display: none;
            }

            .nyk-menu__submenu__content__items-mobile {
                display: none;
            }

            .nyk-menu__submenu__content__left {
                display: none;
            }

            .nyk-menu__submenu__content__search-overlay {
                display: block;
            }
        }

        &__content {
            position: relative;
            width: 100%;
            display: flex;
            gap: 5.2rem;

            @media screen and (min-width: $breakpoint-large) {
                margin: 0 $margin-x-large;
            }

            @media screen and (max-width: $breakpoint-large) {
                margin: 0 $margin-large;
            }

            @media screen and (min-width: $breakpoint-computed-x-large) {
                margin: 0 auto;
                max-width: $max-width;
            }

            @media screen and (max-width: $breakpoint-medium) {
                padding: 0 $margin-medium;
                margin: 0;
                gap: 8rem;
            }

            @media screen and (max-width: 1150px) {
                gap: 6rem;
            }

            @media screen and (max-width: $breakpoint-small) {
                flex-direction: column;
            }

            @media screen and (max-width: $breakpoint-x-small) {
                padding: 0 $margin-x-small;
            }

            &__close {
                position: absolute;
                top: -4.6rem;
                right: -6rem;
                width: 2.8rem;
                height: 2.8rem;
                border: none;
                background-color: transparent;
                cursor: pointer;

                @media screen and (max-width: $breakpoint-medium) {
                    display: none;
                }

                &:focus-visible {
                    outline: 2px solid;
                    outline-color: var(--colortheme-primary-dark);
                }

                &__symbol {
                    position: relative;
                    &__line {
                        position: absolute;
                        height: 0.2rem;
                        width: 2.6rem;
                        background-color: var(--colortheme-primary-dark);
                        transition: 0.3s;

                        &:first-child {
                            transform: rotate(45deg);
                        }

                        &:last-child {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }

            &__items {
                flex: 2.5;
                display: none;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: max-content;
                row-gap: 12.4rem;
                column-gap: 3.6rem;
                padding: 0;
                margin: 0;

                &--shown {
                    display: grid;
                }

                @media screen and (max-width: $breakpoint-medium) {
                    display: none;
                }

                &__group {
                    display: flex;
                    flex-direction: column;
                    gap: 2.4rem;

                    &__title {
                        @include heading("small");
                    }

                    &__list {
                        list-style: none;
                        padding-left: 0;
                        display: flex;
                        flex-direction: column;
                        gap: 0.8rem;

                        &__item {
                            @include bodyText("medium");

                            &--highlight {
                                font-weight: 700;

                                a.nyk-button {
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }

            &__items-mobile {
                flex: 0.75;
                display: none;
                flex-direction: column;

                @media screen and (max-width: $breakpoint-medium) {
                    display: flex;
                }

                @media screen and (max-width: $breakpoint-small) {
                    padding-top: 8.8rem;
                }

                .nyk-menu__top-menu__top__menu-items {
                    height: 8.8rem;
                    padding-left: 0;
                    list-style: none;
                    display: none;
                    align-items: center;
                    gap: 3.2rem;
                    margin: 0 7.6rem 3rem 7.6rem;

                    @media screen and (max-width: $breakpoint-small) {
                        display: flex;
                        position: absolute;
                        top: 0;
                    }

                    @media screen and (max-width: $breakpoint-x-small) {
                        margin: 0 0.8rem;
                        height: 7.4rem;
                    }

                    &__item {
                        &__link {
                            @include bodyText("small");
                            text-decoration: none;
                            transition: 0.2s;

                            &:hover {
                                border-bottom: 1px solid;
                                border-color: var(--colortheme-netrual-3);
                            }

                            &--active {
                                border-bottom: 1px solid;
                                border-color: var(--colortheme-netrual-3);
                            }
                        }
                    }
                }

                &__search {
                    position: relative;
                    display: none;
                    align-items: center;
                    gap: 2.4rem;
                    border: 1px solid var(--colortheme-primary-dark);
                    border-radius: 2.8rem;
                    margin: 0 7.6rem 2.8rem 7.6rem;

                    @media screen and (max-width: $breakpoint-small) {
                        display: flex;
                    }

                    @media screen and (max-width: $breakpoint-x-small) {
                        margin: 0 0 2.8rem 0;
                    }

                    &__icon {
                        position: absolute;
                        left: 2.4rem;
                        width: 2rem;
                        height: 2rem;
                    }

                    &__input {
                        width: 100%;
                        background-color: transparent;
                        border: none;
                        border-radius: 2.8rem;
                        height: 5.6rem;
                        padding: 0 2.4rem 0 6rem;
                        @include bodyText("large");

                        &:focus-visible {
                            outline: 2px solid;
                            outline-color: var(--colortheme-primary-dark);
                        }
                    }
                }

                &__overlay {
                    display: none;
                    position: fixed;
                    left: 0;
                    top: -13rem; // top menu + 1px border
                    width: 100%;
                    min-height: calc(100vh + 13rem);
                    background-color: var(--colortheme-neutral-5-20);

                    &--shown {
                        display: block;
                        z-index: $submenudropdownOverlay;
                    }
                }

                &__selector {
                    position: relative;
                    padding-left: 7.6rem;
                    margin-top: 8.8rem;

                    @media screen and (max-width: $breakpoint-small) {
                        margin-top: 0.4rem;
                    }

                    @media screen and (max-width: $breakpoint-x-small) {
                        padding-left: 0.8rem;
                    }

                    &--hide {
                        display: none;
                    }

                    &__title {
                        @include heading("medium");
                        display: flex;
                        align-items: center;
                        gap: 1.2rem;
                        cursor: pointer;
                        background-color: transparent;
                        padding: 0;
                        border: none;
                        margin-bottom: 2.4rem;

                        &:focus-visible {
                            outline: 2px solid;
                            outline-color: var(--colortheme-primary-dark);
                        }

                        &[aria-expanded="true"] {
                            svg {
                                transform: rotate(180deg);
                            }
                        }

                        svg {
                            width: 1.6rem;
                        }
                    }

                    &__dropdown {
                        position: absolute;
                        display: none;
                        width: calc(100% - 3.4rem);
                        min-width: 28rem;
                        max-height: 60vh;
                        background-color: var(--colortheme-neutral-7);
                        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
                        border-radius: var(--border-radius);

                        @media screen and (max-width: $breakpoint-small) {
                            width: 36rem;
                        }

                        @media screen and (max-width: $breakpoint-x-small) {
                            width: 100%;
                            left: 0;
                        }

                        &--shown {
                            display: block;
                            z-index: $submenudropdown;
                        }

                        &__list {
                            list-style: none;
                            padding-left: 0;
                            display: flex;
                            flex-direction: column;
                            overflow-y: auto;

                            @media screen and (max-width: $breakpoint-x-small) {
                                padding-left: 0;
                            }

                            &__item {
                                &__link {
                                    display: block;
                                    @include bodyText("large");
                                    text-decoration: none;
                                    padding: 1.2rem 2.4rem;
                                    transition: 0.2s;

                                    &:focus-visible {
                                        outline-offset: -0.5rem;
                                    }

                                    &:hover {
                                        background-color: var(--colortheme-neutral-2);
                                    }
                                }
                            }
                        }
                    }
                }

                &__list {
                    flex: 1;
                    list-style: none;
                    padding: 0 0 0 9.2rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1.6rem;

                    @media screen and (max-width: $breakpoint-small) {
                        padding: 0 7.6rem 0 9.2rem;
                    }

                    @media screen and (max-width: $breakpoint-x-small) {
                        padding: 0 2.4rem;
                    }

                    @media screen and (max-width: $breakpoint-xx-small) {
                        padding: 0 0.8rem;
                    }

                    &--hide {
                        display: none;
                    }

                    &__item {
                        &__button {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            background-color: transparent;
                            border: none;
                            padding: 0;
                            width: 100%;
                            color: var(--colortheme-primary-dark);
                            text-decoration: none;
                            cursor: pointer;

                            &:focus-visible {
                                outline: 2px solid;
                                outline-color: var(--colortheme-primary-dark);
                            }

                            &__text {
                                @include bodyText("large");

                                &--highlight {
                                    font-weight: 700;
                                }
                            }

                            &__icon {
                                svg {
                                    width: 1.6rem;
                                }
                            }
                        }
                    }
                }

                &__second-level-submenu {
                    display: none;

                    &--shown {
                        display: block;
                        margin-top: 8.6rem;

                        @media screen and (max-width: $breakpoint-small) {
                            margin-top: 0;
                        }
                    }

                    &__header {
                        margin-left: 1.6rem;
                        margin-bottom: 3.6rem;

                        @media screen and (max-width: $breakpoint-x-small) {
                            margin-bottom: 3.2rem;
                            margin-left: 1.6rem;
                        }

                        &__button {
                            display: flex;
                            align-items: center;
                            gap: 1.6rem;
                            padding: 0;
                            background-color: transparent;
                            border: none;
                            text-align: left;
                            @include heading("medium");
                            cursor: pointer;

                            @media screen and (max-width: $breakpoint-x-small) {
                                flex-direction: column;
                                align-items: flex-start;
                            }

                            &__icon {
                                width: 4.4rem;
                                height: 4.4rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                svg {
                                    width: 1.6rem;
                                }
                            }
                        }
                    }

                    &__list {
                        flex: 1;
                        list-style: none;
                        padding: 0 0 0 9.2rem;
                        display: flex;
                        flex-direction: column;
                        gap: 1.6rem;

                        @media screen and (max-width: $breakpoint-small) {
                            justify-content: flex-end;
                            padding: 0 7.6rem 0 9.2rem;
                        }

                        @media screen and (max-width: $breakpoint-x-small) {
                            padding: 0 2.4rem;
                        }

                        @media screen and (max-width: $breakpoint-xx-small) {
                            padding: 0 0.8rem;
                        }

                        &--hide {
                            display: none;
                        }

                        &__item {
                            &__button {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                background-color: transparent;
                                border: none;
                                padding: 0;
                                width: 100%;
                                color: var(--colortheme-primary-dark);
                                text-decoration: none;
                                cursor: pointer;
                                
                                &:focus-visible {
                                    outline: 2px solid;
                                    outline-color: var(--colortheme-primary-dark);
                                }

                                &__text {
                                    @include bodyText("large");
                                    text-align: left;

                                    &--highlight {
                                        font-weight: 700;
                                    }
                                }

                                &__icon {
                                    svg {
                                        width: 1.6rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &__left {
                flex: 1;
                display: flex;
                flex-direction: column;

                @media screen and (max-width: $breakpoint-medium) {
                    padding-right: 7.6rem;
                }

                @media screen and (max-width: $breakpoint-small) {
                    justify-content: flex-end;
                    padding: 0 7.6rem 4rem 7.6rem;
                }

                @media screen and (max-width: $breakpoint-x-small) {
                    padding: 0;
                }

                .nyk-menu__top-menu__top__menu-items {
                    margin: 0 0 3.2rem 0;
                    padding-left: 0;
                    list-style: none;
                    display: none;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 3.2rem;

                    @media screen and (max-width: $breakpoint-medium) {
                        display: flex;
                    }

                    @media screen and (max-width: $breakpoint-small) {
                        display: none;
                    }

                    &__item {
                        &__link {
                            @include bodyText("small");
                            text-decoration: none;
                            transition: 0.2s;

                            &:hover {
                                border-bottom: 1px solid;
                                border-color: var(--colortheme-netrual-3);
                            }

                            &--active {
                                border-bottom: 1px solid;
                                border-color: var(--colortheme-netrual-3);
                            }
                        }
                    }
                }

                &__cards {
                    display: flex;
                    flex-direction: column;
                    gap: 1.6rem;

                    @media screen and (max-width: $breakpoint-medium) {
                        flex-direction: row;
                    }

                    @media screen and (max-width: $breakpoint-small) {
                        flex-direction: column;
                    }

                    @media screen and (max-width: $breakpoint-x-small) {
                        gap: 0.8rem;
                    }

                    &__card {
                        @include cardBody();
                        min-height: 34.8rem;
                        display: flex;
                        flex-direction: column;
                        flex: 1;

                        &--yellow {
                            background-color: var(--colortheme-alfa);
                        }

                        @media screen and (max-width: $breakpoint-small) {
                            min-height: 22rem;
                            gap: 5rem;
                        }

                        @media screen and (max-width: $breakpoint-x-small) {
                            min-height: unset;
                            padding: 1.8rem 2.4rem;
                        }

                        &__header {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            @media screen and (max-width: $breakpoint-small) {
                                flex-direction: row;
                            }

                            &__title {
                                @include heading("medium");
                                display: flex;
                                justify-content: space-between;

                                @media screen and (max-width: 1024px) {
                                    font-size: $fs-mobile-heading-medium;
                                }

                                @media screen and (max-width: $breakpoint-small) {
                                    @include heading("small");
                                    flex: 1;
                                }

                                @media screen and (max-width: $breakpoint-x-small) {
                                    align-items: center;
                                }

                                &__button {
                                    display: none !important;
                                    background-color: var(--colortheme-neutral-7) !important;
                                    border-color: var(--colortheme-neutral-7) !important;

                                    &:hover {
                                        background-color: var(--colortheme-neutral-4) !important;
                                        border-color: var(--colortheme-neutral-4) !important;
                                    }

                                    &:focus {
                                        background-color: var(--colortheme-neutral-4) !important;
                                        border-color: var(--colortheme-neutral-4) !important;
                                    }

                                    &:active {
                                        background-color: var(--colortheme-neutral-5) !important;
                                        border-color: var(--colortheme-neutral-5) !important;
                                    }

                                    @media screen and (max-width: $breakpoint-x-small) {
                                        display: flex !important;
                                    }
                                }
                            }
                        }

                        &__description {
                            margin: 0;
                            margin-top: auto;
                            @include bodyText("medium");

                            @media screen and (max-width: $breakpoint-small) {
                                flex: 1;
                                @include bodyText("small");
                                margin-top: 0;
                            }

                            @media screen and (max-width: $breakpoint-x-small) {
                                display: none;
                            }

                            & > * {
                                margin: 0 0 1.6rem 0;
                            }
                        }

                        &__action {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: 2.4rem;
                            margin-top: 4rem;
                            text-decoration: none;

                            @media screen and (max-width: $breakpoint-small) {
                                margin-top: 0;
                            }

                            @media screen and (max-width: $breakpoint-x-small) {
                                display: none;
                            }

                            &__text {
                                @include bodyText("medium");
                                font-weight: 500;
                            }

                            &__button {
                                background-color: var(--colortheme-neutral-7) !important;
                                border-color: var(--colortheme-neutral-7) !important;

                                &:hover {
                                    background-color: var(--colortheme-neutral-4) !important;
                                    border-color: var(--colortheme-neutral-4) !important;
                                }

                                &:focus {
                                    background-color: var(--colortheme-neutral-4) !important;
                                    border-color: var(--colortheme-neutral-4) !important;
                                }

                                &:active {
                                    background-color: var(--colortheme-neutral-5) !important;
                                    border-color: var(--colortheme-neutral-5) !important;
                                }
                            }
                        }
                    }
                }
            }

            &__search-overlay {
                display: none;
                width: 100%;
            }
        }
    }
}

body.nyk-submenu-open {
    overflow: hidden;

    .nyk-breaking-news {
        display: none;
    }
}

.nyk-fake-focus-search-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
}
