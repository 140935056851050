.nyk-process {
  max-width: $max-width;
  padding: 6rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid var(--colortheme-primary-dark-20);
    padding-bottom: 6rem;
    gap: 1.6rem;

    @media screen and (max-width: $breakpoint-small) {
      gap: 3.2rem;
      flex-direction: column;
      padding-bottom: 4.4rem;
    }

    &__title {
      @include heading("x-large");
      line-height: $fs-heading-x-large;

      @media screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
    }

    &__button {
      white-space: nowrap;

      @media screen and (max-width: $breakpoint-small) {
        white-space: unset;
      }
    }
  }

  &__content {
    list-style: none;
    padding-left: 0;
    margin: 0;
    padding-top: 4.4rem;
    display: grid;
    column-gap: 1.6rem;
    row-gap: 6rem;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: $breakpoint-medium) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: $breakpoint-small) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $breakpoint-x-small) {
      grid-template-columns: repeat(1, 1fr);
      padding-top: 3.2rem;
      row-gap: 4.4rem;
    }

    .nyk-process__step {
      &__index {
        @include bodyText("large");
        background-color: var(--colortheme-accent-primary);
        height: 4rem;
        width: 4rem;
        border-radius: 0.75rem;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--colortheme-neutral-7);
      }
      &__content {
        @include bodyText("large");
        margin-top: 2.4rem;
        padding-right: 3.2rem;
        color: var(--colortheme-primary-dark);

        @media screen and (max-width: $breakpoint-x-small) {
          padding-right: 0;
        }
      }

      &__title {
        margin: 0;
      }

      &__description {
        margin: 0;

        @media screen and (max-width: $breakpoint-x-small) {
          margin-bottom: 0;
        }

        & > * {
          margin: 0;
        }
      }
    }
  }
}
