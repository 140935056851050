$animation-rotate: -25deg;

.nyk-contact-card {
  .nyk-signup-step__header {
    margin-bottom: 5.4rem;
  }

  .nyk-signup-step__input {
    @include heading("medium");
    padding-top: 1.2rem;

    @media screen and (max-width: $breakpoint-small) {
      font-size: 3.6rem;
      padding-bottom: 0.4rem;

      &::placeholder {
        @include heading("medium");
      }
    }
  }

  .nyk-signup-step-actions--no-space-top {
    padding-top: 3.6rem;
  }

  .nyk-contact-step-intro__content {
    @media screen and (max-width: $breakpoint-small) {
      flex-direction: column;
    }
  }

  .nyk-signup-step__content {
    &--animation {
      overflow: hidden;

      &::before {
        content: "";
        background-color: var(--colortheme-alfa);
        position: absolute;
        width: 200%;
        height: 400%;
        transform: rotate($animation-rotate);
        left: -50%;
        top: -100%;
        z-index: 50;
        animation: contact-card-slide-animation 1.25s ease-in-out 1;
        animation-fill-mode: forwards;
      }
    }
  }

  .nyk-signup-step__content,
  .nyk-contact-step-intro__content {
    background-color: var(--colortheme-alfa);

    &::before {
      background-color: var(--colortheme-alfa);
    }

    .nyk-signup-step__radio__checkmark {
      border-color: var(--colortheme-primary-dark);

      &::after {
        background-color: var(--colortheme-primary-dark);
      }
    }
  }

  &--beta {
    .nyk-signup-step__content,
    .nyk-contact-step-intro__content {
      background-color: var(--colortheme-beta);

      &::before {
        background-color: var(--colortheme-beta);
      }

      .nyk-signup-step__radio__checkmark {
        border-color: var(--colortheme-primary-dark);

        &::after {
          background-color: var(--colortheme-primary-dark);
        }
      }
    }
  }

  &--gamma {
    .nyk-signup-step__content,
    .nyk-contact-step-intro__content {
      background-color: var(--colortheme-gamma);

      &::before {
        background-color: var(--colortheme-gamma);
      }

      .nyk-signup-step__radio__checkmark {
        border-color: var(--colortheme-primary-dark);

        &::after {
          background-color: var(--colortheme-primary-dark);
        }
      }
    }
  }

  &--delta {
    .nyk-signup-step__content,
    .nyk-contact-step-intro__content {
      background-color: var(--colortheme-delta);

      &::before {
        background-color: var(--colortheme-delta);
      }

      .nyk-signup-step__radio__checkmark {
        border-color: var(--colortheme-primary-dark);

        &::after {
          background-color: var(--colortheme-primary-dark);
        }
      }
    }
  }

  &--epsilon {
    .nyk-signup-step__content,
    .nyk-contact-step-intro__content {
      background-color: var(--colortheme-epsilon);

      * :focus-visible {
        outline-color: var(--colortheme-neutral-7);
      }

      &::before {
        background-color: var(--colortheme-epsilon);
      }

      * {
        color: var(--colortheme-neutral-7);
      }

      .nyk-signup-step__input {
        /* Chrome, Safari, and Edge */
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
          -webkit-text-fill-color: var(--colortheme-neutral-7) !important;
        }

        &::placeholder {
          color: var(--colortheme-neutral-7);
        }
      }

      * {
        color: var(--colortheme-neutral-7);
      }

      .nyk-tooltip__tooltip-button {
        border-color: var(--colortheme-neutral-7);

        &--active {
          border-color: var(--colortheme-primary-dark);
        }
      }

      .nyk-signup-step__radio__checkmark {
        border-color: var(--colortheme-neutral-7);

        &::after {
          background-color: var(--colortheme-neutral-7);
        }
      }
    }
  }

  &--image-right {
    .nyk-contact-step-intro__content {
      grid-template-columns: auto calc($column-aside-big - 1.6rem);

      .nyk-contact-step-intro__content__info {
        order: 1;
      }

      .nyk-contact-step-intro__content__image {
        order: 2;
      }

      @media screen and (max-width: $breakpoint-small) {
        flex-direction: column-reverse;
      }
    }
  }
}

@keyframes contact-card-slide-animation {
  0% {
    transform: rotate($animation-rotate) translateY(0);
  }
  100% {
    transform: rotate($animation-rotate) translateY(100%);
  }
}
