.nyk-card-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $margin-x-small;
  padding: 6rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
    margin: 0 $margin-x-small;
    grid-template-columns: 1fr;
    row-gap: 2.4rem;
  }

  &--find-center {
    .nyk-card-medium__footer {
      margin-top: 3.6rem;
    }

    .nyk-card-small {
      @media screen and (max-width: $breakpoint-small) {
        flex-direction: column;

        &__content__title {
          margin-top: 7rem;
          margin-bottom: 0;
        }
      }
    }
  }

  &__title {
    @include heading("x-large", $breakpoint-small);
    grid-column: span 3;
    margin-bottom: 7.6rem;
    word-break: break-word;

    @media screen and (max-width: $breakpoint-small) {
      grid-column: span 1;
      margin-bottom: 3.2rem;
    }
  }

  .nyk-card-small {
    grid-column: span 1;
  }

  .nyk-card-medium {
    grid-column: span 1;
  }

  .nyk-card-large {
    grid-column: span 2;

    @media screen and (max-width: $breakpoint-small) {
      grid-column: span 1;
    }
  }
}
