.nyk-signature-card {
  padding: 6rem 0;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0 $margin-x-large;
  }

  @media screen and (min-width: $breakpoint-computed-x-large) {
    margin: 0 auto;
    max-width: $max-width;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 $margin-large;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 $margin-medium;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding: 3.2rem 0;
  }

  @media screen and (max-width: $breakpoint-x-small) {
    margin: 0 $margin-x-small;
  }

  &__content {
    display: grid;
    grid-template-columns: auto calc($column-aside-big - 1.6rem); // 1.6rem is minus the grid gutter from figma
    height: 50rem;
    overflow: hidden;
    border-radius: var(--border-radius);
    background-color: var(--colortheme-gamma);

    @media screen and (max-width: $breakpoint-small) {
      display: flex;
      flex-direction: column;
      height: unset;
    }

    &__info {
      display: flex;
      flex-direction: column;
      padding: 3.2rem 2.4rem;

      &__header {
        @media screen and (max-width: $breakpoint-small) {
          margin-bottom: 10rem;
        }

        &__title {
          @include heading("medium");
        }
      }

      &__content {
        margin-top: auto;

        &.nyk-richtext {
          margin: 0;

          & > * {
            margin-left: unset;
            width: 100%;
          }
        }

        &__description {
          @include bodyText("large");
          margin: 0;
        }
      }

      &__action {
        margin-top: 5.8rem;

        &__link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;

          &__name {
            @include bodyText("medium");
          }

          &__button {
            background-color: var(--colortheme-neutral-7) !important;
            border-color: var(--colortheme-neutral-7) !important;
          }
        }
      }
    }

    &__image {
      width: 100%;
      height: 50rem;
      overflow: hidden;

      @media screen and (max-width: $breakpoint-small) {
        display: none;
      }

      &__img:is(img) {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0;
      }
    }
  }
}
